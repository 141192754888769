// production
const config = {
  baseUrl: "https://my.cabcard.services",
  apiBaseUrl: "https://api.cabcard.services",
  cookieDomain: ".cabcard.services",
  namespace: "my_cabcard-services",
  paymentFacilitatorId: "pfc_3q12hkjyo6478c", // CCS production
};

if (
  [
    "dev.cabcard.services",
    "dev-a.cabcard.services",
    "dev-b.cabcard.services",
    "dev-c.cabcard.services",
  ].includes(window.location.hostname)
) {
  // development
  config.baseUrl = `http://${window.location.host}`;
  config.apiBaseUrl = "https://api-staging.cabcard.services";
  // remove subdomain from cookieDomain
  config.cookieDomain = window.location.hostname.replace( /[^\.]*\./ , "." );
  config.namespace = "my-dev_cabcard-services";
  config.paymentFacilitatorId = "pfc_bsu0fikjxyxvu2u"; // staging
} else if (window.location.hostname === "my-staging.cabcard.services") {
  // staging
  config.baseUrl = "https://my-staging.cabcard.services";
  config.apiBaseUrl = "https://api-staging.cabcard.services";
  config.cookieDomain = ".cabcard.services";
  config.namespace = "my-stg_cabcard-services";
  config.paymentFacilitatorId = "pfc_bsu0fikjxyxvu2u"; // staging
} else if (window.location.hostname === "my-sandbox.cabcard.services") {
  // sandbox
  config.baseUrl = "https://my-sandbox.cabcard.services";
  config.apiBaseUrl = "https://api-sandbox.cabcard.services";
  config.cookieDomain = ".cabcard.services";
  config.namespace = "my-sbx_cabcard-services";
  config.paymentFacilitatorId = "pfc_hdjjjenxke8379";
}

export default config;
