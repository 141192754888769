<template>
  <div class="row">
    <div v-if="payout" class="col-md-12">
      <div class="card">
        <h2 class="card-header">Payout details</h2>
        <div class="card-body">
          <h6 class="card-subtitle mb-4 text-muted">
            Payout ID:
            <code class="text-transform-none">{{ payout.id }}</code>
          </h6>

          <div class="row">
            <div class="col-md-6">
              <table class="table">
                <tbody>
                  <tr>
                    <th>Amount</th>
                    <td>{{ payout.amount | money(payout.currency) }}</td>
                  </tr>
                  <tr>
                    <th>Date</th>
                    <td>{{ payout.createdAt | moment("YYYY-MM-DD") }}</td>
                  </tr>
                  <tr>
                    <th>Payment reference</th>
                    <td>
                      <code>{{ payout.paymentReference }}</code>
                    </td>
                  </tr>
                  <tr>
                    <th>Schedule</th>
                    <td>
                      <span v-if="payout.scheduled">
                        <i class="fa fa-calendar-check-o"></i>&nbsp; Regular
                      </span>
                      <span v-else>
                        <i class="fa fa-calendar-times-o"></i>&nbsp; One-off
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <th>Destination</th>
                    <td>
                      {{ payout.destinationType | humanize }}<br />
                      <div v-if="bankAccount">
                        {{ bankAccount.nickname }}<br />
                        {{ bankAccount.accountName }}<br />
                        <code>{{ bankAccount.sortCode }}</code> /
                        <code>{{ bankAccount.accountNumber }}</code>
                      </div>

                      <div v-if="payoutCard">
                        {{ payoutCard.nickname }}<br />
                        {{ payoutCard.cardScheme | humanize }}
                        <code>**** {{ payoutCard.panLastFour }}</code
                        ><br />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="card-footer">
          <button v-on:click="$router.back()" class="btn btn-default">
            <i class="fa fa-arrow-left"></i>&nbsp; Go back
          </button>
        </div>
      </div>
    </div>
    <div v-else>
      <div v-if="loading" class="spinner-container">
        <double-bounce size="60px"></double-bounce>
      </div>
    </div>
  </div>
</template>

<script>
import { DoubleBounce } from "vue-loading-spinner";

export default {
  name: "PayoutPage",
  components: {
    DoubleBounce,
  },
  data() {
    return {
      error: null,
      loading: null,
      payout: null,
      bankAccount: null,
      payoutCard: null,
    };
  },
  methods: {
    loadPayoutDestination: function () {
      if (this.payout && this.payout.destinationId) {
        if (this.payout.destinationType === "bank_account") {
          this.axios
            .get(`/v1/bank-accounts/${this.payout.destinationId}`)
            .then((response) => {
              this.loading = false;
              this.bankAccount = response.data;
            })
            .catch((error) => {
              this.loading = false;
              this.error = "Failed to load bank account";
            });
        } else if (this.payout.destinationType === "payout_card") {
          this.axios
            .get(`/v1/payout-cards/${this.payout.destinationId}`)
            .then((response) => {
              this.loading = false;
              this.payoutCard = response.data;
            })
            .catch((error) => {
              this.loading = false;
              this.error = "Failed to load payout card";
            });
        }
      }
    },
  },
  props: ["payoutId"],
  beforeMount() {
    this.loading = true;
    this.axios
      .get(`/v1/payouts/${this.payoutId}`)
      .then((response) => {
        this.loading = false;
        this.payout = response.data;
        this.loadPayoutDestination();
      })
      .catch((error) => {
        this.loading = false;
        this.error = "Failed to load payout";
      });
  },
};
</script>
