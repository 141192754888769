<template>
  <div class="card">
    <div class="card-header">
      <div class="row">
        <div class="col-md-4">
          <h4 class="title">Terminals</h4>
        </div>

        <div class="col-md-8" v-if="showSubmerchantTerminals">
          <div class="row">
            <div class="col-12">
              <!-- Search  -->
              <v-select
                @search="onSearch"
                :filterable="false"
                :options="searchResults"
                @option:selected="chooseSearchResult"
                label="id"
                placeholder="Search by terminal serial number, reference etc."
              >
                <!-- Option -->
                <template v-slot:option="terminal">
                  TSN {{ terminal.serialNumber }}
                  <code v-if="terminal.customReference" class="ml-1">
                    {{ terminal.customReference }}
                  </code>
                </template>
                <!-- No results -->
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    No results found for <em>{{ search }}</em
                    >.
                  </template>
                  <em v-else style="opacity: 0.5"
                    >Try terminal serial number, ID or reference.</em
                  >
                </template>
              </v-select>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card-body" v-if="showSubmerchantTerminals">
      <active-filter-list
        :activeFilters="activeFilters"
        :tableName="tableName"
        @remove-filter="removeFilter"
        @clear-filters="clearFilters"
      ></active-filter-list>

      <v-server-table
        :url="`/v1/terminals?currentAllocation[submerchantId]=${submerchant.id}`"
        name="terminalsTable"
        ref="terminalsTable"
        :columns="columns"
        :options="options"
        v-on:filter="addToActiveFilters"
      >
        <template slot="id" slot-scope="props">
          <code>{{ props.row.id }}</code>
        </template>

        <template slot="serialNumber" slot-scope="props">
          <code>{{ props.row.serialNumber }}</code>
        </template>

        <template slot="currentAllocation.createdAt" slot-scope="props">
          <div>
            {{
              props.row.currentAllocation.createdAt
                | moment("YYYY-MM-DD HH:mm A")
            }}
          </div>
        </template>

        <template slot="reportedConfiguration.isUpToDate" slot-scope="props">
          <div>
            <span
              v-if="
                props.row.reportedConfiguration &&
                props.row.reportedConfiguration.isUpToDate
              "
              class="text-success"
            >
              <i class="fa fa-check"></i>&nbsp; Up to date
            </span>
            <span
              v-else-if="
                props.row.reportedConfiguration &&
                !props.row.reportedConfiguration.isUpToDate
              "
              class="text-danger"
            >
              <i class="fa fa-exclamation-triangle"></i>&nbsp; Out of date
            </span>
            <span v-else class="text-warning">
              <i class="fa fa-question-circle"></i>&nbsp; Status unknown
            </span>
          </div>
        </template>

        <template slot="links" slot-scope="props">
          <router-link
            :to="`/terminals/${props.row.id}`"
            class="btn btn-default btn-sm"
          >
            View &nbsp;
            <i class="fa fa-chevron-right"></i>
          </router-link>
        </template>
      </v-server-table>
    </div>

    <div class="card-body" v-else>Not permitted to view this data.</div>
  </div>
</template>
<script>
import _ from "lodash";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

import { requestAdapter } from "../../../../helpers/tableHelpers";
import ActiveFilterList from "../../../../UIComponents/ActiveFilterList.vue";

const columns = [
  "id",
  "serialNumber",
  "currentAllocation.createdAt",
  "reference",
  "reportedConfiguration.isUpToDate",
  "links",
];

export default {
  name: "TerminalsList",
  components: {
    vSelect,
    ActiveFilterList,
  },
  computed: {
    submerchant: function () {
      return this.$store.state.submerchant;
    },
    submerchantUser: function () {
      return this.$store.state.submerchantUser;
    },
    showSubmerchantTerminals: function () {
      const hideForViewersAndManagers = [
        "sub_doqgnkhq97ql5", // Fleet Cars
        "sub_13qnqlktsnnjp7", // Ecocabs
        "sub_enyvomkxhd8i7h", // A2B Taxis Truro
        "sub_4k35pml9o9a9ox", // Zip
      ];

      const hideForViewers = [];

      if (
        _.includes(hideForViewersAndManagers, this.submerchant.id) &&
        ["viewer", "manager"].includes(this.submerchantUser.submerchantRole)
      ) {
        return false;
      } else if (
        hideForViewers.includes(this.submerchant.id) &&
        this.submerchantUser.submerchantRole === "viewer"
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  data() {
    return {
      // search
      searchResults: [],
      // vue-tables-2
      columns,
      activeFilters: [],
      tableName: "terminalsTable",
      options: {
        columnsDropdown: false,
        headings: {
          id: "Terminal ID",
          serialNumber: "Serial Number",
          "currentAllocation.createdAt": "Allocated at",
          "reportedConfiguration.isUpToDate": "Software status",
        },
        sortable: _.initial(columns),
        filterable: ["serialNumber", "reference"],
        listColumns: {
          isUpToDate: [
            {
              id: "true",
              text: "Up to date",
            },
            {
              id: "false",
              text: "Out of date",
            },
          ],
        },
        requestAdapter,
      },
    };
  },
  methods: {
    searchTerminals: _.debounce((search, loading, vm) => {
      vm.axios
        .get("/v1/terminals", {
          params: {
            q: search,
            limit: 10,
          },
        })
        .then((response) => {
          loading(false);
          vm.searchResults = response.data;
        })
        .catch((error) => {
          loading(false);
          console.log("error: ", error.message);
          // TODO: handle this
        });
    }, 500),
    onSearch(search, loading) {
      if (search !== "") {
        loading(true);
        this.searchTerminals(search, loading, this);
      }
    },
    chooseSearchResult(result) {
      // go to selected terminal page
      this.$router.push(`/terminals/${result.id}`);
    },
    clearFilters() {
      this.$refs["terminalsTable"].resetQuery();
      this.$refs["terminalsTable"].resetCustomFilters();
      this.activeFilters = [];
    },
    containsObject(obj, list) {
      for (const i in list) {
        if (obj.name === list[i].name) {
          return true;
        }
      }

      return false;
    },
    removeFilter(filter) {
      const filterObj = {};
      filterObj[filter.name] = "";
      this.$refs.terminalsTable.setFilter(filterObj);
    },
    addToActiveFilters(filter) {
      let isDuplicate = this.containsObject(filter, this.activeFilters);

      if (filter.value !== "" && !isDuplicate) {
        this.activeFilters.push(filter);
      } else if (filter.value === "") {
        const index = this.activeFilters.indexOf(filter);
        this.activeFilters.splice(index, 1);
      }
    },
    loadActiveFilters() {
      const tableData = this.$refs[this.tableName].getRequestParams();
      const activeQueries = tableData["query"];
      for (let query in activeQueries) {
        if (activeQueries[query] !== "") {
          const queryObj = { name: query, value: activeQueries[query] };
          this.activeFilters.push(queryObj);
        }
      }
    },
  },
  mounted() {
    this.loadActiveFilters();
  },
};
</script>
