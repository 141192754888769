<template>
  <navbar navbar-menu-classes="navbar-right">
    <template slot="navbar-menu"></template>
  </navbar>
</template>

<script>
import { Navbar } from "src/components/UIComponents";
export default {
  name: "main-navbar",
  components: {
    Navbar
  }
};
</script>

<style scoped>
</style>
