<template>
  <span>
    <span v-if="loading">
      <i class="fa fa-spinner fa-spin"></i>&nbsp; Please wait&hellip;
    </span>
    <span v-else-if="error">
      <span v-if="terminalSerialNumber">
        <el-tooltip
          placement="top"
          :content="`Error loading terminal: ${error}`"
        >
          <code>{{ terminalSerialNumber }}</code>
        </el-tooltip>
      </span>
      <span v-else>Error: {{ error }}</span>
    </span>

    <span v-if="terminal">
      <router-link :to="`/terminals/${terminal.id}`" :class="linkClasses">
        <span v-if="terminal.customReference">{{
          terminal.customReference
        }}</span>
        <span v-else>
          S/N
          <code>{{ terminal.serialNumber }}</code>
        </span>
      </router-link>
    </span>
  </span>
</template>
<script>
import { Tooltip } from "element-ui";

export default {
  name: "TerminalFragment",
  components: {
    [Tooltip.name]: Tooltip,
  },
  props: {
    terminalId: {
      type: String,
      required: true,
    },
    terminalSerialNumber: {
      type: String,
      required: false,
    },
    linkClasses: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      terminal: null,
      error: null,
      loading: false,
    };
  },
  methods: {
    loadTerminal() {
      this.loading = true;
      this.axios
        .get(
          `/v1/terminals/${this.terminalId}?fields=serialNumber,id,customReference`
        )
        .then((response) => {
          this.loading = false;
          if (response.status !== 200) {
            this.error = response.statusText;
            return;
          }

          this.terminal = response.data;
        })
        .catch((error) => {
          this.loading = false;
          // Request failed.
          this.error = error.response.statusText;
        });
    },
  },
  mounted() {
    if (this.terminalId) {
      this.loadTerminal();
    }
  },
};
</script>

<style></style>
