<template>
  <div class="card">
    <div class="card-header">
      <div class="row">
        <div class="col-sm-8">
          <h4 class="title">Sales</h4>
        </div>

        <div class="col-sm-4 text-right">
          <button
            class="btn btn-default"
            @click="refreshTable"
            title="Refresh data"
            :disabled="refreshing">
            <span v-if="!refreshing"><i class="fa fa-refresh mr-2"></i> Refresh</span>
            <span v-else><i class="fa fa-spin fa-refresh mr-2"></i> Loading&hellip;</span>
          </button>
          &nbsp;
          <button
            class="btn btn-default"
            v-on:click="showTransactionsExportModal = true">
            <i class="fa fa-download mr-2"></i> Export
          </button>
        </div>
      </div>
    </div>
    <div class="card-body">
      <!-- Active Filters -->
      <active-filter-list
        :activeFilters="activeFilters"
        :tableName="tableName"
        @remove-filter="removeFilter"
        @clear-filters="clearFilters"></active-filter-list>

      <v-server-table
        :url="`/v1/sales?submerchantId=${submerchant.id}`"
        :columns="columns"
        :options="options"
        ref="salesTable"
        name="salesTable"
        @loading="onTableLoading"
        @loaded="onTableLoaded"
        v-on:filter="addToActiveFilters">
        <div slot="filter__capturedAt">
          <date-range-picker
            class="picker"
            ref="picker"
            :opens="opens"
            v-model="dateRange"
            @update="updateValues"
            style="z-index: 1; position: inherit">
            <template
              class="picker-dates"
              v-slot:input="picker"
              style="min-width: 350px">
              {{
                picker.startDate
                ? `${picker.startDate.toISOString().split("T")[0]} -`
                : "Select date range"
              }}
              {{
                picker.endDate ? picker.endDate.toISOString().split("T")[0] : ""
              }}
            </template>
          </date-range-picker>
        </div>

        <template slot="id" slot-scope="props">
          <code>{{ props.row.id }}</code>
        </template>

        <template slot="capturedAt" slot-scope="props">
          <div>{{ props.row.capturedAt | moment("YYYY-MM-DD HH:mm") }}</div>
        </template>

        <template slot="amount" slot-scope="props">
          <span>{{ props.row.amount | money(props.row.currency) }}</span>
        </template>

        <template slot="saleAmount" slot-scope="props">
          <span>{{ props.row.saleAmount | money(props.row.currency) }}</span>
        </template>

        <template slot="gratuityAmount" slot-scope="props">
          <span>{{
            props.row.gratuityAmount | money(props.row.currency)
          }}</span>
        </template>

        <template slot="panLastFour" slot-scope="props">
          <div>************{{ props.row.panLastFour }}</div>
        </template>

        <template slot="method" slot-scope="props">
          <span>{{ props.row.method | humanize }}</span>
        </template>

        <template slot="cardScheme" slot-scope="props">
          <span>{{ props.row.cardScheme | humanize }}</span>
        </template>

        <template slot="terminalSerialNumber" slot-scope="props">
          <terminal-fragment
            v-if="props.row.terminalSerialNumber"
            :terminalId="props.row.terminalId"
            :terminalSerialNumber="props.row.terminalSerialNumber"
            link-classes="btn btn-link btn-sm"
            :key="props.row.id"></terminal-fragment>
          <span v-else>N/A</span>
        </template>

        <template slot="link" slot-scope="props">
          <router-link
            :to="`/transactions/sales/${props.row.id}`"
            class="btn btn-default btn-sm">
            <span class="d-none d-md-inline">Details &nbsp;</span>
            <i class="fa fa-chevron-right"></i>
          </router-link>
        </template>
      </v-server-table>
    </div>

    <export-sales
      :show-transactions-export-modal="showTransactionsExportModal"
      @close-modal="showTransactionsExportModal = false">
    </export-sales>
  </div>
</template>
<script>
import _ from "lodash";
import { doUntil } from "async-es";

// daterangepicker filter dependencies
import jquery from "jquery";
import moment from "moment";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import { Event } from "vue-tables-2-premium";

window.$ = jquery;
window.moment = moment;

import { Button } from "src/components/UIComponents";
import { requestAdapter } from "../../../helpers/tableHelpers";
import TerminalFragment from "src/components/Dashboard/Views/Pages/Terminals/TerminalFragment.vue";
import ActiveFilterList from "../../../UIComponents/ActiveFilterList.vue";
import ExportSales from "./ExportSales.vue";

const columns = [
  "amount",
  "saleAmount",
  "gratuityAmount",
  "method",
  "cardScheme",
  "terminalSerialNumber",
  "cardType",
  "panLastFour",
  "capturedAt",
  "link",
];

export default {
  name: "SalesList",
  components: {
    TerminalFragment,
    [Button.name]: Button,
    DateRangePicker,
    ActiveFilterList,
    ExportSales
  },
  computed: {
    submerchant: function () {
      return this.$store.state.submerchant;
    },
  },
  methods: {
    refreshTable() {
      this.$refs.salesTable.refresh();
    },
    onTableLoading() {
      this.refreshing = true;
    },
    onTableLoaded() {
      this.refreshing = false;
    },
    loadAllTerminals: function () {
      const instance = this;

      instance.options.listColumns.terminalSerialNumber = [
        {
          id: null,
          text: "Loading...",
        },
      ];

      // load
      let offset = 0;
      const limit = 100;

      const params = {
        fields: "id,reference,serialNumber,customReference",
        "currentAllocation[submerchantId]": instance.submerchant.id,
      };

      let resultCount;
      let terminals = [];

      doUntil(
        (next) => {
          instance
            .loadTerminals(params, limit, offset)
            .then((result) => {
              resultCount = result.length;

              terminals = terminals.concat(result);

              offset += limit;

              next(null, result);
            })
            .catch((err) => {
              return next(err);
            });
        },
        (args, cb) => {
          return cb(null, resultCount < limit);
        }, // test passes when less results in a response than limit, iteration will stop
        (err) => {
          if (err) {
            console.error("Error loading terminals: ", err);
          } else {
            instance.terminals = terminals;

            instance.options.listColumns.terminalSerialNumber = _.map(
              instance.terminals,
              (terminal) => {
                const item = {
                  id: terminal.serialNumber,
                  text: `S/N ${terminal.serialNumber}`,
                };

                if (terminal.customReference) {
                  item.text = terminal.customReference;
                }

                return item;
              }
            );
          }
        }
      ); // eslint-disable-line function-paren-newline
    },
    loadTerminals: function (params, limit, offset) {
      return new Promise((resolve, reject) => {
        if (!limit) {
          limit = 100;
        }

        if (!offset) {
          offset = 0;
        }

        params = _.extend(params, {
          limit,
          offset,
        });

        this.axios
          .get(`v1/terminals`, {
            params,
          })
          .then((response) => {
            return resolve(response.data);
          })
          .catch((err) => {
            // error - oops
            return reject(err);
          });
      });
    },
    updateValues(vals) {
      let filter = { name: "date", value: vals };
      let isDuplicate = this.containsObject(filter, this.activeFilters);
      if (!isDuplicate) {
        this.activeFilters.push(filter);
      }
      Event.$emit("vue-tables.salesTable.filter::capturedAt", {
        start: moment(vals.startDate).startOf("day"),
        end: moment(vals.endDate).endOf("day"),
      });
    },
    clearFilters() {
      this.dateRange.startDate = null;
      this.dateRange.endDate = null;
      this.$refs["salesTable"].resetQuery();
      this.$refs["salesTable"].resetCustomFilters();
      this.activeFilters = [];

      Event.$emit("vue-tables.salesTable.filter::capturedAt", "");
      this.refreshTable();
    },
    containsObject(obj, list) {
      for (const i in list) {
        if (obj.name === list[i].name) {
          return true;
        }
      }

      return false;
    },
    removeFilter(filter) {
      if (filter.name === "date") {
        filter.name = "capturedAt";
        Event.$emit(`vue-tables.salesTable.filter::${filter.name}`, "");
        this.refreshTable();
      } else {
        const filterObj = {};
        filterObj[filter.name] = "";
        this.$refs.salesTable.setFilter(filterObj);
      }
    },
    addToActiveFilters(filter) {
      let isDuplicate = this.containsObject(filter, this.activeFilters);

      if (filter.value !== "" && !isDuplicate) {
        this.activeFilters.push(filter);
      } else if (filter.value === "") {
        const index = this.activeFilters.indexOf(filter);
        this.activeFilters.splice(index, 1);
      }
    },
    loadActiveFilters() {
      const tableData = this.$refs[this.tableName].getRequestParams();

      const activeQueries = tableData["query"];

      if (tableData["capturedAt"] !== "") {
        activeQueries.date = tableData["capturedAt"];
      }

      for (let query in activeQueries) {
        if (activeQueries[query] !== "") {
          const queryObj = { name: query, value: activeQueries[query] };
          this.activeFilters.push(queryObj);
        }
      }
    },
  },
  mounted() {
    this.loadAllTerminals();
    this.loadActiveFilters();
  },
  data() {
    return {
      refreshing: false,
      showTransactionsExportModal: false,
      terminals: [],
      activeFilters: [],
      columns,
      tableName: "salesTable",
      options: {
        columnsDropdown: false,
        columnsDisplay: {
          amount: "min_mobileP",
          saleAmount: "min_tabletL",
          gratuityAmount: "min_tabletL",
          method: "min_tabletL",
          cardScheme: "min_desktop",
          terminalSerialNumber: "min_desktop",
          cardType: "min_desktop",
          panLastFour: "min_tabletL",
          capturedAt: "min_mobileL",
          link: "min_mobile",
        },
        headings: {
          link: "",
          amount: "Total",
          saleAmount: "Fare",
          gratuityAmount: "Tip",
          method: "Method",
          cardScheme: "Scheme",
          terminalSerialNumber: "Terminal",
          cardType: "Card type",
          panLastFour: "PAN (last four)",
          capturedAt: "Date",
        },
        sortable: ["amount", "capturedAt"],
        filterable: [
          "cardScheme",
          "method",
          "cardType",
          "panLastFour",
          "terminalSerialNumber",
        ],
        customFilters: ["capturedAt"],
        listColumns: {
          terminalSerialNumber: [],
          cardScheme: [
            {
              id: "visa",
              text: "Visa",
            },
            {
              id: "mastercard",
              text: "Mastercard",
            },
            {
              id: "american_express",
              text: "American Express",
            },
          ],
          method: [
            {
              id: "ecommerce",
              text: "E-Commerce",
            },
            {
              id: "pos",
              text: "POS",
            },
            {
              id: "softpos",
              text: "SoftPOS",
            },
          ],
          cardType: [
            {
              id: "credit",
              text: "Credit",
            },
            {
              id: "debit",
              text: "Debit",
            },
            {
              id: "prepaid",
              text: "Prepaid",
            },
          ],
        },
        pagination: { chunk: 3 },
        requestAdapter,
      },
      dateRange: {
        startDate: null,
        endDate: null,
      },
      opens: "left",
    };
  },
};
</script>
<style>
.picker {
  text-transform: none;
  font-weight: normal;
}

.vue-daterange-picker {
  width: 100%;
}

.reportrange-text[data-v-1ebd09d2] {
  border: 1px solid #ddd;
  margin-top: 5px;
  padding: 7px 5px;
}

#active-filters {
  font-size: 18px;
}

#filter-tag {
  margin-right: 4px;
  cursor: pointer;
}
</style>
