<template>
  <modal
    :show="showTransactionsExportModal"
    class="modal-primary"
    :show-close="true"
    headerClasses="justify-content-center"
  >
    <div slot="header" class>
      <i class="fa fa-download mr-2"></i> Export transactions
    </div>

    <form>
      <div class="form-group">
        <label for="capturedAtPreset">Transaction date</label>
        <select
          v-model="exportOptions.capturedAtPreset"
          class="form-control"
          name="capturedAtPreset"
          id="capturedAtPreset"
        >
          <option value="custom">Custom date range</option>
          <option value="this_week">This week</option>
          <option value="last_week">Last week</option>
          <option value="previous_7_days">Last 7 days</option>
          <option value="this_month">This month</option>
          <option value="last_month">Last month</option>
          <option value="previous_30_days">Last 30 days</option>
          <option value="this_year">This year</option>
          <option value="last_year">Last year</option>
          <option value="previous_365_days">Last 365 days</option>
          <option value="all_time">All time</option>
        </select>
      </div>

      <div v-if="exportOptions.capturedAtPreset === 'custom'">
        <div class="form-group">
          <label class="form-label" for="capturedAtFromDate">From</label>
          <el-date-picker
            v-model="exportOptions.capturedAtFromDate"
            type="date"
            placeholder="Start date"
            name="capturedAtFromDate"
            id="capturedAtFromDate"
            :picker-options="exportDatePickerFromOptions"
          ></el-date-picker>
        </div>

        <div class="form-group">
          <label class="form-label" for="capturedAtToDate">To</label>
          <el-date-picker
            v-model="exportOptions.capturedAtToDate"
            type="date"
            placeholder="End date"
            name="capturedAtToDate"
            id="capturedAtToDate"
            :picker-options="exportDatePickerToOptions"
          ></el-date-picker>
        </div>

        <span v-if="exportDatesError" class="text-danger"
          >Warning: {{ exportDatesError }}</span
        >
      </div>

      <div class="form-group">
        <label for="method">Method</label>
        <select
          v-model="exportOptions.method"
          class="form-control"
          name="method"
          id="method"
        >
          <option :value="null">All</option>
          <option value="pos">POS</option>
          <option value="softops">SoftPOS</option>
          <option value="ecommerce">E-Commerce</option>
        </select>
      </div>

      <div class="form-group">
        <label for="fileType">File type</label>
        <select
          v-model="exportOptions.fileType"
          class="form-control"
          name="fileType"
          id="fileType"
        >
          <option value="pdf">PDF</option>
          <option value="csv">CSV</option>
        </select>
      </div>

      <div class="form-group">
        <label id="processingFees">Processing fees</label>
        <p-checkbox
          v-model="exportOptions.includeBalanceTransactions"
          :disabled="exporting"
        >
          Include processing fees
        </p-checkbox>
      </div>

      <div v-if="exporting">
        <span v-if="fetchingBalanceTransactions">
          <label for="progress"
            >Fetching fees: {{ fetchedBalanceTransactionsCount }} /
            {{ balanceTransactionsTotal }}</label
          >
          <p-progress
            id="progress"
            :value="balanceTransactionsProgress"
          ></p-progress>
        </span>
        <span v-if="fetchingSales">
          <label for="progress"
            >Loading transactions: {{ fetchedSalesCount }} /
            {{ salesTotal }}</label
          >
          <p-progress id="progress" :value="salesProgress"></p-progress>
        </span>
      </div>

      <!-- TODO: column options -->
    </form>

    <div class="alert alert-warning" v-if="exportError">
      Error exporting transactions: {{ exportError }}
    </div>

    <template slot="footer">
      <div class="left-side">
        <p-button type="default" link @click="cancelExport">
          Cancel &nbsp;
          <i class="fa fa-times"></i>
        </p-button>
      </div>
      <div class="divider"></div>
      <div class="right-side">
        <p-button
          type="primary"
          link
          :disabled="exporting"
          @click="startExport()"
        >
          <span v-if="!exporting">
            Export
            <i class="fa fa-check ml-2"></i>
          </span>
          <span v-if="exporting">
            Please wait &nbsp;
            <i class="fa fa-spin fa-circle-o-notch"></i>
          </span>
        </p-button>
      </div>
    </template>
  </modal>
</template>

<script>
import { whilst } from "async-es";
import swal from "sweetalert2";

import { Modal, Checkbox, Progress } from "src/components/UIComponents";
import { DatePicker } from "element-ui";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import moment from "moment";
import _ from "lodash";

export default {
  name: "ExportSales",
  props: ["showTransactionsExportModal"],
  components: {
    [Checkbox.name]: Checkbox,
    [Progress.name]: Progress,
    [DatePicker.name]: DatePicker,
    Modal,
  },
  computed: {
    exportDatesError: function () {
      if (this.exportOptions.capturedAtFromDate > new Date()) {
        return "Start date is after current date";
      }

      if (
        this.exportOptions.capturedAtFromDate &&
        this.exportOptions.capturedAtToDate
      ) {
        if (
          this.exportOptions.capturedAtToDate <
          this.exportOptions.capturedAtFromDate
        ) {
          return "End date is before Start date";
        }
      }
    },
    submerchant: function () {
      return this.$store.state.submerchant;
    },
  },
  methods: {
    startExport: function () {
      const instance = this;
      instance.exporting = true;
      // build queries
      let salesQuery = "?";
      let balanceTransactionsQuery = "?";

      let startDate = null;
      let endDate = null;
      if (instance.exportOptions.capturedAtPreset === "custom") {
        startDate = moment(instance.exportOptions.capturedAtFromDate).startOf(
          "day"
        );

        endDate = moment(instance.exportOptions.capturedAtToDate).endOf("day");
      } else if (instance.exportOptions.capturedAtPreset === "this_week") {
        startDate = moment().startOf("week");

        endDate = moment();
      } else if (instance.exportOptions.capturedAtPreset === "last_week") {
        startDate = moment().subtract(1, "week").startOf("week");

        endDate = moment().subtract(1, "week").endOf("week");
      } else if (
        instance.exportOptions.capturedAtPreset === "previous_7_days"
      ) {
        startDate = moment().subtract(7, "days").startOf("day");

        endDate = moment();
      } else if (instance.exportOptions.capturedAtPreset === "this_month") {
        startDate = moment().startOf("month");

        endDate = moment();
      } else if (instance.exportOptions.capturedAtPreset === "last_month") {
        startDate = moment().subtract(1, "month").startOf("month");

        endDate = moment().subtract(1, "month").endOf("month");
      } else if (
        instance.exportOptions.capturedAtPreset === "previous_30_days"
      ) {
        startDate = moment().subtract(30, "days").startOf("day");

        endDate = moment();
      } else if (instance.exportOptions.capturedAtPreset === "this_year") {
        startDate = moment().startOf("year");

        endDate = moment();
      } else if (instance.exportOptions.capturedAtPreset === "last_year") {
        startDate = moment().subtract(1, "year").startOf("year");

        endDate = moment().subtract(1, "year").endOf("year");
      } else if (
        instance.exportOptions.capturedAtPreset === "previous_365_days"
      ) {
        startDate = moment().subtract(365, "days").startOf("day");

        endDate = moment();
      }

      instance.exportOptions.finalStartDate = startDate;
      instance.exportOptions.finalEndDate = endDate;

      // set to query string
      if (
        instance.exportOptions.capturedAtPreset !== "all_time" &&
        startDate &&
        endDate
      ) {
        salesQuery += `capturedAt[$gte]=${startDate.toISOString()}`;
        salesQuery += `&capturedAt[$lt]=${endDate.toISOString()}`;

        balanceTransactionsQuery += `createdAt[$gte]=${startDate.toISOString()}`;
        balanceTransactionsQuery += `&createdAt[$lt]=${endDate.toISOString()}`;
      }

      // method query
      if (
        instance.exportOptions.method &&
        instance.exportOptions.method !== "null"
      ) {
        salesQuery += `&method=${instance.exportOptions.method}`;
      }

      // only export sales
      if (instance.exportOptions.includeBalanceTransactions !== true) {
        instance.exportSales(salesQuery);

        // export sales with balance transactions
      } else {
        instance.exportBalanceTransactionsWithSales(
          balanceTransactionsQuery,
          salesQuery
        );
      }
    },
    exportSales: function (salesQuery, balanceTransactions) {
      const instance = this;
      let sales = [];

      let totalAvailable = null;
      let totalCountIsApproximate = false;
      let latestRequestReturnedResults = false;

      let offset = 0;
      const limit = 100;

      instance.fetchedSalesCount = 0;
      instance.fetchingSales = true;

      whilst(
        function test(cb) {
          if (instance.showTransactionsExportModal === false) {
            // user cancelled the operation
            return cb(null, false);
          }

          if (totalAvailable === null && totalCountIsApproximate === false) {
            // first run
            return cb(null, true);
          } else if (
            totalAvailable === null &&
            totalCountIsApproximate === true
          ) {
            // total count is approximate, so we need to check whether the latest request has returned any results
            return cb(null, latestRequestReturnedResults);
          } else {
            return cb(null, sales.length < totalAvailable);
          }
        },
        function iter(callback) {
          instance.axios
            .get(`v1/sales${salesQuery}&offset=${offset}&limit=${limit}`)
            .then((response) => {
              if (totalAvailable === null) {
                if (
                  response.headers["total-count"] &&
                  response.headers["total-count-is-approximate"] === "false"
                ) {
                  totalAvailable = parseInt(response.headers["total-count"]);
                  instance.salesTotal = totalAvailable;
                  totalCountIsApproximate = false;
                } else {
                  instance.salesTotal = parseInt(
                    response.headers["total-count"]
                  );
                  instance.salesTotal = instance.salesTotal * 1.2; // approximate total
                  totalCountIsApproximate = true;
                }
              }

              if (response.data.length) {
                latestRequestReturnedResults = true;

                // calculate progress for getting sales
                instance.fetchedSalesCount += response.data.length;
                instance.salesProgress =
                  (instance.fetchedSalesCount * 100) / instance.salesTotal;
              } else {
                latestRequestReturnedResults = false;
              }

              offset += limit;

              return response.data;
            })
            .then((result) => {
              sales = sales.concat(result);
              return callback(null);
            })
            .catch((err) => {
              return callback(err);
            });
        },
        function (err, n) {
          if (err) {
            instance.exporting = false;
            instance.exportError = err.message;
          } else {
            // completion without error
            instance.exporting = false;

            // check the user has not cancelled the export
            if (instance.showTransactionsExportModal !== false) {
              // manipulate data (select and format rows)
              const formattedSales = sales.map((sale) => {
                let saleData = [];

                if (instance.exportOptions.fileType === "csv") {
                  // CSV
                  saleData = [
                    sale.id,
                    sale.saleAmount / 100,
                    sale.gratuityAmount / 100,
                    sale.currency.toUpperCase(),
                    sale.authCode,
                    sale.retrievalReferenceNumber,
                    sale.capturedAt,
                    sale.terminalSerialNumber,
                    sale.panLastFour,
                    sale.cardScheme,
                    sale.method,
                    sale.cardType,
                    sale.externalReference,
                  ];

                  // insert fees using balance transaction
                  if (
                    instance.exportOptions.includeBalanceTransactions === true
                  ) {
                    const balanceTransaction = _.find(balanceTransactions, {
                      sourceId: sale.id,
                    });
                    if (balanceTransaction) {
                      saleData.splice(
                        3,
                        0,
                        balanceTransaction.feeAmount / 100,
                        balanceTransaction.netAmount / 100
                      );
                    } else {
                      saleData.splice(3, 0, "-", "-");
                    }
                  }
                } else if (instance.exportOptions.fileType === "pdf") {
                  // PDF
                  saleData = [
                    (sale.amount / 100).toFixed(2),
                    sale.currency.toUpperCase(),
                    moment(sale.capturedAt).format("YYYY-MM-DD HH:mm"),
                    sale.externalReference,
                  ];

                  // insert fees using balance transaction
                  if (
                    instance.exportOptions.includeBalanceTransactions === true
                  ) {
                    const balanceTransaction = _.find(balanceTransactions, {
                      sourceId: sale.id,
                    });
                    if (balanceTransaction) {
                      saleData.splice(
                        1,
                        0,
                        (balanceTransaction.feeAmount / 100).toFixed(2),
                        (balanceTransaction.netAmount / 100).toFixed(2)
                      );
                    } else {
                      saleData.splice(3, 0, "-", "-");
                    }
                  }
                }

                return saleData;
              });

              // stop showing progress
              instance.fetchingSales = false;

              // set the headers and quotes
              let headings = [];
              let quotes = [];

              if (instance.exportOptions.fileType === "csv") {
                headings = [
                  "ID",
                  "Sale Amount",
                  "Tip Amount",
                  "Currency",
                  "Auth Code",
                  "RRN",
                  "Transaction Date",
                  "Terminal Serial Number",
                  "PAN ending",
                  "Card Scheme",
                  "Payment Method",
                  "Card Type",
                  "External Reference",
                ];

                quotes = [
                  true, // ID
                  false, // sale amount
                  false, // tip amount
                  false, // currency
                  true, // auth code
                  true, // RRN
                  false, // transaction date
                  true, // TSN
                  true, // PAN ending
                  false, // scheme
                  false, // method
                  false, // type
                  true, // external reference
                ];

                if (
                  instance.exportOptions.includeBalanceTransactions === true
                ) {
                  headings.splice(3, 0, "Fee Amount", "Net Amount");
                  quotes.splice(3, 0, false, false);
                }
              } else if (instance.exportOptions.fileType === "pdf") {
                headings = [
                  "Amount",
                  "Currency",
                  "Transaction Date",
                  "External Reference",
                ];

                if (
                  instance.exportOptions.includeBalanceTransactions === true
                ) {
                  headings.splice(1, 0, "Fee Amount", "Net Amount");
                  // quotes.splice(1, 0, false, false); // quotes not relevant for PDF
                }
              }

              // download the file
              let dateRangeText;

              if (
                instance.exportOptions.finalStartDate &&
                instance.exportOptions.finalEndDate
              ) {
                dateRangeText = `${instance.exportOptions.finalStartDate.format(
                  "YYYY-MM-DD"
                )} to ${instance.exportOptions.finalEndDate.format(
                  "YYYY-MM-DD"
                )}`;
              } else {
                dateRangeText = "All Time";
              }

              const filetitle = `CabCard Transactions Export for ${instance.submerchant.name} - ${dateRangeText}`;

              if (instance.exportOptions.fileType === "csv") {
                // write to CSV
                const csv = instance.$papa.unparse(
                  {
                    fields: headings,
                    data: formattedSales,
                  },
                  {
                    delimiter: ",",
                    quotes,
                  }
                );

                // Trigger "download"
                instance.$papa.download(csv, filetitle);
              } else if (instance.exportOptions.fileType === "pdf") {
                // write the PDF
                const pdf = new jsPDF({
                  orientation: "landscape",
                  unit: "pt",
                  format: "a4",
                });

                pdf.text(filetitle, 15, 25);

                autoTable(pdf, {
                  head: [headings],
                  body: formattedSales,
                  margin: { top: 20, right: 20, bottom: 20, left: 20 },
                  startY: 40,
                });

                pdf.save(`${filetitle}.pdf`);
              }

              // Show swal alert - success
              swal({
                type: "success",
                title: "Export complete",
                text: `Your file download has started automatically.`,
              });

              // close modal
              instance.$emit("close-modal");
            }
          }
        }
      );
    },
    exportBalanceTransactionsWithSales: function (
      balanceTransactionsQuery,
      salesQuery
    ) {
      const instance = this;
      let balanceTransactions = [];

      let totalAvailable = null;
      let totalCountIsApproximate = false;
      let latestRequestReturnedResults = false;

      let offset = 0;
      const limit = 100;

      instance.fetchedBalanceTransactionsCount = 0;
      instance.fetchingBalanceTransactions = true;

      whilst(
        function test(cb) {
          if (instance.showTransactionsExportModal === false) {
            // user cancelled the operation
            return cb(null, false);
          }

          if (totalAvailable === null && totalCountIsApproximate === false) {
            // first run
            return cb(null, true);
          } else if (
            totalAvailable === null &&
            totalCountIsApproximate === true
          ) {
            // total count is approximate, so we need to check whether the latest request has returned any results
            return cb(null, latestRequestReturnedResults);
          } else {
            return cb(null, balanceTransactions.length < totalAvailable);
          }
        },
        function iter(callback) {
          instance.axios
            .get(
              `v1/balance-transactions${balanceTransactionsQuery}&type=sale&offset=${offset}&limit=${limit}`
            )
            .then((response) => {
              if (totalAvailable === null) {
                if (
                  response.headers["total-count"] &&
                  response.headers["total-count-is-approximate"] === "false"
                ) {
                  totalAvailable = parseInt(response.headers["total-count"]);
                  instance.balanceTransactionsTotal = totalAvailable;
                  totalCountIsApproximate = false;
                } else {
                  instance.balanceTransactionsTotal = parseInt(
                    response.headers["total-count"]
                  );
                  instance.balanceTransactionsTotal =
                    instance.balanceTransactionsTotal * 1.2; // approximate total
                  totalCountIsApproximate = true;
                }
              }

              if (response.data.length) {
                latestRequestReturnedResults = true;

                // calculate progress for getting balance transactions
                instance.fetchedBalanceTransactionsCount +=
                  response.data.length;
                instance.balanceTransactionsProgress =
                  (instance.fetchedBalanceTransactionsCount * 100) /
                  instance.balanceTransactionsTotal;
              } else {
                latestRequestReturnedResults = false;
              }

              offset += limit;

              return response.data;
            })
            .then((result) => {
              balanceTransactions = balanceTransactions.concat(result);
              return callback(null);
            })
            .catch((err) => {
              return callback(err);
            });
        },
        function (err, n) {
          instance.fetchingBalanceTransactions = false;

          if (err) {
            instance.exporting = false;
            instance.exportError = err.message;
          } else {
            if (instance.showTransactionsExportModal === false) {
              // user cancelled the operation, do nothing (could notify: cancelled)
            } else {
              // match balance transactions to sales and export
              instance.exportSales(salesQuery, balanceTransactions);
            }
          }
        }
      );
    },
    cancelExport: function () {
      this.exporting = false;
      this.fetchingSales = false;
      this.fetchingBalanceTransactions = false;
      this.$emit("close-modal");
    },
  },
  data() {
    return {
      salesTotal: 0,
      fetchedSalesCount: 0,
      salesProgress: 0,
      balanceTransactionsTotal: 0,
      fetchedBalanceTransactionsCount: 0,
      balanceTransactionsProgress: 0,
      fetchingSales: false,
      fetchingBalanceTransactions: false,
      exporting: false,
      exportError: null,
      exportOptions: {
        capturedAtFromDate: null,
        capturedAtToDate: null,
        capturedAtPreset: "last_month",
        fileType: "csv", // default: csv
        finalStartDate: null,
        finalEndDate: null,
        method: null,
        includeBalanceTransactions: false,
      },
      exportDatePickerFromOptions: {
        disabledDate(date) {
          // date must be today or earlier
          return date > new Date();
        },
      },
      exportDatePickerToOptions: {
        disabledDate(date) {
          // date must be today or earlier
          if (date < new Date()) {
            return false;
          }
        },
      },
    };
  },
};
</script>
