<template>
  <div class="card">
    <div class="card-header">
      <h4 class="title">Terminal sales</h4>
    </div>
    <div class="card-body">
      <active-filter-list
        :activeFilters="activeFilters"
        :tableName="tableName"
        @remove-filter="removeFilter"
        @clear-filters="clearFilters"
      ></active-filter-list>

      <v-server-table
        :name="tableName"
        :url="`/v1/sales?terminalId=${terminalId}`"
        :columns="columns"
        :options="options"
        :ref="tableName"
        @loaded="onLoaded"
        v-on:filter="addToActiveFilters"
      >
        <template slot="id" slot-scope="props">
          <code>{{ props.row.id }}</code>
        </template>

        <div slot="filter__capturedAt">
          <date-range-picker
            class="picker"
            ref="picker"
            :opens="opens"
            v-model="dateRange"
            @update="updateValues"
            style="z-index: 1; position: inherit"
          >
            <template
              class="picker-dates"
              v-slot:input="picker"
              style="min-width: 350px"
            >
              {{
                picker.startDate
                  ? `${picker.startDate.toISOString().split("T")[0]} -`
                  : "Select date range"
              }}
              {{
                picker.endDate ? picker.endDate.toISOString().split("T")[0] : ""
              }}
            </template>
          </date-range-picker>
        </div>

        <template slot="capturedAt" slot-scope="props">
          <div>{{ props.row.capturedAt | moment("YYYY-MM-DD HH:mm A") }}</div>
        </template>

        <template slot="amount" slot-scope="props">
          <span>{{ props.row.amount | money(props.row.currency) }}</span>
        </template>

        <template slot="panLastFour" slot-scope="props">
          <div>************{{ props.row.panLastFour }}</div>
        </template>

        <template slot="authCode" slot-scope="props">
          <code>{{ props.row.authCode }}</code>
        </template>

        <template slot="externalReference" slot-scope="props">
          <code v-if="props.row.externalReference">{{
            props.row.externalReference
          }}</code>
        </template>

        <template slot="links" slot-scope="props">
          <router-link
            :to="`/transactions/sales/${props.row.id}`"
            class="btn btn-default btn-sm"
          >
            View &nbsp;
            <i class="fa fa-chevron-right"></i>
          </router-link>
        </template>
      </v-server-table>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import { requestAdapter } from "../../../../helpers/tableHelpers";
import ActiveFilterList from "../../../../UIComponents/ActiveFilterList.vue";

// daterangepicker filter dependencies
import jquery from "jquery";
import moment from "moment";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import { Event } from "vue-tables-2-premium";
window.$ = jquery;
window.moment = moment;

const columns = [
  "id",
  "amount",
  "cardScheme",
  "cardType",
  "authCode",
  "panLastFour",
  "capturedAt",
  "externalReference",
  "links",
];

export default {
  name: "TerminalSalesList",
  components: {
    ActiveFilterList,
    DateRangePicker,
  },
  props: ["terminalId"],
  methods: {
    onLoaded() {
      this.$nextTick(() => {
        // fixes issue where page number displayed incorrectly
        this.$refs[this.tableName].loadState();
      });
    },
    updateValues(vals) {
      let filter = { name: "date", value: vals };
      let isDuplicate = this.containsObject(filter, this.activeFilters);
      if (!isDuplicate) {
        this.activeFilters.push(filter);
      }
      Event.$emit(`vue-tables.${this.tableName}.filter::capturedAt`, {
        start: moment(vals.startDate).startOf("day"),
        end: moment(vals.endDate).endOf("day"),
      });
    },
    clearFilters() {
      this.dateRange.startDate = null;
      this.dateRange.endDate = null;
      this.$refs[this.tableName].resetQuery();
      this.$refs[this.tableName].resetCustomFilters();
      this.activeFilters = [];

      Event.$emit(`vue-tables.${this.tableName}.filter::capturedAt`, "");
    },
    containsObject(obj, list) {
      for (const i in list) {
        if (obj.name === list[i].name) {
          return true;
        }
      }

      return false;
    },
    removeFilter(filter) {
      const filterObj = {};
      filterObj[filter.name] = "";
      this.$refs[this.tableName].setFilter(filterObj);
    },
    addToActiveFilters(filter) {
      let isDuplicate = this.containsObject(filter, this.activeFilters);

      if (filter.value !== "" && !isDuplicate) {
        this.activeFilters.push(filter);
      } else if (filter.value === "") {
        const index = this.activeFilters.indexOf(filter);
        this.activeFilters.splice(index, 1);
      }
    },
    loadActiveFilters() {
      const tableData = this.$refs[this.tableName].getRequestParams();
      const activeQueries = tableData["query"];

      if (tableData["capturedAt"] !== "") {
        activeQueries.date = tableData["capturedAt"];
      }

      for (let query in activeQueries) {
        if (activeQueries[query] !== "") {
          const queryObj = { name: query, value: activeQueries[query] };
          this.activeFilters.push(queryObj);
        }
      }
    },
  },
  mounted() {
    this.loadActiveFilters();
  },
  data() {
    return {
      columns,
      tableName: `terminalSalesList_${this.terminalId}`,
      activeFilters: [],
      options: {
        table: this.tableName,
        pagination: { chunk: 3 },
        requestAdapter,
        customFilters: ["capturedAt"],
        columnsDropdown: false,
        columnsDisplay: {
          amount: "min_mobileP",
          cardScheme: "min_desktop",
          cardType: "min_desktop",
          authCode: "min_desktop",
          panLastFour: "min_tabletL",
          capturedAt: "min_mobileL",
          link: "min_mobile",
        },
        headings: {
          link: "",
          amount: "Amount",
          cardScheme: "Scheme",
          cardType: "Card type",
          authCode: "Auth code",
          panLastFour: "PAN (last four)",
          capturedAt: "Date",
          externalReference: "Reference",
        },
        sortable: ["amount", "capturedAt"],
        filterable: [
          "cardScheme",
          "cardType",
          "panLastFour",
          "authCode",
          "externalReference",
        ],
        listColumns: {
          cardScheme: [
            {
              id: "visa",
              text: "Visa",
            },
            {
              id: "mastercard",
              text: "Mastercard",
            },
            {
              id: "american_express",
              text: "American Express",
            },
          ],
          cardType: [
            {
              id: "credit",
              text: "Credit",
            },
            {
              id: "debit",
              text: "Debit",
            },
          ],
        },
      },
      dateRange: {
        startDate: null,
        endDate: null,
      },
      opens: "left",
    };
  },
};
</script>
