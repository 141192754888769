import Vue from "vue";
import VueRouter from "vue-router";
import VueNotify from "vue-notifyjs";
import VeeValidate from "vee-validate";
import axios from "axios";
import VueAxios from "vue-axios";
import Storage from "vue-ls";
import VueMoment from "vue-moment";
import moment from "moment";
import { ServerTable } from "vue-tables-2-premium";
import lang from "element-ui/lib/locale/lang/en";
import locale from "element-ui/lib/locale";
import FlagIcon from "vue-flag-icon";
import VueTelInput from "vue-tel-input";
import Es6Promise from "es6-promise";
import VuePapaParse from "vue-papa-parse";
import * as Sentry from "@sentry/vue";
import VueSweetalert2 from "vue-sweetalert2";
import VueCurrencyInput from "vue-currency-input";
import VueClipboard from "vue-clipboard2";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import LogRocket from "logrocket";
import LogrocketFuzzySanitizer from "logrocket-fuzzy-search-sanitizer";

import "./pollyfills";
import App from "./App.vue";
import store from "./store";
import getDeviceId from "./device";

// If you don't need the styles, do not connect
import "sweetalert2/dist/sweetalert2.min.css";

if (process.env.NODE_ENV !== "development") {
  // only use Sentry in non-development environments
  Sentry.init({
    Vue: Vue,
    dsn: "https://5f30c5adf66f41f3910dd3b583597cf8@o281596.ingest.sentry.io/1507137",
    attachProps: true,
    logErrors: true,
  });

  // Use Logrocket in non-development environments
  const logRocketId = "nqjkmf/cabcard-submerchants-dashboard";
  const privateFieldNames = [
    "password",
    "mfaToken",
    "currentPassword",
    "newPassword",
  ];

  const { requestSanitizer, responseSanitizer } =
    LogrocketFuzzySanitizer.setup(privateFieldNames);

  LogRocket.init(logRocketId, {
    network: {
      requestSanitizer,
      responseSanitizer,
    },
  });
}

// ES6 Promise Polyfill
Es6Promise.polyfill();

// Import Helpers for filters
import {
  domain,
  count,
  pluralize,
  money,
  humanize,
  renderUrls,
} from "./filters";

// Import Install and register helper items
Vue.filter("count", count);
Vue.filter("domain", domain);
Vue.filter("pluralize", pluralize);
Vue.filter("money", money);
Vue.filter("humanize", humanize);
Vue.filter("urls", renderUrls);

// Plugins
import GlobalComponents from "./globalComponents";
import GlobalDirectives from "./globalDirectives";
import SideBar from "./components/UIComponents/SidebarPlugin";
import initProgress from "./progressbar";

// router setup
import routes from "./routes/routes";

// config setup
import config from "./config.js";

// library imports
import "./assets/sass/paper-dashboard.scss";
import "./assets/sass/element_variables.scss";
import "./assets/sass/demo.scss";
import "./assets/sass/custom.scss";

import sidebarLinks from "./sidebarLinks";

const vueTablesServerOptions = {
  filterByColumn: true,
  saveState: true,
  sortIcon: {
    base: "fa",
    up: "fa-chevron-up",
    down: "fa-chevron-down",
    is: "fa-sort",
  },
  requestAdapter: (input) => {
    const output = {};

    // sort
    if (input.orderBy && input.orderBy !== "") {
      output._sort = input.orderBy;

      if (input.ascending === 0) {
        output._order = "desc";
      } else {
        output._order = "asc";
      }
    }

    // paginate (limit and offset)
    output._limit = input.limit;
    output._page = input.page;

    // filter
    _.each(input.query, (val, key) => {
      if (val && val !== "") {
        output[key] = val;
      }
    });

    return output;
  },
  responseAdapter: (resp) => {
    let count = resp.data.length;
    if (resp.headers["total-count"]) {
      count = parseInt(resp.headers["total-count"]);
    }
    return {
      data: resp.data,
      count,
    };
  },
};

window.axios = axios; // for vue tables server table

// plugin setup
Vue.use(VueRouter);
Vue.use(GlobalDirectives);
Vue.use(GlobalComponents);
Vue.use(VueNotify);
Vue.use(SideBar, { sidebarLinks: sidebarLinks });
Vue.use(VeeValidate);
Vue.use(VueAxios, axios);
Vue.use(VueMoment, { moment });
Vue.use(Storage, {
  namespace: `${config.namespace}__`, // key prefix
  name: "ls", // name variable Vue.[ls] or this.[$ls],
  storage: "local", // storage name session, local, memory
});
Vue.use(
  ServerTable,
  vueTablesServerOptions,
  false,
  "bootstrap4",
  "default" // "footerPagination"
);
locale.use(lang);
Vue.use(FlagIcon);
Vue.use(VueTelInput); // can define default global options here using VueTelInput, [globalOptions = {}]
Vue.use(VuePapaParse);
Vue.use(VueSweetalert2);
Vue.use(VueCurrencyInput);
Vue.use(VueClipboard);

// configure axios
Vue.axios.defaults.baseURL = config.apiBaseUrl;
Vue.axios.defaults.withCredentials = true;

// Function that will be called to refresh authorization
const refreshAuthLogic = (failedRequest) => {
  return axios
    .post(
      "/v1/tokens",
      {
        type: "access",
        grantType: "refresh_token",
        deviceIdentifier: getDeviceId(),
        attachments: ["submerchant"],
      },
      { skipAuthRefresh: true }
    )
    .then((response) => {
      const expSeconds =
        moment(response.data.expiresAt).unix() - moment().unix();
      Vue.ls.set(
        "access_token_expires_at",
        response.data.expiresAt,
        expSeconds * 1000 // expiry milliseconds
      );
      return Promise.resolve();
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

// Instantiate the interceptor (you can chain it as it returns the axios instance)
createAuthRefreshInterceptor(Vue.axios, refreshAuthLogic, {
  statusCodes: [401, 403],
  pauseInstanceWhileRefreshing: false,
});

// request interceptor to add submerchant ID when relevant
Vue.axios.interceptors.request.use(
  function (vueConfig) {
    // only apply request interceptor when calling API
    if (vueConfig.url.includes("statuspal")) {
      return vueConfig;
    }

    vueConfig.headers["x-apicache-bypass"] = true; // do not get cached responses

    if (vueConfig.url.includes("/v1/tokens")) {
      return vueConfig;
    }

    // set token perspective to request headers if set
    const account = store.state.submerchant;

    if (account && account.id) {
      vueConfig.headers["token-perspective"] = account.id;
    }

    return vueConfig;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Axios response interceptor for expired session
Vue.axios.interceptors.response.use(null, (error) => {
  const logoutMessages = [
    "Token expired",
    "Device identifier required",
    "Invalid device",
  ];

  // handle an invalid access token
  if (
    error.response.status === 401 &&
    error.response.data.code === "invalid_token"
  ) {
    // clear the token - this should redirect user to login page
    store.dispatch("logout", null, { root: true });
  }

  if (
    error.response.config.url === "/v1/tokens" &&
    error.response.status === 401 &&
    logoutMessages.includes(error.response.data.message)
  ) {
    // clear the token - this should redirect user to login page
    store.dispatch("logout", null, { root: true });
  }

  return Promise.reject(error);
});

// configure router
const router = new VueRouter({
  routes,
  linkActiveClass: "active",
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
      };
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

function authExpiredGuard(to, from, next) {
  // check logged in state
  const refreshTokenExpiresAt = Vue.ls.get("refresh_token_expires_at");

  if (
    to.meta.authRequired === true &&
    (!refreshTokenExpiresAt || moment(refreshTokenExpiresAt).isBefore(moment()))
  ) {
    store.dispatch("logout");
    // redirect to login
    return next({ name: "Login" });
  } else {
    return next();
  }
}

function authRequiredGuard(to, from, next) {
  if (!store.state.loggedIn && to.meta.authRequired === true) {
    // redirect to login
    return next({ name: "Login" });
  } else {
    return next();
  }
}

router.beforeEach(authExpiredGuard);
router.beforeEach(authRequiredGuard);

Vue.router = router;

initProgress(router);

/* eslint-disable no-new */
new Vue({
  el: "#app",
  render: (h) => h(App),
  router,
  store,
});
