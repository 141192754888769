<template>
  <form>
    <div class="form-group">
      <label>API Key Name</label>
      <fg-input
        type="text"
        name="name"
        placeholder="e.g. My API Key"
        v-validate="{ required: false }"
        :error="getError('name')"
        v-model="name"
      >
      </fg-input>
    </div>

    <label>Permissions</label>
    <p class="text-small">
      Take care to select only appropriate permissions, to minimise security
      risks for your account. If you're unsure what you need, please contact us.
    </p>
    <div class="row mb-2" v-for="resource in resources" :key="resource.name">
      <div class="col-12">{{ resource.label }}</div>
      <div
        class="col-12 col-sm-6 col-lg-4"
        v-for="(operation, index) in resource.operations"
        :key="index"
      >
        <p-checkbox
          v-model="permissions[`${resource.name}:${operation}`].selected"
          >{{ capitalizeFirstLetter(operation) }}
          {{ resource.label }}</p-checkbox
        >
      </div>
    </div>

    <div v-if="errorMessage" class="alert alert-warning" role="warning">
      <strong>Error: </strong>{{ errorMessage }}
    </div>

    <div class="text-right">
      <p-button type="default" @click.prevent="cancel" :disabled="working">
        <span>Cancel &nbsp;<i class="fa fa-times ml-2"></i></span>
      </p-button>
      &nbsp;
      <p-button type="info" @click.prevent="create" :disabled="working">
        <span v-if="!working"
          >Create API Key &nbsp;<i class="fa fa-arrow-right ml-2"></i
        ></span>
        <span v-else
          ><i class="fa fa-spin fa-spinner"></i>&nbsp; wait &hellip;</span
        >
      </p-button>

      <hr />
    </div>
  </form>
</template>

<script>
import _ from "lodash";

export default {
  data() {
    return {
      name: null,
      resources: [
        {
          label: "Balances",
          name: "balances",
          operations: ["read"],
        },
        {
          label: "Balance Transactions",
          name: "balanceTransactions",
          operations: ["read"],
        },
        {
          label: "Bank Accounts",
          name: "bankAccounts",
          operations: ["read"],
        },
        {
          label: "Payment Intents",
          name: "intents",
          operations: ["create", "read", "update", "delete"],
        },
        {
          label: "Payouts",
          name: "payouts",
          operations: ["read"],
        },
        {
          label: "Sales",
          name: "sales",
          operations: ["read", "update"],
        },
        {
          label: "SoftPOS Installations",
          name: "softposInstallations",
          operations: ["create", "read", "update"],
        },
        {
          label: "SoftPOS Transactions",
          name: "softposTransactions",
          operations: ["create", "read", "update"],
        },
        {
          label: "Submerchants",
          name: "submerchants",
          operations: ["read", "update"],
        },
        {
          label: "Terminals",
          name: "terminals",
          operations: ["read", "update"],
        },
        {
          label: "Users",
          name: "users",
          operations: ["read", "update"],
        },
        {
          label: "Webhook Endpoints",
          name: "webhookEndpoints",
          operations: ["create", "read", "update", "delete"],
        },
        {
          label: "Webhook Notifications",
          name: "webhookNotifications",
          operations: ["read"],
        },
      ],
      permissions: {},
      working: false,
      errorMessage: null,
    };
  },
  computed: {
    submerchant: function () {
      return this.$store.state.submerchant;
    },
  },
  beforeMount() {
    this.resources.forEach((resource) => {
      resource.operations.forEach((operation) => {
        this.permissions[`${resource.name}:${operation}`] = {
          selected: false,
        };
      });
    });
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    cancel: function () {
      this.$emit("cancelled");
    },
    create: function () {
      const instance = this;
      instance.working = true;

      const permissions = [];
      _.each(instance.permissions, (item, key) => {
        if (item.selected) {
          permissions.push(key);
        }
      });

      const requestBody = {
        submerchantId: instance.submerchant.id,
        name: instance.name,
        permissions: permissions,
      };

      if (!instance.name) {
        delete requestBody.name;
      }

      instance.axios
        .post(`/v1/api-keys`, requestBody)
        .then(function (response) {
          instance.$emit("created", response.data);
          instance.working = false;

          instance.$swal({
            icon: "success",
            title: "API Key created",
            text: `Token: ${response.data.token}`,
            footer:
              "Copy the token and store it securely. This will never be displayed again.",
          });
        })
        .catch(function (err) {
          instance.working = false;
          instance.errorMessage = `${err.response.data.message}. `;
          _.each(err.response.data.errors, (error, index) => {
            instance.errorMessage += error.messages.join(", and");
            if (index < err.response.data.errors.length - 1) {
              instance.errorMessage += ", and";
            } else {
              instance.errorMessage += ".";
            }
          });
        });
    },
  },
};
</script>
