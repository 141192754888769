<template>
  <div
    class="sidebar"
    :data-color="backgroundColor"
    :data-active-color="activeColor"
  >
    <div class="logo">
      <a class="simple-text logo-mini" href="/">
        <div class="logo-img">
          <img :src="logo" alt="" />
        </div>
      </a>
      <a class="simple-text logo-normal" href="/">
        {{ title }}
      </a>
    </div>
    <div class="sidebar-wrapper" ref="sidebarScrollArea">
      <slot> </slot>
      <ul class="nav">
        <slot name="links">
          <sidebar-item
            v-for="(link, index) in filteredLinks"
            :key="link.name + index"
            :link="link"
          >
            <sidebar-item
              v-for="(subLink, index) in link.children"
              :key="subLink.name + index"
              :link="subLink"
            >
            </sidebar-item>
          </sidebar-item>
        </slot>
      </ul>
    </div>
  </div>
</template>
<script>
import "perfect-scrollbar/dist/css/perfect-scrollbar.css";
export default {
  props: {
    title: {
      type: String,
      default: "Vue PDP Pro",
      description: "Sidebar title",
    },
    backgroundColor: {
      type: String,
      default: "black",
      validator: (value) => {
        let acceptedValues = ["white", "brown", "black"];
        return acceptedValues.indexOf(value) !== -1;
      },
      description: "Sidebar background color (white|brown|black)",
    },
    activeColor: {
      type: String,
      default: "success",
      validator: (value) => {
        let acceptedValues = [
          "primary",
          "info",
          "success",
          "warning",
          "danger",
        ];
        return acceptedValues.indexOf(value) !== -1;
      },
      description:
        "Sidebar active text color (primary|info|success|warning|danger)",
    },
    logo: {
      type: String,
      default: "/static/img/vue-logo.png",
      description: "Sidebar Logo",
    },
    sidebarLinks: {
      type: Array,
      default: () => [],
      description:
        "Sidebar links. Can be also provided as children components (sidebar-item)",
    },
    autoClose: {
      type: Boolean,
      default: true,
    },
    submerchant: {
      type: Object,
    },
    submerchantUser: {
      type: Object,
    },
  },
  computed: {
    filteredLinks: function () {
      const filteredByWhitelist = this.sidebarLinks.filter((item) => {
        if (!item.whitelistedSubmerchants) {
          return true;
        } else {
          return (
            item.whitelistedSubmerchants.includes(this.submerchant.id) ||
            item.whitelistedOperators.includes(this.submerchant.operatorId)
          );
        }
      });

      const filteredByUserRole = filteredByWhitelist.filter((item) => {
        if (!item.submerchantUserRoles) {
          return true;
        } else if (!this.submerchantUser) {
          return false;
        } else {
          return item.submerchantUserRoles.includes(
            this.submerchantUser.submerchantRole
          );
        }
      });

      return filteredByUserRole;
    },
  },
  provide() {
    return {
      autoClose: this.autoClose,
    };
  },
  methods: {
    async initScrollBarAsync() {
      const PerfectScroll = await import("perfect-scrollbar");
      PerfectScroll.initialize(this.$refs.sidebarScrollArea);
    },
  },
  mounted() {
    this.initScrollBarAsync();
    const link = this.sidebarLinks[2];
  },
  beforeDestroy() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false;
    }
  },
};
</script>
<style>
@media (min-width: 992px) {
  .navbar-search-form-mobile,
  .nav-mobile-menu {
    display: none;
  }
}
</style>
