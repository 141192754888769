<template>
  <span>
    <span v-if="loading">
      <i class="fa fa-spinner fa-spin"></i>&nbsp; Please wait&hellip;
    </span>
    <span v-else-if="error"> Error: {{ error }} </span>

    <span v-if="apiKey">
      <span class="fa-stack fa-lg text-muted">
        <i class="fa fa-square-o fa-stack-2x"></i>
        <i class="fa fa-key fa-stack-1x"></i>
      </span>
      &nbsp;
      <span>
        {{ apiKey.name }}
      </span>
    </span>
  </span>
</template>
<script>
import { Tooltip } from "element-ui";

export default {
  name: "ApiKeyFragment",
  components: {
    [Tooltip.name]: Tooltip,
  },
  props: {
    apiKeyId: {
      type: String,
      required: true,
    },
    linkClasses: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      apiKey: null,
      error: null,
      loading: false,
    };
  },
  methods: {
    loadApiKey() {
      this.loading = true;
      this.axios
        .get(`/v1/api-keys/${this.apiKeyId}`)
        .then((response) => {
          this.loading = false;
          if (response.status !== 200) {
            this.error = response.statusText;
            return;
          }

          this.apiKey = response.data;
        })
        .catch((error) => {
          this.loading = false;
          // Request failed.
          this.error = error.response.statusText;
        });
    },
  },
  mounted() {
    if (this.apiKeyId) {
      this.loadApiKey();
    }
  },
};
</script>

<style></style>
