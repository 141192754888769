<template>
  <div class="login-page">
    <app-navbar></app-navbar>
    <div class="wrapper wrapper-full-page">
      <div class="full-page login-page">
        <!--   you can change the color of the filter page using: data-color="blue | azure | green | orange | red | purple" -->
        <div class="content">
          <div class="container">
            <div class="col-lg-4 col-md-6 ml-auto mr-auto">
              <card type="login">
                <h3 slot="header" class="header text-center">
                  Reset your password
                </h3>

                <fg-input
                  v-if="!success"
                  v-model="form.emailAddress"
                  name="emailAddress"
                  type="email"
                  data-vv-as="email address"
                  data-vv-delay="1000"
                  v-validate="formValidators.emailAddress"
                  :error="fieldError('emailAddress')"
                  addon-left-icon="nc-icon nc-single-02"
                  @keyup.enter="validate"
                  placeholder="Email Address"
                ></fg-input>

                <div class="alert alert-warning" v-if="error">
                  <span>{{ error }}</span>
                </div>

                <div class="alert alert-success" v-if="success">
                  <span
                    >We've sent you an email. Please check your inbox and follow
                    the instructions to reset your password.</span
                  >
                </div>

                <template slot="footer">
                  <p-button
                    v-if="!working && !success"
                    type="submit"
                    round
                    block
                    class="mb-3"
                    @click.prevent="validate"
                  >
                    Request password reset &nbsp;
                    <i class="fa fa-arrow-right"></i>
                  </p-button>
                  <p-button
                    v-if="working && !success"
                    type="info"
                    round
                    block
                    class="mb-3"
                    disabled
                  >
                    <i class="fa fa-spin fa-circle-o-notch"></i>&nbsp; Please
                    wait&hellip;
                  </p-button>

                  <p class="text-right">
                    <router-link to="/login">Log in</router-link>
                  </p>
                </template>
              </card>
            </div>
          </div>
        </div>
        <app-footer></app-footer>
        <div
          class="full-page-background"
          style="background-image: url('/static/img/background/yellow-bg.svg')"
        ></div>
      </div>
    </div>
  </div>
</template>
<script>
import { Card, Checkbox, Button } from "src/components/UIComponents";
import AppNavbar from "./Layout/AppNavbar";
import AppFooter from "./Layout/AppFooter";
import config from "../../../../config";

export default {
  components: {
    Card,
    AppNavbar,
    AppFooter,
    [Checkbox.name]: Checkbox,
    [Button.name]: Button,
  },
  methods: {
    toggleNavbar() {
      document.body.classList.toggle("nav-open");
    },
    closeMenu() {
      document.body.classList.remove("nav-open");
      document.body.classList.remove("off-canvas-sidebar");
    },
    validate() {
      this.$validator.validateAll().then((isValid) => {
        this.$emit("on-submit", this.registerForm, isValid);
        if (isValid) {
          this.login();
        }
      });
    },
    fieldError(fieldName) {
      // clientside
      if (this.errors.first(fieldName)) {
        return this.errors.first(fieldName);
      }
    },
    login() {
      this.working = true;

      const requestBody = {
        completionUrl: `${config.baseUrl}/reset-password`,
        paymentFacilitatorId: `${config.paymentFacilitatorId}`,
        emailAddress: this.form.emailAddress,
      };

      this.axios
        .post("/v1/password-reset-tickets", requestBody)
        .then((response) => {
          this.working = false;
          this.success = true;
        })
        .catch((error) => {
          this.working = false;
          this.error = "Failed to create password reset ticket";
        });
    },
  },
  data() {
    return {
      form: {
        emailAddress: "",
      },
      formValidators: {
        emailAddress: {
          required: true,
          email: true,
        },
      },
      validationErrors: {},
      error: null,
      working: false,
      success: false,
    };
  },
  beforeMount() {
    // set email address from URL param
    if (this.$route.query.emailAddress) {
      this.form.emailAddress = this.$route.query.emailAddress;
    }
  },
  beforeDestroy() {
    this.closeMenu();
  },
};
</script>
<style></style>
