<template>
  <div class="row">
    <div class="col-12 col-lg-8">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-md-6">
              <h4 class="title">Scan to pay</h4>
              <a class="card-subtitle text-muted">Let your customers pay securely any time</a>
            </div>
          </div>
        </div>

        <div class="card-body text-center" v-if="!submerchant">
          <img src="/static/img/illustrations/warning-sign.svg" class="p-5 w-50" />
          <p class="lead">Oops - we couldn't load your account.</p>
          <a class="btn btn-lg btn-info" href="javascript:window.location.reload();">Refresh this page <i
              class="fa fa-reload ml-2"></i></a>
        </div>

        <div v-else-if="submerchant && submerchant.finaroId">
          <div class="row">
            <div class="col-12 col-lg-6">
              <div>
                <div class="card-body">
                  <h5 class="h5 mt-0">Anytime payment link</h5>
                  <p>
                    You can accept payments as long as your customer has a mobile phone. You won't need to generate
                    a new link for each customer.
                  </p>
                  <ol>
                    <li>Ask customer to scan QR code using their phone’s camera</li>
                    <li>Enter the transaction amount</li>
                    <li>Your customer can pay online</li>
                  </ol>
                </div>

                <helpText v-if="!hasTouchScreen"></helpText>
              </div>
            </div>

            <div class="col-12 col-lg-6">
              <!-- QR code -->
              <div class="qr-container">
                <qr-code style="display: flex; justify-content: center;" :text="anytimeLink" :size="300"></qr-code>
              </div>
              <!-- download button -->
              <div class="text-center">
                <a class="btn btn-primary text-white" @click="pdfDownload">
                  <i class="fa fa-download mr-2"></i> Download and print for easy access
                </a>
              </div>

              <!-- on mobile it looks better if this is below the QR code -->
              <helpText v-if="hasTouchScreen"></helpText>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Other options card -->
    <div class="col-12 col-lg-4">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-md-12">
              <h4 class="title">Other options</h4>
              <a class="card-subtitle text-muted">Explore additional payment options</a>
            </div>
          </div>
        </div>
        <!-- Main payment links page -->
        <div class="card-body">
          <h5 class="h5 mt-0">Send payment requests</h5>
          <p>
            You can create a payment link for a specific customer. This will
            allow you to send a link to a customer by SMS or email.
          </p>

          <!-- button link to payment links page -->
          <div class="text-center">
            <router-link class="btn btn-primary" to="/payment-links/create">Send a payment request</router-link>
          </div>
        </div>
        <!-- download app -->
        <div class="card-body">
          <h5 class="h5 mt-0">Contactless payments app</h5>
          <p>
            You can take contactless payments using your Android mobile phone:
          </p>
          <ol>
            <li v-if="hasTouchScreen">Download the app by following this link:
              <!-- this doesn't work if the href uses the variable -->
              <a href=https://cab.link/cc-go>{{ cabCardGoLink }}</a>
            </li>
            <li v-else>Download the app by scanning this QR code:
              <div style="display: flex; justify-content: center;">
                <qr-code :text="cabCardGoLink" :size="60"></qr-code>
              </div>
            </li>
            <li>Sign in with your CabCard account</li>
            <li>Tap “Take a payment”</li>
            <li>Ask your customer to tap their card on your phone</li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueQRCodeComponent from "vue-qrcode-component";
import HelpText from './HelpText.vue'

import { degrees, PDFDocument, rgb, cmyk } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';

export default {
  name: "AnytimePaymentsPage",
  components: {
    qrCode: VueQRCodeComponent,
    helpText: HelpText,
  },
  data() {
    return {
      working: false,
      error: null,
      cabCardGoLink: "https://cab.link/cc-go",
    };
  },
  computed: {
    submerchant: function () {
      return this.$store.state.submerchant;
    },
    anytimeLink: function () {
      const baseUrl = "https://pay.cabcard.services";
      return `${baseUrl}/now?s=${this.submerchant.id}`;
    },
    hasTouchScreen: function () {
      // based on code at https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent
      let hasTouchScreen = 0;
      if ("maxTouchPoints" in navigator) {
        hasTouchScreen = navigator.maxTouchPoints > 0;
      } else if ("msMaxTouchPoints" in navigator) {
        hasTouchScreen = navigator.msMaxTouchPoints > 0;
      } else if ("orientation" in window) {
        hasTouchScreen = true; // deprecated, but good fallback
      } else {
        // Only as a last resort, fall back to user agent sniffing
        const UA = navigator.userAgent;
        hasTouchScreen =
          /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
          /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
      }

      return hasTouchScreen;
    },
  },
  beforeMount() {
    this.maybeReloadSubmerchant();
  },
  methods: {
    maybeReloadSubmerchant() {
      if (!this.$store.state.submerchant.finaroId) {
        this.working = true;
        this.axios
          .get(`/v1/submerchants/${this.$store.state.submerchant.id}`, {
            headers: {
              "x-apicache-bypass": "true", // bypass API cache to get latest
            },
          })
          .then((response) => {
            const submerchant = response.data;
            this.$store.commit("SET_SUBMERCHANT", submerchant);
          })
          .catch((error) => {
            console.error("Error loading submerchant", error.message);
          })
          .finally(() => {
            this.working = false;
          });
      }
    },
    async pdfDownload() {
      // fetch pdf file
      const url = '/static/files/emergency-kit-template.pdf'
      const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer())

      // load pdf file
      const pdfDoc = await PDFDocument.load(existingPdfBytes)

      // get the first page
      const pages = pdfDoc.getPages()
      const firstPage = pages[0]

      // get the width and height of the first page
      const { width, height } = firstPage.getSize()

      // Register the `fontkit` instance
      pdfDoc.registerFontkit(fontkit)

      // load the fonts
      const fontBytes = await Promise.all([fetch('/static/fonts/HKGrotesk-ExtraBold.otf').then(res => res.arrayBuffer()),
                                            fetch('/static/fonts/HKGrotesk-Regular.otf').then(res => res.arrayBuffer())])

      // Embed the font in the document
      const boldFont = await pdfDoc.embedFont(fontBytes[0])
      const regularFont = await pdfDoc.embedFont(fontBytes[1])

      // text colour
      const textColour = cmyk(0.87, 0.77, 0.44, 0.39)
      const urlBlue = rgb(0, 0, 238 / 255)

      // create "Generated for <submerchant.name>" text and measure its width and height in our font
      const nameText = `${this.submerchant.name}`
      const nameTextSize = 20
      const nameTextWidth = boldFont.widthOfTextAtSize(nameText, nameTextSize)
      const nameTextHeight = boldFont.heightAtSize(nameTextSize)

      const nameMaxWidth = 220;

      // draw centred "Generated for <submerchant.name>" text
      const yPositionName = height - 186;
      firstPage.drawText(nameText, {
        x: 685 - Math.min(nameTextWidth, nameMaxWidth) / 2,
        y: yPositionName,
        size: nameTextSize * nameMaxWidth / Math.max(nameTextWidth, nameMaxWidth),
        font: boldFont,
        color: textColour,
        rotate: degrees(0),
      })

      // create a url string and measure its width and height in our font
      const urlText = this.anytimeLink
      const urlTextSize = 10
      const urlTextWidth = regularFont.widthOfTextAtSize(urlText, urlTextSize)
      const urlTextHeight = regularFont.heightAtSize(urlTextSize)

      const urlMaxWidth = nameMaxWidth;
      const urlLines = this.splitText(urlText, urlMaxWidth, regularFont, urlTextSize);

      // draw centred url text
      let yPositionUrl = height - 518;
      urlLines.forEach(line => {
        const lineTextWidth = regularFont.widthOfTextAtSize(line, urlTextSize)
        firstPage.drawText(line, {
          x: 685 - lineTextWidth / 2,
          y: yPositionUrl,
          size: urlTextSize,
          font: regularFont,
          color: textColour,
          rotate: degrees(0),
        })
        yPositionUrl -= urlTextHeight; // move the y position down for the next line
      });

      // get qr code from free, unlimited API (https://www.qrserver.com/)
      const imgWidth = 210;
      const imgHeight = imgWidth;
      const qrCodeUrl = `https://api.qrserver.com/v1/create-qr-code/?size=${imgWidth}x${imgHeight}&data=${this.anytimeLink}`

      const qrCodeBytes = await fetch(qrCodeUrl).then(res => res.arrayBuffer())

      // embed the image in the document
      const pngImage = await pdfDoc.embedPng(qrCodeBytes)

      // draw the QR code
      firstPage.drawImage(pngImage, {
        x: 580, // note this is the left edge of the image, from the left edge of the page
        y: height - 435, // note this is the bottom of the image, from the bottom of the page
        width: imgWidth,
        height: imgHeight,
      })

      // serialize the pdf document
      const pdfBytes = await pdfDoc.save();

      // download
      const blob = new Blob([pdfBytes], { type: 'application/pdf' });
      const dlUrl = URL.createObjectURL(blob);
      const dlLink = document.createElement('a');
      dlLink.href = dlUrl;
      dlLink.download = 'emergency-kit.pdf';
      dlLink.click();
    },
    splitText (text='', maxLength=1, font, fontSize=10) {
      let output = [];
      const baseUrl = "https://pay.cabcard.services";

      if (text.startsWith(baseUrl)) {
        text = text.substring(baseUrl.length);
        output.push(baseUrl);
      }
      let currentLength = font.widthOfTextAtSize(text, fontSize);

      const charCount = text.length;
      const numSplits = Math.ceil(currentLength / maxLength);
      const indexIncrement = Math.floor(charCount / numSplits);

      let splitIndex = 0;
      for (let i = 0; i < numSplits; i++) {
        output.push(text.substring(splitIndex, splitIndex + indexIncrement));
        splitIndex += indexIncrement;
      }

      return output;
    },
  },
};
</script>
