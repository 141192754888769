var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-group",class:[
    { 'input-group': _vm.hasIcon },
    { 'has-danger': _vm.error },
    { 'input-group-focus': _vm.focused },
    { 'has-label': _vm.label || _vm.$slots.label },
    { 'has-success': _vm.hasSuccess } ]},[_vm._t("label",[(_vm.label)?_c('label',{class:_vm.labelClasses},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.required)?_c('span',[_vm._v("*")]):_vm._e()]):_vm._e()]),(_vm.addonLeftIcon || _vm.$slots.addonLeft)?_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text"},[_vm._t("addonLeft",[_c('i',{class:_vm.addonLeftIcon})])],2)]):_vm._e(),_vm._t("default",[_c('input',_vm._g(_vm._b({ref:_vm.inputRef,staticClass:"form-control",class:[
        { valid: _vm.value && !_vm.error },
        _vm.inputClasses,
        _vm.error ? 'form-control-danger' : '' ],attrs:{"aria-describedby":"addon-right addon-left","autocomplete":_vm.autocomplete},domProps:{"value":_vm.value}},'input',_vm.$attrs,false),_vm.listeners))]),(_vm.addonRightIcon || _vm.$slots.addonRight)?_c('div',{staticClass:"input-group-append"},[_c('span',{staticClass:"input-group-text"},[_vm._t("addonRight",[_c('i',{class:_vm.addonRightIcon})])],2)]):_vm._e(),_vm._t("infoBlock"),_vm._t("helpBlock",[(_vm.error)?_c('div',{staticClass:"text-danger invalid-feedback",class:{ 'mt-2': _vm.hasIcon },staticStyle:{"display":"block"}},[(_vm.hasErrorMessages)?_c('ul',{staticClass:"text-left"},_vm._l((_vm.error),function(message,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(message)+" ")])}),0):(_vm.error.length)?_c('span',[_vm._v(_vm._s(_vm.error))]):_c('span',[_vm._v("Invalid input.")])]):_vm._e()])],2)}
var staticRenderFns = []

export { render, staticRenderFns }