<template>
  <div class="card">
    <div class="card-header">
      <h4 class="title">Payouts</h4>
    </div>

    <div class="card-body" v-if="showSubmerchantPayouts">
      <active-filter-list
        :activeFilters="activeFilters"
        :tableName="tableName"
        @remove-filter="removeFilter"
        @clear-filters="clearFilters"
      ></active-filter-list>

      <v-server-table
        ref="payoutsTable"
        name="payoutsTable"
        :url="`/v1/payouts?submerchantId=${submerchant.id}`"
        :columns="columns"
        :options="options"
        v-on:filter="addToActiveFilters"
      >
        <template slot="id" slot-scope="props">
          <code>{{ props.row.id }}</code>
        </template>

        <template slot="paymentReference" slot-scope="props">
          <code>{{ props.row.paymentReference }}</code>
        </template>

        <template slot="amount" slot-scope="props">
          <span>{{ props.row.amount | money(props.row.currency) }}</span>
        </template>

        <template slot="destinationType" slot-scope="props">
          {{ props.row.destinationType | humanize }}
        </template>

        <template slot="status" slot-scope="props">
          {{ props.row.status | humanize }}
        </template>

        <template slot="createdAt" slot-scope="props">
          <div>{{ props.row.createdAt | moment("YYYY-MM-DD") }}</div>
        </template>

        <div slot="filter__createdAt">
          <date-range-picker
            class="picker"
            ref="picker"
            :opens="opens"
            v-model="createdAtDateRange"
            @update="updateValues"
            style="z-index: 1; position: inherit"
          >
            <template
              class="picker-dates"
              v-slot:input="picker"
              style="min-width: 350px"
            >
              {{
                picker.startDate
                  ? `${picker.startDate.toISOString().split("T")[0]} -`
                  : "Select date range"
              }}
              {{
                picker.endDate ? picker.endDate.toISOString().split("T")[0] : ""
              }}
            </template>
          </date-range-picker>
        </div>

        <template slot="links" slot-scope="props">
          <router-link
            :to="`/payouts/${props.row.id}`"
            class="btn btn-default btn-sm"
          >
            View &nbsp;
            <i class="fa fa-chevron-right"></i>
          </router-link>
        </template>
      </v-server-table>
    </div>
    <div class="card-body" v-else>Not permitted to view this data.</div>
  </div>
</template>
<script>
import _ from "lodash";

import { requestAdapter } from "../../../../helpers/tableHelpers";

// daterangepicker dependencies
import jquery from "jquery";
import moment from "moment";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import { Event } from "vue-tables-2-premium";
import ActiveFilterList from "../../../../UIComponents/ActiveFilterList.vue";

window.$ = jquery;
window.moment = moment;

const columns = [
  "id",
  "amount",
  "paymentReference",
  "destinationType",
  "createdAt",
  "status",
  "links",
];

export default {
  name: "PayoutsList",
  components: {
    DateRangePicker,
    ActiveFilterList,
  },
  computed: {
    submerchant: function () {
      return this.$store.state.submerchant;
    },
    submerchantUser: function () {
      return this.$store.state.submerchantUser;
    },
    showSubmerchantPayouts: function () {
      const hideForViewersAndManagers = [
        "sub_qj0nkhn9axr4", // Radar Taxis
        "sub_doqgnkhq97ql5", // Fleet Cars
        "sub_2p4xpbmkumg4bw2", // Value Cars Basildon
        "sub_125ikk6w45mp3", // Central Taxis (North East) Ltd
        "sub_ef6608mkupcy76l", // Gatwick Cars
        "sub_4g35qygml8k1mdiu", // Bens Private Hire
        // "sub_177f4vkleir6uan", // Aqua Data Services - driver collections
        "sub_5vqszwimld387nf2", // Aqua Data Services - customer payments
        "sub_11d2lls8x0b6w", // Veezu Ltd Passengers -customer payments (successor to sub_5vqszwimld387nf2)
        "sub_1c32klfuzh9g5", // A1 Taxis Rotherham
        "sub_13qnqlktsnnjp7", // Ecocabs
        "sub_enyvomkxhd8i7h", // A2B Taxis Truro
        "sub_4k35pml9o9a9ox", // Zip
      ];

      const hideForViewers = [];

      if (
        hideForViewersAndManagers.includes(this.submerchant.id) &&
        ["viewer", "manager"].includes(this.submerchantUser.submerchantRole)
      ) {
        return false;
      } else if (
        hideForViewers.includes(this.submerchant.id) &&
        this.submerchantUser.submerchantRole === "viewer"
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    updateValues(vals) {
      let filter = { name: "date", value: vals };
      let isDuplicate = this.containsObject(filter, this.activeFilters);
      if (!isDuplicate) {
        this.activeFilters.push(filter);
      }
      Event.$emit("vue-tables.payoutsTable.filter::createdAt", {
        start: moment(vals.startDate).startOf("day"),
        end: moment(vals.endDate).endOf("day"),
      });
    },
    clearFilters() {
      this.createdAtDateRange.startDate = null;
      this.createdAtDateRange.endDate = null;
      this.$refs["payoutsTable"].resetQuery();
      this.activeFilters = [];

      Event.$emit("vue-tables.payoutsTable.filter::createdAt", null);
    },
    removeFilter(filter) {
      if (filter.name === "date") {
        filter.name = "createdAt";
        Event.$emit(`vue-tables.payoutsTable.filter::${filter.name}`, "");
      } else {
        const name = filter.name;
        const filterObj = {};
        filterObj[name] = "";
        this.$refs.payoutsTable.setFilter(filterObj);
      }
    },
    containsObject(obj, list) {
      for (const i in list) {
        if (obj.name === list[i].name) {
          return true;
        }
      }
      return false;
    },
    addToActiveFilters(filter) {
      let isDuplicate = this.containsObject(filter, this.activeFilters);

      if (filter.value !== "" && !isDuplicate) {
        this.activeFilters.push(filter);
      } else if (filter.value === "") {
        // value of '' means filter has been removed using built in table filter
        const index = this.activeFilters.indexOf(filter);
        this.activeFilters.splice(index, 1);
      }
    },
    loadActiveFilters() {
      const tableData = this.$refs[this.tableName].getRequestParams();
      const activeQueries = tableData["query"];

      if (tableData["createdAt"] !== null) {
        activeQueries.date = tableData["createdAt"];
      }

      for (let query in activeQueries) {
        if (activeQueries[query] !== "") {
          const queryObj = { name: query, value: activeQueries[query] };
          this.activeFilters.push(queryObj);
        }
      }
    },
  },
  mounted() {
    this.loadActiveFilters();
  },
  data() {
    return {
      columns,
      activeFilters: [],
      tableName: "payoutsTable",
      options: {
        columnsDropdown: false,
        headings: {
          id: "Payout ID",
          amount: "Amount",
          paymentReference: "Reference",
          createdAt: "Date",
        },
        customFilters: ["createdAt"],
        sortable: _.initial(columns),
        filterable: ["status", "paymentReference"],
        listColumns: {
          isUpToDate: [
            {
              id: "true",
              text: "Up to date",
            },
            {
              id: "false",
              text: "Out of date",
            },
          ],
        },
        requestAdapter,
      },
      // daterangepicker
      createdAtDateRange: {
        startDate: null,
        endDate: null,
      },
      opens: "center",
    };
  },
};
</script>
