import Cookies from "js-cookie";

import config from "./config";

// from https://levelup.gitconnected.com/generate-unique-id-in-the-browser-without-a-library-50618cdc3cb1
function generateRandomId() {
  const uint32 = window.crypto.getRandomValues(new Uint32Array(1))[0];
  return uint32.toString(16);
}

function getDeviceId() {
  const cookieName = "device_id";
  const lsKey = `${config.namespace}__device_id`;
  const storedDeviceId = window.localStorage.getItem(lsKey);
  let result;
  if (Cookies.get(cookieName)) {
    result = Cookies.get(cookieName);
  } else if (storedDeviceId) {
    // this is for backwards compatibility, so logged in users don't have their sessions broken
    result = storedDeviceId;
    Cookies.set(cookieName, result, { domain: config.cookieDomain });
  } else {
    result = generateRandomId();
    Cookies.set(cookieName, result, { domain: config.cookieDomain });
  }
  window.localStorage.setItem(lsKey, result);
  return result;
}

export default getDeviceId;
