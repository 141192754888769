<template>
  <span>
    <span v-if="loading">
      <i class="fa fa-spinner fa-spin"></i>&nbsp; Please wait&hellip;
    </span>
    <span v-else-if="error"> Error: {{ error }} </span>

    <span v-if="user">
      <img
        :src="user.avatarUrl"
        class="img img-circle"
        width="30"
        height="30"
      />&nbsp;
      <span>
        {{ user.displayName }}
      </span>
    </span>
  </span>
</template>
<script>
import { Tooltip } from "element-ui";

export default {
  name: "UserFragment",
  components: {
    [Tooltip.name]: Tooltip,
  },
  props: {
    userId: {
      type: String,
      required: true,
    },
    linkClasses: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      user: null,
      error: null,
      loading: false,
    };
  },
  computed: {
    submerchant: function () {
      return this.$store.state.submerchant;
    },
  },
  methods: {
    loadUser() {
      this.loading = true;
      this.axios
        .get(`/v1/submerchants/${this.submerchant.id}/users/${this.userId}`)
        .then((response) => {
          this.loading = false;
          if (response.status !== 200) {
            this.error = response.statusText;
            return;
          }

          this.user = response.data;
        })
        .catch((error) => {
          this.loading = false;
          // Request failed.
          this.error = error.response.statusText;
        });
    },
  },
  mounted() {
    if (this.userId) {
      this.loadUser();
    }
  },
};
</script>

<style></style>
