<template>
  <form>
    <div class="form-group">
      <label>Endpoint Name</label>
      <fg-input
        type="text"
        name="name"
        placeholder="e.g. My API endpoint"
        v-validate="{ required: false }"
        :error="getError('name')"
        v-model="name"
      >
      </fg-input>
    </div>

    <div class="form-group">
      <label>Endpoint URL</label>
      <fg-input
        type="text"
        name="url"
        placeholder="e.g. https://api.example.com/webhook"
        v-validate="{ required: true }"
        :error="getError('url')"
        :required="true"
        v-model="url"
      >
      </fg-input>
    </div>

    <label>Trigger Events</label>
    <p-checkbox
      v-for="event in events"
      :key="event.key"
      v-model="event.selected"
      >{{ event.label }}</p-checkbox
    >

    <div v-if="errorMessage" class="alert alert-warning" role="warning">
      <strong>Error: </strong>{{ errorMessage }}
    </div>

    <div class="text-right">
      <p-button type="info" @click.prevent="create" :disabled="working">
        <span v-if="!working"
          >Create endpoint &nbsp;<i class="fa fa-arrow-right"></i
        ></span>
        <span v-else
          ><i class="fa fa-spin fa-spinner"></i>&nbsp; wait &hellip;</span
        >
      </p-button>
    </div>
  </form>
</template>

<script>
import _ from "lodash";

export default {
  data() {
    return {
      name: null,
      url: null,
      events: [
        {
          selected: true,
          key: "sale.created",
          label: "Sale created",
        },
        {
          selected: false,
          key: "intent.authorized",
          label: "Intent authorized",
        },
      ],
      working: false,
      errorMessage: null,
    };
  },
  computed: {
    submerchant: function () {
      return this.$store.state.submerchant;
    },
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    create: function () {
      const instance = this;
      instance.working = true;

      const events = [];
      _.each(instance.events, (item) => {
        if (item.selected) {
          events.push(item.key);
        }
      });

      const requestBody = {
        submerchantId: instance.submerchant.id,
        name: instance.name,
        url: instance.url,
        events,
        isEnabled: true,
      };

      instance.axios
        .post(`/v1/webhook-endpoints`, requestBody)
        .then(function (response) {
          instance.$emit("created", response.data);
          instance.working = false;

          instance.$swal({
            icon: "success",
            title: "Endpoint created",
            text: `Signing secret: ${response.data.signingSecret}`,
            footer:
              "Copy the signing secret and store it securely. This will never be displayed again.",
          });
        })
        .catch(function (err) {
          instance.working = false;
          instance.errorMessage = `${err.response.data.message}. `;
          _.each(err.response.data.errors, (error, key) => {
            instance.errorMessage += _.values(error).join(", and");
          });
        });
    },
  },
};
</script>
