<template>
  <FinaroSelfEnrollment
    v-if="submerchant && !submerchant.finaroId"
  ></FinaroSelfEnrollment>
  <div v-else class="row">
    <div class="col-sm-12">
      <div class="card">
        <h2 class="card-header">Bulk create payment links</h2>

        <div
          class="card-body"
          v-if="submerchantUser && submerchantUser.submerchantRole !== 'viewer'"
        >
          <div class="alert alert-warning" v-if="error">Error: {{ error }}</div>

          <form v-if="!csvFile">
            <div class="form-group">
              <label for="csvFile">Import CSV File</label>
              <input
                type="file"
                class="form-control-file"
                id="csvFile"
                name="csvFile"
                @change="readcsvFile"
                tabindex="-1"
              />
              <p class="help mt-2">
                Please use
                <a href="/static/files/bulk-payment-link-template.csv"
                  >this template</a
                >
                and follow required format
              </p>
            </div>
          </form>

          <div v-if="csvFile && !results.length" class="row">
            <div class="col-sm-8">
              Please check the table below before submitting.<br />
              <strong>Imported {{ rows.length }} rows.</strong>
            </div>
            <div class="col-sm-4 text-right">
              <button @click="reset" class="btn btn-sm">
                Start again <i class="fa fa-undo ml-2"></i>
              </button>
            </div>
          </div>
          <div class="table-responsive">
            <table
              v-if="rows.length && !results.length"
              class="table table-striped my-4"
            >
              <thead>
                <tr>
                  <th>Reference</th>
                  <th>Amount</th>
                  <th>Customer Name</th>
                  <th>Description</th>
                  <th>Send SMS?</th>
                  <th>Phone Number</th>
                  <th>Send Email?</th>
                  <th>Email Address</th>
                  <th>Postcode</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, index) in rows" :key="index">
                  <td>{{ row.reference }}</td>
                  <td>{{ row.amount | money("gbp") }}</td>
                  <td>{{ row.customerName }}</td>
                  <td>{{ row.description }}</td>
                  <td>
                    <i v-if="row.sendCustomerSms" class="fa fa-check"></i>
                    <i v-else class="fa fa-times"></i>
                  </td>
                  <td>{{ row.customerPhoneNumber }}</td>
                  <td>
                    <i v-if="row.sendCustomerEmail" class="fa fa-check"></i>
                    <i v-else class="fa fa-times"></i>
                  </td>
                  <td>{{ row.customerEmailAddress }}</td>
                  <td>{{ row.customerPostcode }}</td>
                  <td>
                    <button
                      class="btn btn-default btn-xs"
                      @click="rows.splice(index, 1)"
                      title="Remove row"
                    >
                      <i class="fa fa-times"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div v-if="results.length" class="row">
            <div class="col-sm-8">
              <strong
                >Processed {{ results.length }} /
                {{ rows.length }} rows.</strong
              >
            </div>
            <div class="col-sm-4 text-right">
              <button
                class="btn btn-info btn-sm"
                v-if="results.length"
                @click="exportResults"
                :disabled="exporting"
              >
                <span v-if="!exporting"
                  >Export results <i class="fa fa-download ml-2"></i
                ></span>
                <span v-else
                  ><i class="fa fa-spin fa-spinner mr-2"></i> Please
                  wait&hellip;</span
                >
              </button>

              <button @click="reset" class="btn btn-warning btn-sm">
                Start again <i class="fa fa-undo ml-2"></i>
              </button>
            </div>
          </div>
          <table v-if="results.length" class="table table-striped my-4">
            <thead>
              <tr>
                <th>Reference</th>
                <th>Success</th>
                <th>Error</th>
                <th>Payment Link ID</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, index) in results" :key="index">
                <td>{{ row.reference }}</td>
                <td>
                  <i v-if="row.success" class="fa fa-check"></i>
                  <i v-else class="fa fa-exclamation-trianble"></i>
                </td>
                <td>{{ row.error }}</td>
                <td>
                  <span v-if="row.success">
                    <router-link :to="`/payment-links/${row.intent.id}`"
                      ><code>{{ row.intent.id }}</code></router-link
                    >
                  </span>
                  <span v-else>
                    <span v-if="row.error">{{ row.error }}</span>
                    <span v-else>Failed, unknown error</span>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="card-body" v-else>
          <p class="text-warning">
            Sorry, you do not have sufficient permissions to create payment
            links for this account.
          </p>
        </div>

        <div class="card-footer">
          <div class="row">
            <div class="col">
              <button v-on:click="$router.back()" class="btn btn-default">
                <i class="fa fa-arrow-left"></i>&nbsp; Go back
              </button>
            </div>
            <div class="col text-right">
              <button
                @click="createIntents"
                class="btn btn-success"
                v-if="!results.length || working"
                :disabled="!rows.length || working"
              >
                <span v-if="!working">
                  Create {{ rows.length }}
                  {{ rows.length | pluralize("payment link") }}
                  <i class="fa fa-arrow-right ml-2"></i>
                </span>
                <span v-if="working">
                  <i class="fa fa-spin fa-spinner mr-2"></i> Please wait&hellip;
                </span>
              </button>

              <router-link
                class="btn btn-info"
                to="/payment-links"
                v-if="results.length && !working"
              >
                View all payment links <i class="fa fa-arrow-right ml-2"></i>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import camelCase from "camelcase";
import parsePhoneNumber from "libphonenumber-js";
import { eachLimit } from "async-es";
import swal from "sweetalert2";
import FinaroSelfEnrollment from "./FinaroSelfEnrollment.vue";

export default {
  name: "IntentCreatePage",
  components: {
    FinaroSelfEnrollment,
  },
  data() {
    return {
      working: false,
      exporting: false,
      error: null,
      csvFile: null,
      rows: [],
      results: [],
      bulkCreationIdentifier: null, // used for idempotency
    };
  },
  computed: {
    submerchant: function () {
      return this.$store.state.submerchant;
    },
    submerchantUser: function () {
      return this.$store.state.submerchantUser;
    },
  },
  beforeMount() {
    this.maybeReloadSubmerchant();
  },
  methods: {
    maybeReloadSubmerchant() {
      if (!this.$store.state.submerchant.finaroId) {
        this.axios
          .get(`/v1/submerchants/${this.$store.state.submerchant.id}`, {
            headers: {
              "x-apicache-bypass": "true", // bypass API cache to get latest
            },
          })
          .then((response) => {
            const submerchant = response.data;
            this.$store.commit("SET_SUBMERCHANT", submerchant);
          })
          .catch((error) => {
            console.error("Error loading submerchant", error.message);
          });
      }
    },
    reset: function () {
      this.rows = [];
      this.results = [];
      this.csvFile = null;
      this.error = null;
      this.working = false;
      this.exporting = false;
      this.bulkCreationIdentifier = null;
    },
    exportResults: function () {
      const instance = this;
      // completion without error
      instance.exporting = false;

      // manipulate data (select and format rows)
      const formatted = instance.results.map((result) => {
        if (!result.success) {
          return [false, result.error];
        } else {
          return [
            true,
            null,
            result.intent.id,
            result.intent.amount / 100,
            result.intent.currency.toUpperCase(),
            result.intent.clientAccessUrl,
            result.intent.customerSmsSent,
            result.intent.customerEmailSent,
            result.reference,
            result.customerName,
            result.customerPostcode,
          ];
        }
      });
      // write to CSV
      const csv = instance.$papa.unparse(
        {
          fields: [
            "Succeeded",
            "Error reason",
            "ID",
            "Amount",
            "Currency",
            "Access URL",
            "SMS Sent",
            "Email Sent",
            "Reference",
            "Customer Name",
            "Customer Postcode",
          ],
          data: formatted,
        },
        {
          delimiter: ",",
          quotes: [
            false, // succeeded
            true, // error
            false, // ID
            false, // amount
            false, // currency
            true, // access URL
            false, // SMS sent
            false, // email sent
            true, // reference
            true, // customer name
            true, // customer postcode
          ],
        }
      );
      // Trigger "download"
      instance.$papa.download(csv, "Payment Links Export");

      // Show swal alert
      swal({
        icon: "success",
        title: "Export complete",
        text: `Your file download has started automatically.`,
      });
      instance.exporting = false;
    },
    readcsvFile: function (event) {
      const instance = this;
      instance.$papa.parse(event.target.files[0], {
        header: true,
        skipEmptyLines: true,
        transformHeader: function (header, index) {
          return camelCase(header); // e.g. convert "Customer Name" to customerName
        },
        error: function (err, file) {
          console.error("error handling CSV: ", err);
          instance.error = err;
        },
        transform: function (value, column) {
          let formatted = value;
          const truthyValues = ["TRUE", "true", true, 1, "1", "yes", "YES"];
          switch (column) {
            case "amount":
              formatted = Math.round(value * 100); // pence to pounds, rounded
              break;
            case "sendCustomerSms":
              if (truthyValues.includes(value)) {
                formatted = true;
              } else {
                formatted = false;
              }
              break;
            case "sendCustomerEmail":
              if (truthyValues.includes(value)) {
                formatted = true;
              } else {
                formatted = false;
              }
              break;
            case "customerPhoneNumber":
              const parsedPhoneNumber = parsePhoneNumber(value, "GB");
              if (parsedPhoneNumber) {
                if (parsedPhoneNumber.isValid()) {
                  formatted = parsedPhoneNumber.number;
                } else {
                  formatted = null;
                }
              } else {
                formatted = null;
              }
              break;
            case "customerPostcode":
              if (value) {
                formatted = value.toUpperCase();
              } else {
                formatted = null;
              }
              break;
            default:
            // nothing
          }
          return formatted;
        },
        complete: function (result, file) {
          instance.csvFile = file;
          instance.rows = result.data;
          instance.bulkCreationIdentifier = Math.random()
            .toString(36)
            .substr(2, 9);
        },
      });
    },
    createIntents: function () {
      const instance = this;
      instance.working = true;
      const PARALLEL_LIMIT = 1; // this will make the process slower, but ensures the order of the payment links is correct in export file

      let rowNumber = 0;
      eachLimit(
        instance.rows,
        PARALLEL_LIMIT,
        (row, next) => {
          rowNumber++;
          const result = row;
          result.error = null;
          result.success = false;
          result.intent = null;

          row.submerchantId = instance.submerchant.id;
          row.brandIdentifier = row.submerchantId;

          instance
            .createIntent(row, rowNumber)
            .then((created) => {
              result.intent = created;
              result.success = true;
              instance.results.push(result);
              return next(null);
            })
            .catch((err) => {
              result.error = err.message;
              result.success = false;
              instance.results.push(result);
              return next(null); // do not stop execution
            });
        },
        (err) => {
          instance.working = false;
          if (err) {
            console.error("Error: ", err);
            instance.error = err.message;
          } else {
            instance.$notify({
              message: "Processing completed",
              timeout: 3000,
              icon: "fa fa-check",
              horizontalAlign: "right",
              verticalAlign: "top",
              type: "success",
            });
          }
        }
      );
    },
    createIntent: function (row, rowNumber) {
      return new Promise((resolve, reject) => {
        // some validation
        if (!row.amount) {
          const error = new Error("Amount is required");
          return reject(error);
        }

        if (row.sendCustomerSms && !row.customerPhoneNumber) {
          const error = new Error(
            "Customer phone number is required to send SMS"
          );
          return reject(error);
        }

        if (row.sendCustomerEmail && !row.customerEmailAddress) {
          const error = new Error(
            "Customer email address is required to send email"
          );
          return reject(error);
        }

        const requestBody = {
          saleAmount: row.amount,
          currency: "gbp",
          submerchantId: row.submerchantId,
          description: row.description,
          reference: row.reference,
          customerName: row.customerName,
          brandIdentifier: row.brandIdentifier,
        };

        if (row.customerPhoneNumber && row.sendCustomerSms) {
          requestBody.customerPhoneNumber = row.customerPhoneNumber;
          requestBody.customerPhoneNumber = requestBody.customerPhoneNumber
            .split("-")
            .join(""); // remove dashes
          requestBody.customerPhoneNumber = requestBody.customerPhoneNumber
            .split(" ")
            .join(""); // remove spaces
          requestBody.sendCustomerSms = true;
        }

        if (row.customerEmailAddress && row.sendCustomerEmail) {
          requestBody.customerEmailAddress = row.customerEmailAddress;
          requestBody.sendCustomerEmail = true;
        }

        if (row.customerPostcode) {
          if (!requestBody.customerAddress) {
            requestBody.customerAddress = {};
          }
          requestBody.customerAddress.postcode = row.customerPostcode;
        }

        // Generate an Idempotency-Key for the request
        const idempotencyKey = `${this.bulkCreationIdentifier}-${rowNumber}`;
        this.axios
          .post("/v1/intents", requestBody, {
            headers: {
              "Idempotency-Key": idempotencyKey, // set Idempotency-Key header
            },
          })
          .then((response) => {
            const intent = response.data;
            return resolve(intent);
          })
          .catch((error) => {
            if (error.response) {
              const err = new Error(error.response.data.message);
              return reject(err);
            } else {
              return reject(error);
            }
          });
      });
    },
  },
};
</script>
