<template>
  <div>
    <div v-if="terminal">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <h2 class="card-header">Terminal details</h2>

            <div class="card-body" v-if="showSubmerchantTerminals">
              <h5 class="card-title">{{ terminal.name }}</h5>
              <h6 class="card-subtitle mb-4 text-muted">
                Terminal ID:
                <code class="text-transform-none">{{ terminal.id }}</code>
              </h6>

              <div class="row">
                <div class="col-md-6">
                  <table class="table">
                    <tbody>
                      <tr>
                        <th>Serial number</th>
                        <td>
                          <code>{{ terminal.serialNumber }}</code>
                        </td>
                      </tr>
                      <tr>
                        <th>Allocated at</th>
                        <td>
                          {{
                            terminal.currentAllocation.createdAt
                              | moment("YYYY-MM-DD HH:mm A")
                          }}
                        </td>
                      </tr>
                      <tr>
                        <th>Software status</th>
                        <td>
                          <span
                            v-if="
                              terminal.reportedConfiguration &&
                              terminal.reportedConfiguration.isUpToDate
                            "
                            class="text-success"
                          >
                            <i class="fa fa-check"></i>&nbsp; Up to date
                          </span>
                          <span
                            v-else-if="
                              terminal.reportedConfiguration &&
                              !terminal.reportedConfiguration.isUpToDate
                            "
                            class="text-danger"
                          >
                            <i class="fa fa-exclamation-triangle"></i>&nbsp; Out
                            of date
                          </span>
                          <span v-else class="text-warning">
                            <i class="fa fa-question-circle"></i>&nbsp; Status
                            unknown
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <th>Quick Start Guide</th>
                        <td>
                          <a
                            v-if="
                              terminal.model === 'bluePad55' ||
                              terminal.model === 'bluePad55_4g'
                            "
                            href="https://cabcard.services/downloads/cabcard-pocket-quick-start-guide.pdf"
                            target="_blank"
                          >
                            <i class="fa fa-file-pdf-o"></i>&nbsp; Download PDF
                          </a>

                          <a
                            v-else-if="
                              terminal.model === 'bluePad5000' ||
                              terminal.model === 'bluePad5000_v2'
                            "
                            href="https://cabcard.services/downloads/cabcard-handheld-quick-start-guide.pdf"
                            target="_blank"
                          >
                            <i class="fa fa-file-pdf-o"></i>&nbsp; Download PDF
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="col-md-6">
                  <img
                    class="img img-responsive mx-auto d-block mb-5"
                    :src="`/static/img/terminals/${terminalImageFileName}`"
                    style="max-height: 250px"
                  />
                </div>
              </div>
            </div>

            <div class="card-body" v-else>Not permitted to view this data.</div>

            <div class="card-footer">
              <button v-on:click="$router.back()" class="btn btn-default">
                <i class="fa fa-arrow-left"></i>&nbsp; Go back
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <terminal-sales-list :terminal-id="terminal.id"></terminal-sales-list>
        </div>
      </div>
    </div>

    <div v-else>
      <div v-if="loading" class="spinner-container">
        <double-bounce size="60px"></double-bounce>
      </div>
    </div>
  </div>
</template>

<script>
import { DoubleBounce } from "vue-loading-spinner";
import TerminalSalesList from "./TerminalSalesList";

export default {
  name: "SalePage",
  components: {
    DoubleBounce,
    TerminalSalesList,
  },
  data() {
    return {
      error: null,
      loading: null,
      terminal: null,
    };
  },
  computed: {
    terminalImageFileName() {
      if (!this.terminal) {
        return "default.png";
      }

      if (this.terminal && this.terminal.model.includes("55")) {
        return "bluePad55-trans.png";
      } else if (this.terminal && this.terminal.model.includes("5000")) {
        return "bluePad5000-trans.png";
      }
    },
    submerchant: function () {
      return this.$store.state.submerchant;
    },
    submerchantUser: function () {
      return this.$store.state.submerchantUser;
    },
    showSubmerchantTerminals: function () {
      const hideForViewersAndManagers = [
        "sub_doqgnkhq97ql5", // Fleet Cars
        "sub_13qnqlktsnnjp7", // Ecocabs
        "sub_enyvomkxhd8i7h", // A2B Taxis Truro
        "sub_4k35pml9o9a9ox", // Zip
      ];

      const hideForViewers = [];

      if (
        _.includes(hideForViewersAndManagers, this.submerchant.id) &&
        ["viewer", "manager"].includes(this.submerchantUser.submerchantRole)
      ) {
        return false;
      } else if (
        hideForViewers.includes(this.submerchant.id) &&
        this.submerchantUser.submerchantRole === "viewer"
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  props: ["terminalId"],
  beforeMount() {
    this.loading = true;
    this.axios
      .get(`/v1/terminals/${this.terminalId}`)
      .then((response) => {
        this.loading = false;
        this.terminal = response.data;
      })
      .catch((error) => {
        this.loading = false;
        this.error = "Failed to load terminal";
      });
  },
};
</script>
