<template>
  <div class="register-page">
    <app-navbar></app-navbar>
    <div class="wrapper wrapper-full-page">
      <div class="full-page submerchant-select-page" filter-color="black">
        <div class="content">
          <div class="container">
            <div class="row">
              <div class="col-md-6 ml-auto mr-auto mb-5">
                <!-- submerchant selection -->
                <card type="lock" :footer-line="true">
                  <div class="panel-body">
                    <div v-if="loading" class="spinner-container">
                      <double-bounce size="60px"></double-bounce>
                    </div>

                    <div
                      v-if="loadingError"
                      class="alert alert-danger"
                      role="alert">
                      {{ loadingError }}
                    </div>

                    <div v-if="!loading && submerchants.length > 0">
                      <h2>Select an account:</h2>
                      <div class="list-group">
                        <button
                          type="button"
                          @click="selectSubmerchant(submerchant)"
                          v-for="submerchant in submerchants"
                          v-bind:key="submerchant.id"
                          class="list-group-item list-group-item-action clickable">
                          {{ submerchant.name }}
                        </button>
                      </div>
                      <div class="spacer"></div>
                      <div slot="footer" class="text-muted small">
                        <p>
                          Need help? Please
                          <a href="mailto:support@cabcard.services">contact support</a>.
                        </p>
                        <div class="text-right">
                          <button
                            class="btn btn-xs btn-link"
                            v-on:click="logout">
                            <i class="fa fa-sign-out"></i>&nbsp; Log out
                          </button>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <p class="lead">
                        There are no accounts associated with your user
                        information.
                      </p>
                      <p>
                        If you need help, please
                        <a href="mailto:support@cabcard.services">contact support</a>.
                      </p>
                      <div class="text-right">
                        <button class="btn btn-xs btn-link" v-on:click="logout">
                          <i class="fa fa-sign-out"></i>&nbsp; Log out
                        </button>
                      </div>
                    </div>
                  </div>
                </card>
              </div>
            </div>
          </div>
        </div>
        <!-- <app-footer></app-footer> -->
        <div
          class="full-page-background"
          style="background-image: url('/static/img/background/yellow-bg.svg')"></div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import { DoubleBounce } from "vue-loading-spinner";

import AppNavbar from "./Layout/AppNavbar";
import AppFooter from "./Layout/AppFooter";
import {
  Card,
  Checkbox,
  Button,
  InfoSection,
} from "src/components/UIComponents";

export default {
  components: {
    Card,
    AppNavbar,
    DoubleBounce,
    AppFooter,
    InfoSection,
    [Checkbox.name]: Checkbox,
    [Button.name]: Button,
  },
  data() {
    return {
      form: {
        name: "",
      },
      formValidators: {
        name: {
          required: true,
          min: 3,
        },
      },
      validationErrors: {},
      errorMessage: null,
      creating: false,
      loading: false,
      submerchants: [],
      loadingError: false,
    };
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    },
    toggleNavbar() {
      document.body.classList.toggle("nav-open");
    },
    closeMenu() {
      document.body.classList.remove("nav-open");
      document.body.classList.remove("off-canvas-sidebar");
    },
    selectSubmerchant(submerchant) {
      // set to local storage
      this.$store.commit("SET_SUBMERCHANT", submerchant);

      // load operator user
      this.axios
        .get(`/v1/submerchants/${submerchant.id}/users/current`)
        .then((response) => {
          this.$store.commit("SET_SUBMERCHANT_USER", response.data);

          // go to submerchant dashboard
          this.$router.push("/dashboard");
        })
        .catch((error) => {
          this.loadingError = "Failed to load submerchant user";
        });
    },
    fieldError(fieldName) {
      // clientside
      if (this.errors.first(fieldName)) {
        return this.errors.first(fieldName);
      }

      // serverside
      const fieldError = _.find(this.validationErrors, (item) => {
        return _.includes(item.field, fieldName);
      });

      if (!fieldError) {
        return false;
      } else if (fieldError.messages) {
        return fieldError.messages;
      } else {
        return true;
      }
    },
  },
  beforeMount() {
    this.loading = true;
    this.axios
      .get("/v1/submerchants?sort=name", {
        headers: {
          "x-apicache-bypass": "true", // bypass API cache to get accessible submerchants
        },
      })
      .then((response) => {
        this.loading = false;
        this.submerchants = response.data;
      })
      .catch((error) => {
        this.loading = false;
        this.loadingError = "Failed to load submerchants";
      });
  },
  beforeDestroy() {
    this.closeMenu();
  },
};
</script>
<style></style>
