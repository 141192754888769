<template>
  <div class="user">
    <avatar
      v-if="user && user.displayName"
      class="photo"
      :size="34"
      :username="user.displayName"
      :src="user.avatarUrl"
    ></avatar>
    <div class="info" v-if="user">
      <a
        data-toggle="collapse"
        :aria-expanded="!isClosed"
        @click="toggleMenu"
        href="#"
      >
        <span>
          {{ user.displayName | truncate(15, "&hellip;") }}
          <b class="caret"></b>
        </span>
      </a>
      <div class="clearfix"></div>
      <div>
        <collapse-transition>
          <ul class="nav nav-menu" v-show="!isClosed">
            <li>
              <router-link to="/user/edit">
                <span class="sidebar-mini-icon">
                  <i class="fa fa-user"></i>
                </span>
                <span class="sidebar-normal">Edit Profile</span>
              </router-link>
            </li>
            <li>
              <router-link to="/select-account">
                <span class="sidebar-mini-icon">
                  <i class="fa fa-random"></i>
                </span>
                <span class="sidebar-normal">Switch account</span>
              </router-link>
            </li>
            <li>
              <a href="#" @click="logout">
                <span class="sidebar-mini-icon">
                  <i class="fa fa-sign-out"></i>
                </span>
                <span class="sidebar-normal">Logout</span>
              </a>
            </li>
          </ul>
        </collapse-transition>
      </div>
    </div>
  </div>
</template>
<script>
import { CollapseTransition } from "vue2-transitions";
import Avatar from "vue-avatar";

export default {
  components: {
    CollapseTransition,
    Avatar,
  },
  data() {
    return {
      isClosed: true,
    };
  },
  computed: {
    user: function () {
      return this.$store.state.user;
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    },
    toggleMenu() {
      this.isClosed = !this.isClosed;
    },
  },
  filters: {
    truncate: (value, maxLength, appendString) => {
      if (!value || value.length < 1) {
        return "";
      } else if (value.length > maxLength) {
        return `${value.substr(0, maxLength)}${appendString}`;
      } else {
        return value;
      }
    },
  },
};
</script>
<style scoped>
.nav.nav-menu {
  margin-top: 0;
}
</style>
