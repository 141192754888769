<template>
  <div class="row">
    <div v-if="sale" class="col-md-12">
      <div class="card">
        <h2 class="card-header">Sale details</h2>
        <div class="card-body">
          <h6 class="card-subtitle mb-4 text-muted">
            Sale ID:
            <code class="text-transform-none">{{ sale.id }}</code>
          </h6>

          <div class="row">
            <div class="col-md-6">
              <table class="table">
                <tbody>
                  <tr>
                    <th>Transaction amount</th>
                    <td>
                      {{ sale.amount | money(sale.currency) }}
                      <span v-if="sale.gratuityAmount" class="text-muted"
                        >(fare {{ sale.saleAmount | money(sale.currency) }} +
                        tip
                        {{ sale.gratuityAmount | money(sale.currency) }})</span
                      >
                    </td>
                  </tr>
                  <tr v-if="balanceTransaction">
                    <th>Processing fee</th>
                    <td>
                      {{
                        balanceTransaction.feeAmount
                          | money(balanceTransaction.currency)
                      }}
                    </td>
                  </tr>
                  <tr v-if="balanceTransaction">
                    <th>Net amount</th>
                    <td>
                      {{
                        balanceTransaction.netAmount
                          | money(balanceTransaction.currency)
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th>PAN</th>
                    <td>
                      <span class="small text-muted">
                        <i class="fa fa-circle"></i>
                        <i class="fa fa-circle"></i>
                        <i class="fa fa-circle"></i>
                        <i class="fa fa-circle"></i>
                        &nbsp;
                        <i class="fa fa-circle"></i>
                        <i class="fa fa-circle"></i>
                        <i class="fa fa-circle"></i>
                        <i class="fa fa-circle"></i>
                        &nbsp;
                        <i class="fa fa-circle"></i>
                        <i class="fa fa-circle"></i>
                        <i class="fa fa-circle"></i>
                        <i class="fa fa-circle"></i>
                        &nbsp;
                      </span>
                      {{ sale.panLastFour }}
                    </td>
                  </tr>
                  <tr>
                    <th>Auth code</th>
                    <td>{{ sale.authCode }}</td>
                  </tr>
                  <tr>
                    <th>Card scheme</th>
                    <td>{{ sale.cardScheme | humanize }}</td>
                  </tr>
                  <tr>
                    <th>Card details</th>
                    <td>
                      <span v-if="sale.cardIsCommercial">Commercial</span>
                      <span v-else>Consumer</span>

                      <span v-if="sale.cardType"
                        >&nbsp;{{ sale.cardType }}</span
                      >
                      card
                      <span v-if="sale.cardIsPrepaid">(prepaid)</span>
                    </td>
                  </tr>
                  <tr>
                    <th>Card issuing country</th>
                    <td>
                      <span v-if="sale.cardCountryCode">
                        {{ sale.cardCountryCode }}
                        <flag :iso="sale.cardCountryCode.toLowerCase()" />
                      </span>
                      <span v-else>Unknown</span>

                      <span v-if="sale.cardCountryIsInEEA === true"
                        >(European card)</span
                      >
                      <span v-else-if="sale.cardCountryIsInEEA === false"
                        >(Non-European card)</span
                      >
                    </td>
                  </tr>
                  <tr>
                    <th>Transaction date</th>
                    <td>
                      {{ sale.capturedAt | moment("YYYY-MM-DD HH:mm A") }}
                    </td>
                  </tr>
                  <tr v-if="sale.retrievalReferenceNumber">
                    <th>RRN</th>
                    <td>
                      <code>{{ sale.retrievalReferenceNumber }}</code>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="col-md-6">
              <table class="table">
                <tbody>
                  <tr>
                    <th>Settlement status</th>
                    <td>
                      <span
                        v-if="sale.settlementTransactionId"
                        class="text-success"
                      >
                        <i class="fa fa-check"></i>&nbsp; Settled
                      </span>
                      <span v-else class="text-info">
                        <i class="fa fa-clock-o"></i>&nbsp; Awaiting bank
                        settlement
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <th>Payout status</th>
                    <td>
                      <span
                        v-if="sale.disbursementWithheld"
                        class="text-danger"
                      >
                        <i class="fa fa-exclamation-triangle"></i>&nbsp;
                        Withheld from payout
                      </span>
                      <span v-else class="text-success">
                        <i class="fa fa-check"></i>&nbsp; OK
                      </span>
                    </td>
                  </tr>
                  <tr v-if="sale.terminalId && sale.terminalSerialNumber">
                    <th>Terminal</th>
                    <td>
                      <router-link
                        v-if="sale.terminalId"
                        :to="`/terminals/${sale.terminalId}`"
                        class="btn btn-default"
                      >
                        <code>S/N {{ sale.terminalSerialNumber }}</code>
                      </router-link>
                      <span v-else>N/A</span>
                    </td>
                  </tr>

                  <tr v-if="sale.receiptId">
                    <th>Receipt</th>
                    <td>
                      <button
                        class="btn btn-info"
                        v-on:click.prevent="showReceiptSendForm = true"
                      >
                        Send by SMS or email
                      </button>
                      <br />
                      <br />
                      <a
                        class="btn btn-default"
                        :href="`${receiptsWebsiteHost}/receipts/${sale.receiptId}?panLastFour=${sale.panLastFour}`"
                        target="_blank"
                        >View</a
                      >
                      &nbsp;
                      <a
                        class="btn btn-default"
                        :href="`${receiptsWebsiteHost}/receipts/${sale.receiptId}?panLastFour=${sale.panLastFour}&print=true`"
                        target="_blank"
                        >Print</a
                      >
                    </td>
                  </tr>

                  <tr v-if="sale.intentId">
                    <th>Payment link ID</th>
                    <td>
                      <router-link :to="`/payment-links/${sale.intentId}`">
                        <code>{{ sale.intentId }}</code>
                      </router-link>
                    </td>
                  </tr>

                  <tr>
                    <th>External reference</th>
                    <td>
                      <code>{{ sale.externalReference }}</code>
                    </td>
                  </tr>

                  <tr>
                    <th>Refund</th>
                    <td>
                      <a
                        v-if="
                          refundRestrictedSubmerchantIds.includes(
                            submerchant.id
                          ) && this.submerchantUser.submerchantRole !== 'owner'
                        "
                        class="btn btn-sm btn-default"
                        disabled="true"
                        title="Insufficient privileges"
                        href="#"
                      >
                        Not permitted
                      </a>
                      <a
                        v-else-if="sale.amount > totalRefundAmount"
                        class="btn btn-sm btn-default"  @click="showRefundRequestForm = true"
                        href="#"
                        ><i class="fa fa-undo mr-2"></i>Request refund</a
                      >
                    </td>
                  </tr>

                  <tr v-for="refund in refunds">
                    <th>
                      <a
                        href="#"
                        title="Show refund details"
                        @click="showRefundDetails(refund)"
                      >
                        {{ refund.amount | money(sale.currency) }} refund
                      </a>
                    </th>
                    <td>
                      <span v-if="refund.status === 'pending' || refund.status === 'approved'" class="text-warning">
                        <i class="fa fa-clock-o"></i>&nbsp; Pending
                      </span>
                      <span v-else-if="refund.status === 'rejected'" class="text-danger">
                        <i class="fa fa-times"></i>&nbsp; {{ refund.status | humanize }}
                      </span>
                      <span v-else-if="refund.status === 'completed'" class="text-success">
                        <i class="fa fa-check"></i>&nbsp; {{ refund.status | humanize }}
                      </span>
                      <span v-else>
                        {{ refund.status | humanize }}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="card-footer">
          <button v-on:click="$router.back()" class="btn btn-default">
            <i class="fa fa-arrow-left"></i>&nbsp; Go back
          </button>
        </div>
      </div>
    </div>
    <div v-else>
      <div v-if="loading" class="spinner-container">
        <double-bounce size="60px"></double-bounce>
      </div>
    </div>

    <modal
      :show.sync="showRefundModal"
      class="modal-primary"
      :show-close="true"
      headerClasses="justify-content-center"
      type="mini"
    >
      <div slot="header">
        <h4>Refund details</h4>
        <h6 class="mb-4 text-muted">
          Refund request ID:
          <code v-if="selectedRefund" class="text-transform-none">{{ selectedRefund.id }}</code>
        </h6>
      </div>

      <sale-refund
        :saleId="this.saleId"
        :refund="selectedRefund"
        @refund-request-updated="loadResources"
      >
      </sale-refund>

      <template slot="footer">
        <p-button type="default" link @click="showRefundModal = false">
          <i class="fa fa-times"></i>
          Close
        </p-button>
      </template>
    </modal>

    <modal
      :show.sync="showRefundRequestForm"
      class="modal-primary"
      :show-close="true"
      headerClasses="justify-content-center"
    >
      <div slot="header" class="modal-profile ml-auto mr-auto">
        <i class="fa fa-paper-plane-o"></i>
      </div>

      <div class="request-refund-form">
        <p class="lead text-center">
          Request a refund
        </p>

        <div
          class="alert alert-danger alert-dismissible fade show"
          v-if="refundError"
          role="alert"
        >
          {{ refundError }}
          <button
            type="button"
            class="close"
            data-dismiss="alert"
            aria-label="Close"
            @click="refundError = null"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="form-group">
          <label for="refundType" class="control-label">Refund type
            <span class="text-danger" title="Required">*</span>
          </label>
          <select v-model="refundType" class="form-control" required>
            <option disabled :value="null">Please select one</option>
            <option value="full">Full refund</option>
            <option v-if="sale && sale.acquirer !== 'bambora'" value="partial">Partial refund</option>
          </select>
        </div>

        <form v-if="refundType">
          <div class="form-group" v-if="refundType === 'partial'">
            <label for="partialAmount" class="control-label">Amount
              <span class="text-danger" title="Required">*</span>
            </label>
            <currency-input
              v-model="partialAmount"
              currency="GBP"
              locale="gb"
              :value-as-integer="true"
              :allow-negative="false"
              class="form-control"
              placeholder="e.g. 10.00"
              required
            />
          </div>

          <label for="refundReason" class="control-label">Refund reason
            <span class="text-danger" title="Required">*</span>
          </label>
          <textarea
            class="form-control"
            v-model="refundReason"
            placeholder=" e.g. service not provided, duplicate transaction"
            required
          />

          <br>

          <label for="customerEmailAddress" class="control-label">Email address of customer (optional)</label>
          <input
            class="form-control"
            type="email"
            v-model="customerEmailAddress"
            placeholder="e.g. example@email.com"
          />
        </form>
      </div>

      <template slot="footer">
        <div class="left-side">
          <p-button type="default" link @click="showRefundRequestForm = false">
            Cancel &nbsp;
            <i class="fa fa-times"></i>
          </p-button>
        </div>
        <div class="divider"></div>
        <div class="right-side">
          <p-button type="info" link @click="sendRefundRequest" :disabled="requestingRefund">
            <span v-if="requestingRefund">
              <i class="fa fa-spin fa-spinner"></i>
              &nbsp; Please wait&hellip;
            </span>
            <span v-else>
              Submit &nbsp;
              <i class="fa fa-check"></i>
            </span>
          </p-button>
        </div>
      </template>
    </modal>

    <modal
      :show.sync="showReceiptSendForm"
      class="modal-primary"
      :show-close="true"
      headerClasses="justify-content-center"
      type="mini"
    >
      <div slot="header" class="modal-profile ml-auto mr-auto">
        <i class="fa fa-paper-plane-o"></i>
      </div>

      <send-receipt-advice-form
        v-if="sale && sale.receiptId"
        transaction-type="sale"
        :receipt-id="sale.receiptId"
        :transaction="sale"
      ></send-receipt-advice-form>

      <template slot="footer">
        <div class="left-side">
          <p-button type="default" link @click="showReceiptSendForm = false">
            Cancel &nbsp;
            <i class="fa fa-times"></i>
          </p-button>
        </div>
        <div class="divider"></div>
        <div class="right-side">
          <p-button type="info" link @click="showReceiptSendForm = false">
            Close &nbsp;
            <i class="fa fa-check"></i>
          </p-button>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import { DoubleBounce } from "vue-loading-spinner";
import SendReceiptAdviceForm from "src/components/Dashboard/Views/Forms/SendReceiptAdviceForm.vue";
import { Button, Modal } from "src/components/UIComponents";
import SaleRefund from "src/components/Dashboard/Views/Pages/Sales/SaleRefund.vue"

export default {
  name: "SalePage",
  components: {
    DoubleBounce,
    SendReceiptAdviceForm,
    Button,
    Modal,
    SaleRefund,
  },
  data() {
    return {
      error: null,
      refundError: null,
      loading: null,
      sale: null,
      refunds: null,
      selectedRefund: null,
      balanceTransaction: null,
      refundType: null,
      partialAmount: null,
      refundReason: null,
      customerEmailAddress: null,
      requestingRefund: false,
      showRefundModal: false,
      showRefundRequestForm: false,
      showReceiptSendForm: false,
      refundRestrictedSubmerchantIds: [
        // "sub_177f4vkleir6uan", // Aqua Data Services - driver collections
        "sub_5vqszwimld387nf2", // Aqua Data Services - customer payments
        "sub_11d2lls8x0b6w", // Veezu Ltd Passengers -customer payments (successor to sub_5vqszwimld387nf2)
        "sub_doqgnkhq97ql5", // Fleet Cars
      ],
    };
  },
  props: ["saleId"],
  computed: {
    submerchant: function () {
      return this.$store.state.submerchant;
    },
    submerchantUser: function () {
      return this.$store.state.submerchantUser;
    },
    user: function () {
      return this.$store.state.user;
    },
    receiptsWebsiteHost: function () {
      let result = "https://receipts.cabcard.services";

      // production OPL PF ID
      if (this.submerchant.paymentFacilitatorId === "pfc_114jnwkjzeas1eb") {
        result = "https://receipts.optimuspay.co.uk";
      }

      // some exceptions
      if (this.submerchant.operatorId === "opr_3q12hkjyo7q4ke") {
        result = "https://torbay.receipts.taxi"; // Torbay
      } else if (this.submerchant.operatorId === "opr_125ikk6vyx0s9") {
        result = "https://avenue.receipts.taxi"; // Avenue
      } else if (this.submerchant.operatorId === "opr_4abkka3p2j3h") {
        result = "https://wheels.receipts.taxi"; // Wheels
      } else if (this.submerchant.operatorId === "opr_125ikk6wfuuow") {
        result = "https://toa.receipts.taxi"; // TOA
      }

      return result;
    },
    totalRefundAmount: function () {
      let totalRefundAmount = 0;
      if (this.refunds) {
        this.refunds.forEach((refund) => {
          totalRefundAmount += refund.amount;
        });
      }
      return totalRefundAmount;
    },
  },
  beforeMount() {
    this.loadResources();
  },
  methods: {
    loadResources() {
      this.loading = true;
      this.showRefundModal = false;
      this.axios
        .get(`/v1/sales/${this.saleId}`, {
          headers: {
            "x-apicache-bypass": "true", // bypass API cache - in case the receipt has only just loaded
          },
        })
        .then((response) => {
          this.sale = response.data;

          return this.axios.get(
            `/v1/sales/${this.saleId}/refund-requests/`
          );
        })
        .then((response) => {
          if (response && response.data) {
            this.refunds = response.data;
          }

          if (this.sale.balanceTransactionId) {
            return this.axios.get(
              `/v1/balance-transactions/${this.sale.balanceTransactionId}`
            );
          } else {
            return null;
          }
        })
        .then((response) => {
          if (response && response.data) {
            this.balanceTransaction = response.data;
          }
        })
        .catch((error) => {
          this.error = "Failed to load sale";
        })
        .finally(() => {
          this.loading = false;
        });
    },
    showRefundDetails(refund) {
      // set which refund to show and open modal
      this.selectedRefund = refund
      this.showRefundModal = true
    },
    sendRefundRequest() {
      this.requestingRefund = true;
      let body = {};

      try {
        if (this.refundType === "partial") {
          if (!this.partialAmount) {
            throw new Error("Amount required");
          }

          body.amount = this.partialAmount;
        } else if (this.refundType === "full") {
          // if already partially refunded before, set the remaining amount
          body.amount = this.sale.amount - this.totalRefundAmount;
        } else {
          throw new Error("Need to select full or partial refund");
        }

        if (!this.refundReason) {
          throw new Error("Reason for refund required");
        }

        body.refundReason = this.refundReason;
      } catch (error) {
        this.refundError = error.message;
        this.requestingRefund = false;
        return;
      }

      if (this.customerEmailAddress) {
        body.customerEmailAddress = this.customerEmailAddress;
      }

      this.axios
        .post(`/v1/sales/${this.saleId}/refund-requests/`, body)
        .then((response) => {
          if (response.status !== 201) {
            throw new Error(response.statusText);
          }

          this.showRefundRequestForm = false;

          // display a notification
          this.$notify({
            message: "Refund request submitted successfully",
            timeout: 8000,
            icon: "fa fa-paper-plane",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "success",
          });

          // clear the form
          this.refundType = null;
          this.partialAmount = null;
          this.refundReason = null;
          this.customerEmailAddress = null;
          // clear any previous error
          this.refundError = null;

          this.loadResources();
        })
        .catch((error) => {
          this.refundError = error.message;

          if (error.response && error.response.data && error.response.data.message) {
            this.refundError = error.response.data.message;
          }
        })
        .finally(() => {
          this.requestingRefund = false;
        });
    },
  }
};
</script>
