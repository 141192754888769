<template>
  <div v-if="refund">
    <table class="table">
      <tbody>
        <tr>
          <th>Status</th>
          <td>
            <span v-if="refund.status === 'pending' || refund.status === 'approved'" class="text-warning">
              <i class="fa fa-clock-o"></i>&nbsp; Pending
            </span>
            <span v-else-if="refund.status === 'rejected'" class="text-danger">
              <i class="fa fa-times"></i>&nbsp; {{ refund.status | humanize }}
            </span>
            <span v-else-if="refund.status === 'completed'" class="text-success">
              <i class="fa fa-check"></i>&nbsp; {{ refund.status | humanize }}
            </span>
            <span v-else>
              {{ refund.status | humanize }}
            </span>
          </td>
        </tr>
        <tr>
          <th>Amount</th>
          <td>
            {{ refund.amount | money(refund.sale.currency) }}
          </td>
        </tr>
        <tr>
          <th>Reason</th>
          <td>
            {{ refund.refundReason }}
          </td>
        </tr>
        <tr v-if="refund.customerEmailAddress">
          <th>Customer email address</th>
          <td>
            {{ refund.customerEmailAddress }}
          </td>
        </tr>
        <tr v-if="refund.status === 'pending'">
          <th>Edit refund</th>
          <td>
            <span class="btn btn-warning btn-sm" @click="showRefundEditForm = true"
              :disabled="refund.status !== 'pending'">
              <i class="fa fa-pencil mr-2"></i>Edit
            </span>
          </td>
        </tr>
        <tr>
          <th>Date</th>
          <td>
            {{ refund.createdAt | moment("YYYY-MM-DD HH:mm A") }}
          </td>
        </tr>
        <tr>
          <th>Requested by</th>
          <td>
            <user-fragment
              v-if="refund.createdBy.startsWith('usr_')"
              :userId="refund.createdBy"
            ></user-fragment>
          </td>
        </tr>
        <tr v-if="refund.status === 'pending'">
          <th>Delete refund</th>
          <td>
            <span class="btn btn-danger btn-sm" @click="openRefundDeletionForm(refund.id)"
              :disabled="refund.status !== 'pending'">
              <i class="fa fa-trash mr-2"></i>Delete
            </span>
          </td>
        </tr>
      </tbody>
    </table>

    <modal
      :show.sync="showRefundEditForm"
      class="modal-primary"
      :show-close="true"
      headerClasses="justify-content-center"
      type="mini"
    >
      <div slot="header" class="modal-profile ml-auto mr-auto">
        <i class="fa fa-pencil"></i>
      </div>

      <div class="request-refund-form">
        <p class="lead text-center">
          Edit this refund
        </p>

        <div
          class="alert alert-danger alert-dismissible fade show"
          v-if="refundError"
          role="alert"
        >
          {{ refundError }}
          <button
            type="button"
            class="close"
            data-dismiss="alert"
            aria-label="Close"
            @click="refundError = null"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <form v-if="localRefund" class="form-group">
          <label for="amount" class="control-label">Amount
            <span class="text-danger" title="Required">*</span>
          </label>
          <currency-input
            v-model="localRefund.amount"
            currency="GBP"
            locale="gb"
            :value-as-integer="true"
            :allow-negative="false"
            class="form-control"
            placeholder="e.g. 10.00"
            required
          />

          <br>

          <label for="refundReason" class="control-label">Refund reason
            <span class="text-danger" title="Required">*</span>
          </label>
          <textarea
            class="form-control"
            v-model="localRefund.refundReason"
            placeholder="e.g. service not provided, duplicate transaction"
            required
          />

          <br>

          <label for="customerEmailAddress" class="control-label">Email address of customer (optional)</label>
          <input
            class="form-control"
            type="email"
            v-model="localRefund.customerEmailAddress"
            placeholder="e.g. example@email.com"
          />
        </form>
      </div>

      <template slot="footer">
        <div class="left-side">
          <p-button type="default" link @click="showRefundEditForm = false">
            Cancel &nbsp;
            <i class="fa fa-times"></i>
          </p-button>
        </div>
        <div class="divider"></div>
        <div class="right-side">
          <p-button type="info" link @click="editRefundRequest" :disabled="editingRefund">
            <span v-if="editingRefund">
              <i class="fa fa-spin fa-spinner"></i>
              &nbsp; Please wait&hellip;
            </span>
            <span v-else>
              Edit &nbsp;
              <i class="fa fa-pencil"></i>
            </span>
          </p-button>
        </div>
      </template>
    </modal>
  </div>
</template>
<script>
  import _ from "lodash";
  import Swal from 'sweetalert2';
  import UserFragment from "src/components/Dashboard/Views/Pages/Users/UserFragment.vue";
  import { Modal } from "src/components/UIComponents";
  
  export default {
    name: "SaleRefund",
    components: {
      UserFragment,
      Modal,
    },
    props: ["saleId", "refund"],
    methods: {
      editRefundRequest() {
        this.editingRefund = true;
        let body = {};

        try {
          if (!this.localRefund.amount) {
            throw new Error("Amount required");
          }

          body.amount = this.localRefund.amount;
          
          if (!this.localRefund.refundReason) {
            throw new Error("Reason for refund required");
          }

          body.refundReason = this.localRefund.refundReason;
        } catch (error) {
          this.refundError = error.message;
          this.editingRefund = false;
          return;
        }

        if (this.localRefund.customerEmailAddress) {
          body.customerEmailAddress = this.localRefund.customerEmailAddress;
        }

        this.axios
          .patch(`/v1/sales/${this.saleId}/refund-requests/${this.refund.id}`, body)
          .then((response) => {
            if (response.status !== 200) {
              throw new Error(response.statusText);
            }

            this.showRefundEditForm = false;

            // display a notification
            this.$notify({
              message: "Refund request edited successfully",
              timeout: 8000,
              icon: "fa fa-pencil",
              horizontalAlign: "right",
              verticalAlign: "top",
              type: "success",
            });

            // clear any previous error
            this.refundError = null;

            // refresh page
            this.$emit('refund-request-updated')
          })
          .catch((error) => {
            this.refundError = error.message;

            if (error.response && error.response.data && error.response.data.message) {
              this.refundError = error.response.data.message;
            }
          })
          .finally(() => {
            this.editingRefund = false;
          });
      },
      openRefundDeletionForm(refundId) {
        Swal.fire({
          type: 'warning',
          title: 'Are you sure you want to delete this refund request?',
          showCancelButton: true,
          confirmButtonText: 'Delete refund',
          reverseButtons: true,
          showLoaderOnConfirm: true,
          preConfirm: () => {
            return this.axios
              .delete(`/v1/sales/${this.saleId}/refund-requests/${refundId}`)
              .then((response) => {
                if (response.status !== 204) {
                  throw new Error(response.statusText)
                }
                
                return response.data
              })
              .catch((error) => {
                let err = error.message
                if (error.response && error.response.data && error.response.data.message) {
                  err = error.response.data.message
                }
                Swal.showValidationMessage(`Request failed: ${err}`)
              })
          },
          allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
          if (result.value === true) {

            Swal.fire({
              title: 'Refund request deleted',
              type: 'success'
            }).then(() => {
              // refresh page after success message is closed
              this.$emit('refund-request-updated')
            })
          }
        })
      },
    },
    data() {
      return {
        localRefund: null,
        showRefundEditForm: false,
        editingRefund: false,
        refundError: null,
      };
    },
    watch: {
      showRefundEditForm(newVal) {
        this.localRefund = JSON.parse(JSON.stringify(this.refund));
      },
    },
  };
  </script>
  