<template>
  <div class="login-page">
    <app-navbar></app-navbar>
    <div class="wrapper wrapper-full-page">
      <div class="full-page login-page">
        <!--   you can change the color of the filter page using: data-color="blue | azure | green | orange | red | purple" -->
        <div class="content">
          <div class="container">
            <div class="col-lg-4 col-md-6 ml-auto mr-auto mb-5">
              <card type="login" v-if="!totpTokenRequired">
                <div slot="header" class="text-center">
                  <!-- NOTE: the string "Welcome back to My CabCard" is used for uptime monitor 1103927; ensure you update the monitor configuration if you update the copy -->
                  <span class="text-muted">Welcome back to My CabCard</span>
                  <h3 class="header">Log In</h3>
                </div>
                <form>
                  <fg-input v-model="form.emailAddress" name="emailAddress" type="email" data-vv-as="email address"
                    data-vv-delay="1000" v-validate="formValidators.emailAddress" :error="fieldError('emailAddress')"
                    addon-left-icon="nc-icon nc-single-02" @keyup.enter="validate" placeholder="Email Address"
                    autocomplete="username" input-ref="email" ref="email-input"></fg-input>

                  <fg-input v-model="form.password" name="password" v-validate="formValidators.password"
                    data-vv-delay="1000" :error="fieldError('password')" addon-left-icon="nc-icon nc-key-25"
                    @keyup.enter="validate" placeholder="Password" type="password"
                    autocomplete="current-password"></fg-input>
                </form>

                <br />

                <div class="alert alert-warning" v-if="errorMessage">
                  <span>{{ errorMessage }}</span>
                </div>

                <template slot="footer">
                  <p-button v-if="!loggingIn" type="submit" round block class="mb-3" @click.prevent="validate">
                    Log in &nbsp;
                    <i class="fa fa-arrow-right"></i>
                  </p-button>
                  <p-button v-if="loggingIn" type="info" round block class="mb-3" disabled>
                    <i class="fa fa-spin fa-circle-o-notch"></i>&nbsp; Please
                    wait&hellip;
                  </p-button>

                  <p class="text-right">
                    <router-link :to="`/forgot-password?emailAddress=${form.emailAddress}`">Forgot password?</router-link>
                  </p>
                </template>
              </card>
              <card v-else>
                <div class="alert alert-warning" v-if="errorMessage">
                  <span>{{ errorMessage }}</span>
                </div>
                <div slot="header">
                  <span class="text-center">
                    Enter the code from your authenticator app.
                  </span>
                </div>
                <form @submit.prevent>
                  <fg-input v-model="totpToken" name="totpToken" type="text" data-vv-as="code"
                    data-vv-delay="1000" v-validate="formValidators.totpToken" :error="fieldError('totpToken')"
                    addon-left-icon="nc-icon nc-key-25" @keyup.enter="validate" placeholder="Code"
                    autocomplete="one-time-code" input-ref="totpToken" ref="totpToken-input"></fg-input>
                </form>

                <p
                  style="cursor:pointer;"
                  class="text-center text-muted mb-0"
                  @click="showLostAccessInfo">
                  Lost access to your device?
                </p>

                <template slot="footer">
                  <p-button v-if="!loggingIn" type="submit" round block class="mb-3" @click.prevent="validate">
                    Submit &nbsp;
                    <i class="fa fa-arrow-right"></i>
                  </p-button>
                  <p-button v-if="loggingIn" type="info" round block class="mb-3" disabled>
                    <i class="fa fa-spin fa-circle-o-notch"></i>&nbsp; Please
                    wait&hellip;
                  </p-button>
                </template>

              </card>
            </div>
          </div>
        </div>
        <app-footer></app-footer>
        <div class="full-page-background" style="background-image: url('/static/img/background/yellow-bg.svg')"></div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";

import getDeviceId from "src/device.js";
import { Card, Checkbox, Button } from "src/components/UIComponents";
import AppNavbar from "./Layout/AppNavbar";
import AppFooter from "./Layout/AppFooter";

export default {
  components: {
    Card,
    AppNavbar,
    AppFooter,
    [Checkbox.name]: Checkbox,
    [Button.name]: Button,
  },
  mounted() {
    // focus on the email address field
    this.$nextTick(() => {
      if (this.$refs["email-input"]) {
        this.$refs["email-input"].focus();
      }
    });
  },
  methods: {
    toggleNavbar() {
      document.body.classList.toggle("nav-open");
    },
    closeMenu() {
      document.body.classList.remove("nav-open");
      document.body.classList.remove("off-canvas-sidebar");
    },
    validate() {
      this.errorMessage = null;
      this.$validator.validateAll().then((isValid) => {
        if (isValid) {
          this.login();
        }
      });
    },
    fieldError(fieldName) {
      // clientside
      if (this.errors.first(fieldName)) {
        return this.errors.first(fieldName);
      }
    },
    maybeClearLocalStorage() {
      // NOTE: update this line when a change is made to VueTables structure etc.
      const MIN_LOCAL_STORAGE_CLEARANCE_DATE = "2023-08-12T10:00:00.000Z";
      const localStorageClearedAt = this.$ls.get("local_storage_cleared_at");
      if (localStorageClearedAt) {
        if (
          moment(localStorageClearedAt).isBefore(
            MIN_LOCAL_STORAGE_CLEARANCE_DATE
          )
        ) {
          localStorage.clear();
          this.$ls.set("local_storage_cleared_at", moment().toISOString());
        }
      } else {
        localStorage.clear();
        this.$ls.set("local_storage_cleared_at", moment().toISOString());
      }
    },
    login() {
      this.loggingIn = true;
      this.maybeClearLocalStorage();
      // create refresh token
      const body = {
        type: "refresh",
        grantType: "password",
        emailAddress: this.form.emailAddress,
        password: this.form.password,
        deviceIdentifier: getDeviceId(),
      }

      if (this.totpTokenRequired) {
        body.mfaToken = this.totpToken;
      }

      this.axios
        .post(
          "/v1/tokens",
          body,
          { skipAuthRefresh: true }
        )
        .then((response) => {
          this.$store.dispatch("login");

          // set the refresh token expiry
          const expSeconds =
            moment(response.data.expiresAt).unix() - moment().unix();
          this.$ls.set(
            "refresh_token_expires_at",
            response.data.expiresAt,
            expSeconds * 1000
          );


          const accessTokenBody = {
            type: "access",
            grantType: "refresh_token",
            attachments: ["submerchant"],
            deviceIdentifier: getDeviceId(),
          }

          // now get an access token
          return this.axios.post("/v1/tokens", accessTokenBody);
        })
        .then((response) => {
          // set the access token expiry
          const expSeconds =
            moment(response.data.expiresAt).unix() - moment().unix();
          this.$ls.set(
            "access_token_expires_at",
            response.data.expiresAt,
            expSeconds * 1000
          );

          // finally, get a user
          return this.axios.get("/v1/users/current");
        })
        .then((response) => {
          this.$store.commit("SET_USER", response.data);
          this.loggingIn = false;
          // redirect
          this.$router.push("/select-account");

          // if totptoken is 24 chars long and access token has been
          // granted it would have to be  a recovery code that's been used
          if (this.totpToken.length === 24) {
            this.showUsedRecoveryCodeInfo();
          }
        })
        .catch((error) => {
          console.error("error: ", error);
          this.loggingIn = false;
          if (error.response) {
            if (error.response.data.message == 'MFA token required') {
              this.totpTokenRequired = true;
              return;
            }
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            if (error.response.data.message) {
              this.errorMessage = error.response.data.message;

              if (error.response.status === 400 && error.response.data.errors) {
                this.validationErrors = error.response.data.errors;
              }
            } else {
              this.errorMessage = "Sorry, an unknown error occurred.";
            }
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest
            this.errorMessage = "Error - no response from server.";
          } else {
            // Something happened in setting up the request that triggered an Error
            this.errorMessage = "Error - request not sent";
          }
        });
    },
    showLostAccessInfo() {
      this.$swal({
        icon: "info",
        title: "Recover your account",
        html: `<p>If you have access to your recovery code which was issued when you enrolled for MFA, you can use it to login once more - just enter it where you would normally enter your MFA code. <br> <p>If you don't have your recovery code, please <a href="https://support.cabcard.services/contact-us"> contact support</a>.</p>`,
        confirmButtonColor: "#66615B",
        backdrop: 'rgba(0,0,0,0.4)'
      })
    },
    showUsedRecoveryCodeInfo() {
      this.$swal({
        icon: "warning",
        title: "Recovery code used",
        text: "You have used your recovery code to login, so multi-factor authentication (MFA) is now disabled on your account. Please re-enable it in your account settings if you wish to use MFA again.",
        confirmButtonColor: "#66615B",
        backdrop: 'rgba(0,0,0,0.4)'
      })
    }
  },
  data() {
    return {
      form: {
        emailAddress: "",
        password: "",
      },
      formValidators: {
        emailAddress: {
          required: true,
          email: true,
        },
        password: {
          required: true,
        },
        totpToken: {
          required: true,
        },
      },
      validationErrors: {},
      errorMessage: null,
      loggingIn: false,
      totpTokenRequired: false,
      totpToken: null,
      mfaError: null
    };
  },
  beforeDestroy() {
    this.closeMenu();
  },
};
</script>
<style></style>
