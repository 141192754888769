<template>
	<div class="row">
		<div class="col-12 col-lg-8">
			<div class="card">
				<div class="card-body text-center">
					<img v-if="enrollmentStatus !== 'queued'" src="/static/img/illustrations/bird-whistling.svg" class="p-5 w-50" />
					<div v-else class="spinner-container">
						<double-bounce size="120px"></double-bounce>
					</div>
					<p v-if="enrollmentStatus === 'queued'" class="lead">You are now being set up for payment links. This
						{{ getReadableQueueTimeMessage() }}; please check again later.</p>
					<p v-else-if="enrollmentStatus === 'enrolled'" class="lead">You are now set up to use payment links. For help
						getting started, you can check our Payment Links support page <a
							href="https://support.cabcard.services/en_GB/payment-links">here</a>.</p>
					<div v-else-if="enrollmentStatus === 'error'">
						<p class="lead">There was an error setting up payment links for your account. Please press this button to
							request manual set-up:</p>
						<a class="btn btn-lg btn-info"
							:href="`https://tally.so#tally-open=wo9Zy1&tally-layout=modal&tally-hide-title=1&tally-auto-close=3000&submerchantId=${submerchant.id}&userId=${submerchantUser.id}`">
							Request manual setup <i class="fa fa-paper-plane-o ml-2"></i></a>
					</div>
					<div v-else>
						<p class="lead">Your account is not yet set up for payment links.</p>
						<button class="btn btn-lg btn-info" @click="sendEnrollmentRequest">
							Request setup
							<i class="fa fa-paper-plane-o ml-2"></i></button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import moment from 'moment';
import { DoubleBounce } from "vue-loading-spinner";

export default {
	props: [],
	components: {
		DoubleBounce,
	},
	data() {
		return {
			// options: '', 'queued', 'error', 'enrolled'
			enrollmentStatus: '',
			// this is the time returned by the API; it's an estimate of how long it will take to enrol
			queueTime: 0,
		};
	},
	beforeMount() {
		this.checkEnrollmentStatus();
	},
	computed: {
		submerchant: function () {
			return this.$store.state.submerchant;
		},
		submerchantUser: function () {
			return this.$store.state.submerchantUser;
		}
	},
	methods: {
		setStatusToQueued() {
			this.enrollmentStatus = 'queued';
			// refresh the page after 1 minute
			setTimeout(() => {
				this.checkEnrollmentStatus();
			}, 60 * 1000);
		},
		sendEnrollmentRequest() {
			const requestBody = {
				submerchantId: this.submerchant.id,
				acquirer: 'finaro',
				type: 'submerchant'
			}

			this.axios.post('/v1/enrollments',
				requestBody
			).then((response) => {
				this.setStatusToQueued();
				this.setQueueTime(response.data);
			}).catch((error) => {
				if (error.message === "Request failed with status code 409") {
					this.setStatusToQueued();
				} else {
					this.enrollmentStatus = 'error';
				}
			});
		},
		setQueueTime(data) {
			const localTime = moment(data.processingExpectedBy).format('HH:mm');
			// if it is Sunday, set queueTime to 'Sunday'
			this.queueTime = (moment().isoWeekday() === 7) ? 'Sunday' : localTime;
		},
		getReadableQueueTimeMessage() {
			if (!this.queueTime) {
				return 'can take some time';
			} else if (this.queueTime === 'Sunday') {
				return 'will be completed on Monday morning';
			} else {
				return 'is expected to complete at about ' + this.queueTime;
			}
		},
		checkEnrollmentStatus() {
			// used to check which message should be displayed
			if (this.submerchant.finaroId) {
				this.enrollmentStatus = 'enrolled';
			} else {
				// these methods are asynchronous; to give feedback ASAP we want to keep the first one that sets enrollmentStatus to 'enrolled'
				// we do Promise.all so that we can emit the 'render full page' event for the parent if it _is_ enrolled
				Promise.all([
					// check if the submerchant is already enrolled
					this.axios.get(`/v1/submerchants/${this.submerchant.id}`).then((response) => {
						if (this.submerchant.finaroId) {
							this.enrollmentStatus = 'enrolled';
						}
						// update submerchant in store to get vue to re-render relevant elements
						const submerchant = response.data;
						this.$store.commit("SET_SUBMERCHANT", submerchant);
					}).catch((error) => {
						// do nothing; we just don't want to cause the function to return before both gets have completed
					}),

					// check if the submerchant is already in the queue for enrollment
					this.axios.get(`/v1/enrollments?submerchantId=${this.submerchant.id}&acquirer=finaro`).then((response) => {
						// return early if the submerchant was found by the other check
						if (this.enrollmentStatus == 'enrolled') {
							return;
						};
						if (response.data.length > 0) {
							// check if any member of response.data has acquirer === 'finaro'
							const foundEnrollment = response.data.find(item => item.acquirer === 'finaro');
							if (foundEnrollment) {
								this.setStatusToQueued();
								this.setQueueTime(foundEnrollment);
							}
						};
					}).catch((error) => {
						// do nothing; we just don't want to cause the function to return before both gets have completed
					})
				])
				.then(() => {
					// if the submerchant is enrolled, emit the event to render the full page
					// this is only used on IntentsList.vue, because we use it to call the function to get the list
					if (this.enrollmentStatus) {
						this.$emit('enrollment-complete');
					}
				});
			}
		},
	},
}

</script>