<template>
  <div class="wrapper">
    <side-bar
      title="My CabCard"
      logo="/static/img/logo-small.png"
      type="sidebar"
      :sidebar-links="$sidebar.sidebarLinks"
      :submerchant="submerchant"
      :submerchant-user="submerchantUser"
    >
      <user-menu></user-menu>
    </side-bar>

    <div class="main-panel">
      <top-navbar :submerchant="submerchant"></top-navbar>

      <dashboard-content @click.native="toggleSidebar"></dashboard-content>

      <content-footer></content-footer>
    </div>
  </div>
</template>
<style lang="scss">
.sidebar .logo .simple-text .logo-img img {
  height: auto !important;
}
</style>
<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import UserMenu from "src/components/UIComponents/SidebarPlugin/UserMenu.vue";

export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    UserMenu,
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
  computed: {
    submerchant: function () {
      return this.$store.state.submerchant;
    },
    submerchantUser: function () {
      return this.$store.state.submerchantUser;
    },
  },
};
</script>
