var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"row"},[_vm._m(0),(_vm.showSubmerchantTerminals)?_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('v-select',{attrs:{"filterable":false,"options":_vm.searchResults,"label":"id","placeholder":"Search by terminal serial number, reference etc."},on:{"search":_vm.onSearch,"option:selected":_vm.chooseSearchResult},scopedSlots:_vm._u([{key:"option",fn:function(terminal){return [_vm._v(" TSN "+_vm._s(terminal.serialNumber)+" "),(terminal.customReference)?_c('code',{staticClass:"ml-1"},[_vm._v(" "+_vm._s(terminal.customReference)+" ")]):_vm._e()]}},{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
return [(searching)?[_vm._v(" No results found for "),_c('em',[_vm._v(_vm._s(search))]),_vm._v(". ")]:_c('em',{staticStyle:{"opacity":"0.5"}},[_vm._v("Try terminal serial number, ID or reference.")])]}}],null,false,2514314861)})],1)])]):_vm._e()])]),(_vm.showSubmerchantTerminals)?_c('div',{staticClass:"card-body"},[_c('active-filter-list',{attrs:{"activeFilters":_vm.activeFilters,"tableName":_vm.tableName},on:{"remove-filter":_vm.removeFilter,"clear-filters":_vm.clearFilters}}),_c('v-server-table',{ref:"terminalsTable",attrs:{"url":("/v1/terminals?currentAllocation[submerchantId]=" + (_vm.submerchant.id)),"name":"terminalsTable","columns":_vm.columns,"options":_vm.options},on:{"filter":_vm.addToActiveFilters},scopedSlots:_vm._u([{key:"id",fn:function(props){return [_c('code',[_vm._v(_vm._s(props.row.id))])]}},{key:"serialNumber",fn:function(props){return [_c('code',[_vm._v(_vm._s(props.row.serialNumber))])]}},{key:"currentAllocation.createdAt",fn:function(props){return [_c('div',[_vm._v(" "+_vm._s(_vm._f("moment")(props.row.currentAllocation.createdAt,"YYYY-MM-DD HH:mm A"))+" ")])]}},{key:"reportedConfiguration.isUpToDate",fn:function(props){return [_c('div',[(
              props.row.reportedConfiguration &&
              props.row.reportedConfiguration.isUpToDate
            )?_c('span',{staticClass:"text-success"},[_c('i',{staticClass:"fa fa-check"}),_vm._v(" Up to date ")]):(
              props.row.reportedConfiguration &&
              !props.row.reportedConfiguration.isUpToDate
            )?_c('span',{staticClass:"text-danger"},[_c('i',{staticClass:"fa fa-exclamation-triangle"}),_vm._v(" Out of date ")]):_c('span',{staticClass:"text-warning"},[_c('i',{staticClass:"fa fa-question-circle"}),_vm._v(" Status unknown ")])])]}},{key:"links",fn:function(props){return [_c('router-link',{staticClass:"btn btn-default btn-sm",attrs:{"to":("/terminals/" + (props.row.id))}},[_vm._v(" View "),_c('i',{staticClass:"fa fa-chevron-right"})])]}}],null,false,4215737578)})],1):_c('div',{staticClass:"card-body"},[_vm._v("Not permitted to view this data.")])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-4"},[_c('h4',{staticClass:"title"},[_vm._v("Terminals")])])}]

export { render, staticRenderFns }