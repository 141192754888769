<template>
  <div>
    <div class="row">
      <div class="col-sm-12 col-md-8">
        <div class="card">
          <h2 class="card-header">Developer Tools</h2>

          <div class="card-body">
            <p>CabCard makes a variety of developer tools available to you.</p>
            <p>
              The easiest way to interact with your account is using webhooks.
            </p>
            <p>
              For technical documentation and support, contact
              <a href="mailto:developers@cabcard.services"
                >developers@cabcard.services</a
              >
            </p>

            <!-- TODO: add more info, including links to docs -->
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 col-md-8">
        <div class="card">
          <h3 class="card-header">API Keys</h3>

          <div class="card-body">
            <div class="list-group">
              <!-- TODO: list all current API keys -->
              <div
                class="list-group-item"
                v-for="(apiKey, index) in apiKeys"
                :key="apiKey.id"
              >
                <!-- state -->
                <span class="badge pull-right" v-if="apiKey.stateChanging"
                  ><i class="fa fa-spin fa-spinner"></i
                ></span>
                <span
                  class="badge pull-right text-success"
                  v-else-if="apiKey.isEnabled"
                  ><i class="fa fa-check"></i
                ></span>
                <span
                  class="badge pull-right text-danger"
                  v-else-if="!apiKey.isEnabled"
                  ><i class="fa fa-times"></i
                ></span>
                <!-- name -->
                <strong>{{ apiKey.name || "Unnamed API key" }}</strong
                >:
                <code>{{ apiKey.identifier }}</code>
                <br />
                <span class="text-muted" v-if="apiKey.lastSeenAt">
                  Last used
                  <time :datetime="apiKey.lastSeenAt" :title="apiKey.lastSeenAt"
                    >{{
                      apiKey.lastSeenAt | moment("from", "now")
                    }}
                    (approx.)</time
                  ></span
                >
                <span class="text-muted" v-else>Never used</span>
                <br />
                <span>{{ apiKey.permissions.length }} permissions:</span>
                <ul>
                  <li
                    v-for="permission in apiKey.permissions"
                    :key="permission"
                  >
                    <code>{{ permission }}</code>
                  </li>
                </ul>

                <div class="text-right">
                  <button
                    v-if="!apiKey.isEnabled"
                    class="btn btn-sm btn-success"
                    @click="enableApiKey(index)"
                    :disabled="apiKey.stateChanging"
                  >
                    <span v-if="!apiKey.stateChanging"
                      ><i class="fa fa-rocket"></i>&nbsp; Enable</span
                    >
                    <span v-if="apiKey.stateChanging"
                      ><i class="fa fa-spin fa-spinner"></i>&nbsp; Working</span
                    >
                  </button>
                  <button
                    v-if="apiKey.isEnabled"
                    class="btn btn-sm btn-warning"
                    @click="disableApiKey(index)"
                    :disabled="apiKey.stateChanging"
                  >
                    <span v-if="!apiKey.stateChanging"
                      ><i class="fa fa-ban"></i>&nbsp; Disable</span
                    >
                    <span v-if="apiKey.stateChanging"
                      ><i class="fa fa-spin fa-spinner"></i>&nbsp; Working</span
                    >
                  </button>
                </div>
              </div>
            </div>

            <button
              class="btn btn-secondary"
              v-if="!showAPIKeyCreateForm"
              v-on:click="showAPIKeyCreateForm = true"
            >
              Create an API Key &nbsp;
              <i class="fa fa-plus"></i>
            </button>
            <div v-if="showAPIKeyCreateForm">
              <hr />
              <api-key-create-form
                @created="handleAPIKeyCreated"
                @cancelled="showAPIKeyCreateForm = false"
              ></api-key-create-form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 col-md-8">
        <div class="card">
          <h3 class="card-header">Webhook endpoints</h3>

          <div class="card-body">
            <div class="list-group">
              <div
                class="list-group-item"
                v-for="(endpoint, index) in endpoints"
                :key="endpoint.id"
              >
                <!-- state -->
                <span class="badge pull-right" v-if="endpoint.stateChanging"
                  ><i class="fa fa-spin fa-spinner"></i
                ></span>
                <span
                  class="badge pull-right text-success"
                  v-else-if="endpoint.isEnabled"
                  ><i class="fa fa-check"></i
                ></span>
                <span
                  class="badge pull-right text-danger"
                  v-else-if="!endpoint.isEnabled"
                  ><i class="fa fa-times"></i
                ></span>
                <!-- name -->
                <strong>{{ endpoint.name || "Unnamed endpoint" }}</strong
                ><br />
                <!-- URL -->
                <code>{{ endpoint.url }}</code
                ><br />
                <span>Event triggers:</span>
                <ul>
                  <li v-for="event in endpoint.events" :key="event">
                    <code>{{ event }}</code>
                  </li>
                </ul>
                <div class="text-right">
                  <button
                    v-if="!endpoint.isEnabled"
                    class="btn btn-sm btn-success"
                    @click="enableEndpoint(index)"
                    :disabled="endpoint.stateChanging"
                  >
                    <span v-if="!endpoint.stateChanging"
                      ><i class="fa fa-rocket"></i>&nbsp; Enable</span
                    >
                    <span v-if="endpoint.stateChanging"
                      ><i class="fa fa-spin fa-spinner"></i>&nbsp; Working</span
                    >
                  </button>
                  <button
                    v-if="endpoint.isEnabled"
                    class="btn btn-sm btn-warning"
                    @click="disableEndpoint(index)"
                    :disabled="endpoint.stateChanging"
                  >
                    <span v-if="!endpoint.stateChanging"
                      ><i class="fa fa-ban"></i>&nbsp; Disable</span
                    >
                    <span v-if="endpoint.stateChanging"
                      ><i class="fa fa-spin fa-spinner"></i>&nbsp; Working</span
                    >
                  </button>
                  <button
                    v-if="endpoint.isEnabled"
                    class="btn btn-sm btn-info"
                    @click="sendTestEndpointNotification(index)"
                  >
                    <i class="fa fa-flask"></i>&nbsp; Test
                  </button>
                  <button
                    class="btn btn-sm btn-danger"
                    @click="deleteEndpoint(index)"
                    :disabled="endpoint.stateChanging"
                  >
                    <i class="fa fa-trash"></i>&nbsp; Delete
                  </button>
                  <router-link
                    :to="`developer/endpoints/${endpoint.id}/notifications`"
                    class="btn btn-sm btn-default"
                  >
                    <i class="fa fa-history"></i>&nbsp; History
                  </router-link>
                </div>
              </div>
            </div>

            <button
              class="btn btn-secondary"
              v-if="!showWebhookCreateForm"
              v-on:click="showWebhookCreateForm = true"
            >
              Create a webhook endpoint &nbsp;
              <i class="fa fa-plus"></i>
            </button>
            <div v-if="showWebhookCreateForm">
              <hr />
              <webhook-endpoint-create-form
                @created="handleEndpointCreated"
              ></webhook-endpoint-create-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WebhookEndpointCreateForm from "./WebhookEndpointCreateForm";
import ApiKeyCreateForm from "./APIKeyCreateForm";

export default {
  components: {
    WebhookEndpointCreateForm,
    ApiKeyCreateForm,
  },
  data() {
    return {
      showWebhookCreateForm: false,
      showAPIKeyCreateForm: false,
      endpointsLoading: false,
      endpoints: [],
      apiKeysLoading: false,
      apiKeys: [],
    };
  },
  beforeMount() {
    this.endpointsLoading = true;

    this.getWebhooks();
    this.getApiKeys();
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    validate() {
      this.$validator.validateAll().then((isValid) => {
        this.$emit("on-submit", this.registerForm, isValid);
      });
    },
    getWebhooks: function () {
      this.axios
        .get("/v1/webhook-endpoints")
        .then((response) => {
          this.endpointsLoading = false;
          this.endpoints = response.data;
        })
        .catch((error) => {
          this.endpointsLoading = false;
          console.error("error: ", error.response.data);
        });
    },
    getApiKeys: function () {
      this.axios
        .get("/v1/api-keys")
        .then((response) => {
          this.apiKeysLoading = false;
          this.apiKeys = response.data;
        })
        .catch((error) => {
          this.apiKeysLoading = false;
          console.error("error: ", error.response.data);
        });
    },
    handleEndpointCreated: function (val) {
      this.endpoints.push(val);
      this.showWebhookCreateForm = false;
    },
    handleAPIKeyCreated: function (val) {
      this.apiKeys.push(val);
      this.showAPIKeyCreateForm = false;
    },
    disableEndpoint: function (index) {
      const newVal = JSON.parse(JSON.stringify(this.endpoints[index]));
      newVal.stateChanging = true;
      this.endpoints.splice(index, 1, newVal);

      // network request
      this.axios
        .patch(`/v1/webhook-endpoints/${this.endpoints[index].id}`, {
          isEnabled: false,
        })
        .then((response) => {
          this.endpoints.splice(index, 1, response.data);

          this.$notify({
            message: "Endpoint disabled",
            timeout: 3000,
            icon: "fa fa-check",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "success",
          });
        })
        .catch((err) => {
          return this.$swal({
            icon: "error",
            title: "Error disabling endpoint",
            text: err.message,
          });
        });
    },
    enableEndpoint: function (index) {
      const newVal = JSON.parse(JSON.stringify(this.endpoints[index]));
      newVal.stateChanging = true;
      this.endpoints.splice(index, 1, newVal);

      this.axios
        .patch(`/v1/webhook-endpoints/${this.endpoints[index].id}`, {
          isEnabled: true,
        })
        .then((response) => {
          this.endpoints.splice(index, 1, response.data);
          this.$notify({
            message: "Endpoint enabled",
            timeout: 3000,
            icon: "fa fa-check",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "success",
          });
        })
        .catch((err) => {
          return this.$swal({
            icon: "error",
            title: "Error enabling endpoint",
            text: err.message,
          });
        });
    },
    sendTestEndpointNotification: function (index) {
      const endpoint = JSON.parse(JSON.stringify(this.endpoints[index]));

      const inputOptionsPromise = new Promise((resolve, reject) => {
        this.axios
          .get(
            "/v1/sales?limit=10&fields=id,retrievalReferenceNumber,capturedAt&sort=-capturedAt"
          )
          .then((response) => {
            const sales = response.data;
            if (sales.length < 1) {
              // const err = new Error(
              //   "At least one sale is required on your account"
              // );
              // throw err;
              sales.push({
                id: "sal_test_only",
                retrievalReferenceNumber: "TestOnly",
                capturedAt: "2021-12-02T18:54:00Z",
              });
            }

            const options = {};
            sales.forEach((item) => {
              options[
                item.id
              ] = `RRN: ${item.retrievalReferenceNumber} (${item.capturedAt})`;
            });
            return resolve(options);
          })
          .catch(reject);
      });

      this.$swal
        .fire({
          icon: "info",
          title: "Send test request to endpoint?",
          subtitle: "Event 'sale.created' will be sent",
          showCancelButton: true,
          input: "select",
          inputOptions: inputOptionsPromise,
          inputPlaceholder: "Select a sale transaction",
          inputValidator: (value) => {
            if (!value) {
              return "A sale must be selected";
            }
          },
          preConfirm: (input) => {
            return this.axios
              .post(`/v1/webhook-endpoints/${endpoint.id}/test-notifications`, {
                event: "sale.created",
                saleId: input,
              })
              .then((response) => {
                return response;
              })
              .catch((error) => {
                this.$swal.showValidationMessage(
                  `Test failed: ${error.response.data.message}`
                );
              });
          },
        })
        .then((result) => {
          if (result.value) {
            return this.$swal({
              icon: "success",
              title: "Test notification sent",
            });
          }
        });
    },
    deleteEndpoint: function (index) {
      // set endpoint.stateChanging
      const endpoint = JSON.parse(JSON.stringify(this.endpoints[index]));

      // DELETE endpoint
      this.$swal({
        icon: "warning",
        title: "Delete endpoint?",
        text: "This cannot be undone.",
        showCancelButton: true,
        preConfirm: (input) => {
          return this.axios
            .delete(`/v1/webhook-endpoints/${endpoint.id}`)
            .then((response) => {
              this.endpoints.splice(index, 1);
              return response;
            })
            .catch((error) => {
              this.$swal.showValidationMessage(
                `Deletion failed: ${error.response.data.message}`
              );
            });
        },
      }).then((result) => {
        if (result.value) {
          return this.$swal({
            icon: "success",
            title: "Endpoint deleted",
          });
        }
      });
    },
    disableApiKey: function (index) {
      const newVal = JSON.parse(JSON.stringify(this.apiKeys[index]));
      newVal.stateChanging = true;
      this.apiKeys.splice(index, 1, newVal);

      // network request
      this.axios
        .patch(`/v1/api-keys/${this.apiKeys[index].id}`, {
          isEnabled: false,
        })
        .then((response) => {
          this.apiKeys.splice(index, 1, response.data);

          this.$notify({
            message: "API key will be disabled",
            timeout: 3000,
            icon: "fa fa-check",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "success",
          });
        })
        .catch((err) => {
          return this.$swal({
            icon: "error",
            title: "Error disabling API key",
            text: err.message,
          });
        });
    },
    enableApiKey: function (index) {
      const newVal = JSON.parse(JSON.stringify(this.apiKeys[index]));
      newVal.stateChanging = true;
      this.apiKeys.splice(index, 1, newVal);

      this.axios
        .patch(`/v1/api-keys/${this.apiKeys[index].id}`, {
          isEnabled: true,
        })
        .then((response) => {
          this.apiKeys.splice(index, 1, response.data);

          this.$notify({
            message: "API key enabled",
            timeout: 3000,
            icon: "fa fa-check",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "success",
          });
        })
        .catch((err) => {
          return this.$swal({
            icon: "error",
            title: "Error enabling API key",
            text: err.message,
          });
        });
    },
  },
};
</script>
