export default [
  {
    name: "Dashboard",
    icon: "fa fa-tachometer",
    path: "/dashboard",
  },
  {
    name: "Transactions",
    icon: "fa fa-credit-card",
    path: "/transactions/sales",
  },
  {
    name: "Payment Links",
    icon: "fa fa-link",
    path: "/payment-links",
  },
  {
    name: "Scan to Pay",
    icon: "fa fa-qrcode",
    path: "/scan-to-pay",
  },
  {
    name: "Payouts",
    icon: "fa fa-bank",
    path: "/payouts",
    submerchantUserRoles: ["owner", "manager", "viewer"],
  },
  {
    name: "Terminals",
    icon: "fa fa-calculator",
    path: "/terminals",
    submerchantUserRoles: ["owner", "manager", "viewer"],
  },
  {
    name: "Support",
    icon: "fa fa-support",
    path: "/support",
    isRoute: false,
  },
  {
    name: "System Status",
    icon: "fa fa-bolt",
    path: "/status",
    isRoute: false,
  },
  {
    name: "Developer Tools",
    icon: "fa fa-code",
    path: "/developer",
    submerchantUserRoles: ["owner", "manager"],
  },
];
