<template>
  <div class="row">
    <div v-if="intent" class="col-md-12">
      <div class="card">
        <h2 class="card-header">Payment link details</h2>
        <div class="card-body">
          <h6 class="card-subtitle mb-4 text-muted">
            Payment link ID:
            <code class="text-transform-none">{{ intent.id }}</code>
          </h6>

          <div class="row">
            <div class="col-md-6">
              <table class="table">
                <tbody>
                  <tr>
                    <th
                      v-if="!intent.captureMode ||
                        intent.captureMode === 'automatic'
                        ">
                      Amount
                    </th>
                    <th v-else-if="intent.captureMode === 'manual'">
                      Authorized Amount
                    </th>
                    <td>{{ intent.amount | money(intent.currency) }}</td>
                  </tr>
                  <tr
                    v-if="intent.captureMode === 'manual' &&
                      intent.captureAmount !== null
                      ">
                    <th>Captured Amount</th>
                    <td>{{ intent.captureAmount | money(intent.currency) }}</td>
                  </tr>
                  <tr>
                    <th>Status</th>
                    <td>
                      <div class="row">
                        <div class="col-md-8">
                          <span>{{ intent.status | humanize }}</span><br />
                          <time
                            :datetime="loadedAt"
                            :title="loadedAt"
                            class="small"
                            v-if="!intent.statusIsFinal">
                            Checked {{ loadedAt | moment("from", "now") }}
                          </time>
                        </div>
                        <div class="col-md-4">
                          <button
                            class="btn btn-default btn"
                            :disabled="!canCheck"
                            title="Check status"
                            @click="reloadIntent"
                            v-if="!intent.statusIsFinal">
                            <i v-if="!checking" class="fa fa-refresh"></i>
                            <i
                              v-if="checking"
                              class="fa fa-spin fa-refresh"
                              title="Checking status"></i>
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="!intent.statusIsFinal">
                    <th>Actions</th>
                    <td>
                      <router-link
                        v-if="intent.status === 'created'"
                        class="btn btn-info"
                        :to="`/payment-links/${intent.id}/edit`">
                        <i class="fa fa-pencil mr-2"></i> Edit
                      </router-link>
                      &nbsp;
                      <button
                        v-if="intent.status === 'created'"
                        :disabled="cancelling"
                        @click="cancelIntent"
                        class="btn btn-warning">
                        <span v-if="cancelling"><i class="fa fa-spin fa-spinner mr-2"></i> Please
                          wait&hellip;</span>
                        <span v-else><i class="fa fa-ban mr-2"></i> Cancel</span>
                      </button>
                      &nbsp;
                      <button
                        v-if="intent.status === 'authorized_pending_capture'"
                        :disabled="capturing"
                        @click="captureIntent"
                        class="btn btn-success">
                        <span v-if="capturing"><i class="fa fa-spin fa-spinner mr-2"></i> Please
                          wait&hellip;</span>
                        <span v-else><i class="fa fa-diamond mr-2"></i> Capture</span>
                      </button>
                      &nbsp;
                      <button
                        v-if="!intent.statusIsFinal"
                        :disabled="resetting"
                        @click="resetIntent"
                        class="btn btn-default">
                        <span v-if="resetting"><i class="fa fa-spin fa-spinner mr-2"></i> Please
                          wait&hellip;</span>
                        <span v-else><i class="fa fa-undo mr-2"></i> Reset</span>
                      </button>
                    </td>
                  </tr>
                  <tr
                    v-if="intent.status === 'cancelled' ||
                      intent.status === 'expired' ||
                      intent.status === 'payment_declined'
                      ">
                    <th>Delete</th>
                    <td>
                      <button
                        :disabled="deleting"
                        @click="deleteIntent"
                        class="btn btn-danger">
                        <span v-if="deleting"><i class="fa fa-spin fa-spinner mr-2"></i> Please
                          wait&hellip;</span>
                        <span v-else><i class="fa fa-trash mr-2"></i> Delete</span>
                      </button>
                    </td>
                  </tr>
                  <tr v-if="intent.customerSmsSent || intent.customerEmailSent">
                    <th>Sent via</th>
                    <td>
                      <span v-if="intent.customerSmsSent">SMS <i class="fa fa-comment ml-1 mr-3"></i></span>&nbsp;
                      <span v-if="intent.customerEmailSent">Email <i class="fa fa-envelope ml-1"></i></span>
                    </td>
                  </tr>
                  <tr>
                    <th>Created</th>
                    <td>
                      <time
                        :datetime="intent.createdAt"
                        :title="intent.createdAt">
                        {{ intent.createdAt | moment("from", "now") }}
                      </time>
                    </td>
                  </tr>
                  <tr v-if="intent.createdBy">
                    <th>Created by</th>
                    <td>
                      <user-fragment
                        v-if="intent.createdBy.startsWith('usr_')"
                        :userId="intent.createdBy"></user-fragment>
                      <api-key-fragment
                        v-else-if="intent.createdBy.startsWith('api_')"
                        :api-key-id="intent.createdBy"></api-key-fragment>
                    </td>
                  </tr>
                  <tr v-if="intent.status === 'succeeded'">
                    <th>Completed</th>
                    <td>
                      <time
                        :datetime="intent.succeededAt"
                        :title="intent.succeededAt">
                        {{ intent.succeededAt | moment("from", "now") }}
                      </time>
                    </td>
                  </tr>
                  <tr v-if="!intent.statusIsFinal">
                    <th>Expiry</th>
                    <td>
                      <time
                        :datetime="intent.expiresAt"
                        :title="intent.expiresAt">
                        {{ intent.expiresAt | moment("from", "now") }}
                      </time>
                    </td>
                  </tr>
                  <tr>
                    <th>Reference</th>
                    <td>
                      <code v-if="intent.reference">{{
                        intent.reference
                      }}</code>
                      <span v-else>Not set</span>
                    </td>
                  </tr>
                  <tr>
                    <th>Description</th>
                    <td>
                      <span v-if="intent.description">{{
                        intent.description
                      }}</span>
                      <span v-else>Not set</span>
                    </td>
                  </tr>
                  <tr v-if="intent.customerIpAddress">
                    <th>Customer IP</th>
                    <td>
                      <a
                        :href="`https://whatismyipaddress.com/ip/${intent.customerIpAddress}`"
                        target="_blank"
                        title="IP address details (opens in new tab)">
                        <code>{{ intent.customerIpAddress }}</code>
                      </a>
                    </td>
                  </tr>
                  <tr v-if="intent.customerDeviceInfo">
                    <th>Customer Device</th>
                    <td>
                      <ul>
                        <li
                          v-for="(val, key) in filteredCustomerDeviceInfo"
                          :key="key">
                          <strong>{{ key | humanize }}</strong> {{ val }}
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <th>Logs</th>
                    <td>
                      <button
                        class="btn btn-default btn-sm"
                        data-toggle="collapse"
                        data-target="#intent-logs">
                        Show / Hide
                      </button>
                      <ol class="collapse" id="intent-logs">
                        <li
                          v-for="(log, index) in intent.logs"
                          :key="index"
                          v-html="formatLog(log)"></li>
                      </ol>
                    </td>
                  </tr>
                </tbody>
              </table>

              <hr v-if="intent.status === 'created'" />

              <card v-if="intent.status === 'created'" plain>
                <h5 name="title">Resend payment link</h5>

                <div class="alert alert-success" v-if="notificationResent">
                  Payment link sent successfully
                  <i class="fa fa-thumbs-up ml-2"></i>
                </div>
                <form v-if="!notificationResent">
                  <div class="form-group">
                    <label>Phone number</label>
                    <div class="d-flex">
                      <vue-tel-input
                        v-if="!intent.customerPhoneNumber"
                        name="customerPhoneNumber"
                        v-model="customerPhoneNumber"
                        default-country="GB"
                        class="w-100"
                        :preferredCountries="['gb', 'ie']"
                        mode="international"
                        input-classes="form-control">
                      </vue-tel-input>
                      <p class="w-100" v-else>
                        {{ intent.customerPhoneNumber }}
                        <span
                          style="cursor:pointer; color:rgb(81, 203, 206);"
                          v-if="intent.status === 'created'"
                          @click="showPhoneEdit">
                          <i class="fa fa-pencil ml-2"></i>
                        </span>
                      </p>
                      <p-checkbox style="position: relative; top: -7px;" class="ml-4"
                        v-model="sendCustomerSms"></p-checkbox>
                    </div>
                    <label class="mt-2">Email</label>
                    <div class="d-flex">
                      <input
                        v-if="!intent.customerEmailAddress"
                        type="email"
                        name="customerEmail"
                        v-model="customerEmailAddress"
                        class="form-control w-100"
                        placeholder="e.g. user@example.com" />
                      <p class="w-100 mt-2" v-else>
                        {{ intent.customerEmailAddress }}
                        <span
                          style="cursor:pointer; color:rgb(81, 203, 206);"
                          v-if="intent.status === 'created'"
                          @click="showEmailEdit">
                          <i class="fa fa-pencil ml-2"></i>
                        </span>
                      </p>
                      <p-checkbox style="position: relative; top: -10px;" class="ml-4"
                        v-model="sendCustomerEmail"></p-checkbox>
                    </div>
                  </div>

                  <button
                    class="btn btn-warning btn-block"
                    :disabled="(!(customerPhoneNumber || intent.customerPhoneNumber) && !(customerEmailAddress || intent.customerEmailAddress)) || (!sendCustomerSms && !sendCustomerEmail)"
                    @click.prevent="sendCustomerNotification">
                    <span v-if="sendingCustomerNotification"><i class="fa fa-spin fa-spinner mr-2"></i>Please
                      wait&hellip;</span>
                    <div v-else>
                      <span
                        v-if="sendCustomerEmail && sendCustomerSms">
                        Send SMS &amp; Email
                        <i class="fa fa-paper-plane ml-2"></i>
                      </span>
                      <span v-else-if="sendCustomerSms">
                        Send SMS
                        <i class="fa fa-paper-plane ml-2"></i>
                      </span>
                      <span v-else-if="sendCustomerEmail">
                        Send Email
                        <i class="fa fa-paper-plane ml-2"></i>
                      </span>
                      <span
                        v-else-if="(customerPhoneNumber || intent.customerPhoneNumber || customerEmailAddress || intent.customerEmailAddress) && !(sendCustomerSms || sendCustomerEmail)">
                        Select a delivery method
                      </span>
                      <span v-else>
                        Enter a phone number or email address
                      </span>
                    </div>
                  </button>
                </form>
              </card>
            </div>

            <div class="col-md-6">
              <div
                class="alert alert-info"
                role="alert"
                v-if="intent.status === 'created'">
                <div class="text-center mb-2">
                  <i class="fa fa-info-circle fa-5x"></i>
                </div>
                Your customer can now make payment via this link:
                <pre
                  class="my-2"><a :href="intent.clientAccessUrl" class="alert-link">{{ intent.clientAccessUrl }}</a></pre>
                <button
                  type="button"
                  class="btn btn-default btn-block"
                  v-clipboard:copy="intent.clientAccessUrl"
                  v-clipboard:success="onCopySuccess"
                  v-clipboard:error="onCopyError">
                  <i class="fa fa-copy mr-2"></i>
                  Copy to clipboard
                </button>

                <hr />
                <p>Or ask your customer to scan this QR code:</p>
                <img
                  :src="intent.clientAccessQrCode"
                  class="img-fluid d-block mx-auto" />
              </div>

              <div
                class="alert alert-success text-center"
                role="alert"
                v-if="intent.status === 'succeeded'">
                <div class="text-center">
                  <i class="fa fa-check-circle fa-5x"></i>
                  <h4 class="mt-2">Payment completed!</h4>
                </div>

                <router-link
                  v-if="intent.saleId"
                  :to="`/transactions/sales/${intent.saleId}`"
                  class="alert-link">
                  View transaction <i class="fa fa-arrow-right ml-2"></i>
                </router-link>
              </div>

              <div
                class="alert alert-danger"
                role="alert"
                v-if="intent.status === 'authorized_pending_capture'">
                <div class="text-center">
                  <i class="fa fa-exclamation-circle fa-5x"></i>
                  <h4 class="mt-2">Pending capture</h4>
                </div>

                <p>This payment is authorized but not yet captured.</p>
                <p>
                  You must capture the payment in order to receive the funds. If
                  you don't, the authorization will be automatically released
                  back to the cardholder by their bank.
                </p>
                <p>
                  It is recommended to capture the payment as soon as possible
                  after authorization, ideally within 24 hours.
                </p>
                <p>
                  This transaction was authorized
                  <time
                    :datetime="intent.authorizedAt"
                    :title="intent.authorizedAt">{{ intent.authorizedAt | moment("from", "now") }}</time>.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="card-footer">
          <button v-on:click="$router.back()" class="btn btn-default">
            <i class="fa fa-arrow-left"></i>&nbsp; Go back
          </button>
        </div>
      </div>
    </div>
    <div v-else>
      <div v-if="loading" class="spinner-container">
        <double-bounce size="60px"></double-bounce>
      </div>
    </div>
  </div>
</template>

<script>
import { DoubleBounce } from "vue-loading-spinner";
import moment from "moment";
import _ from "lodash";

import Card from "src/components/UIComponents/Cards/Card";
import UserFragment from "src/components/Dashboard/Views/Pages/Users/UserFragment.vue";
import ApiKeyFragment from "src/components/Dashboard/Views/Pages/Developers/APIKeyFragment.vue";

import "sweetalert2/dist/sweetalert2.css";

export default {
  name: "IntentPage",
  components: {
    DoubleBounce,
    Card,
    UserFragment,
    ApiKeyFragment,
  },
  props: ["intentId"],
  data() {
    return {
      error: null,
      intent: null,
      loading: false,
      cancelling: false,
      resetting: false,
      deleting: false,
      checking: false,
      capturing: false,
      loadedAt: null,
      sendCustomerSms: false,
      customerPhoneNumber: null,
      editingPhone: false,
      sendCustomerEmail: false,
      customerEmailAddress: null,
      editingEmail: false,
      sendingCustomerNotification: false,
      notificationResent: false,
    };
  },
  watch: {
    customerPhoneNumber(newVal) {
      this.sendCustomerSms = !_.isEmpty(newVal);
    },
    customerEmailAddress(newVal) {
      this.sendCustomerEmail = !_.isEmpty(newVal);
    }
  },
  computed: {
    canCheck() {
      let canCheck = true;
      if (!this.intent) {
        canCheck = false;
      } else if (this.intent.statusIsFinal) {
        canCheck = false;
      } else if (this.checking) {
        canCheck = false;
      }

      return canCheck;
    },
    filteredCustomerDeviceInfo() {
      if (!this.intent) {
        return null;
      }

      if (!this.intent.customerDeviceInfo) {
        return null;
      }

      return _.pickBy(this.intent.customerDeviceInfo, (val, key) => {
        return val !== false && key !== "source" && !_.isEmpty(val);
      });
    }
  },
  beforeMount() {
    if (this.intentId === "create") {
      this.$router.push("/payment-links/create");
    }

    this.loading = true;
    this.axios
      .get(`/v1/intents/${this.intentId}`)
      .then((response) => {
        this.loading = false;
        this.intent = response.data;
        this.loadedAt = moment().toISOString();

        if (this.intent.customerPhoneNumber) {
          this.sendCustomerSms = true;
        }
        if (this.intent.customerEmailAddress) {
          this.sendCustomerEmail = true;
        }
      })
      .catch((error) => {
        this.loading = false;
        this.error = "Failed to load intent";
      });
  },
  mounted() {
    const interval = 1000 * 60 * 1; // 1 minutes
    this.reloadIntent(); // initial check
    const timerId = setInterval(() => {
      // ensure interval is cancelled after page nav
      if (this.$route.name !== "Payment Link Detail") {
        clearInterval(timerId);
      } else {
        this.reloadIntent();
      }
    }, interval);
  },
  methods: {
    formatLog(value) {
      if (!value) {
        return "";
      }

      if (value.charAt(0) === "[" && value.includes("]")) {
        const timestamp = value.split("]")[0].split("[")[1];
        const text = value.split("] ")[1];
        return `<span>${text}</span><br><time class="text-muted small" title="${timestamp}" datetime="${timestamp}">
        ${moment(timestamp).fromNow()}
        </time>`;
      } else {
        // return the full log
        return value;
      }
    },
    sendCustomerNotification() {
      this.editingPhone, this.editingEmail = false;

      this.sendingCustomerNotification = true;
      const requestBody = {
        channels: [],
      };

      if (this.sendCustomerSms) {
        requestBody.channels.push('sms');
      }

      if (this.sendCustomerEmail) {
        requestBody.channels.push('email');
      }

      if (this.customerPhoneNumber) {
        requestBody.customerPhoneNumber = this.customerPhoneNumber;
      }

      if (this.customerEmailAddress) {
        requestBody.customerEmailAddress = this.customerEmailAddress;
      }

      this.axios
        .post(
          `/v1/intents/${this.intentId}/customer-notifications`,
          requestBody
        )
        .then(() => {
          this.sendingCustomerNotification = false;
          this.notificationResent = true;

          this.$notify({
            message: "Notification sent",
            timeout: 3000,
            icon: "fa fa-paper-plane",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "success",
          });
        })
        .catch(() => {
          this.sendingCustomerNotification = false;
          this.notificationResent = false;

          this.$notify({
            message: "Error sending notification",
            timeout: 3000,
            icon: "fa fa-exclamation-triangle",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "error",
          });
        });
    },
    reloadIntent() {
      if (this.editingEmail || this.editingPhone) {
        return false;
      }

      if (
        moment(this.loadedAt).add(30, "seconds").isAfter(moment()) ||
        this.$route.name !== "Payment Link Detail"
      ) {
        // too soon to check
        this.$notify({
          message: "Too soon to check again",
          timeout: 1000,
          icon: "fa fa-exclamation-triangle",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "error",
        });

        return false;
      }

      this.checking = true;
      this.axios
        .get(`/v1/intents/${this.intentId}`)
        .then((response) => {
          this.checking = false;
          this.intent = response.data;
          this.loadedAt = moment().toISOString();
        })
        .catch((error) => {
          this.checking = false;
          this.error = "Failed to load intent";
        });
    },
    cancelIntent() {
      this.cancelling = true;
      this.$swal({
        icon: "warning",
        title: "Cancel payment link?",
        text: "Your customer will not be able to pay using this link.",
        showCancelButton: true,
        preConfirm: (input) => {
          return this.axios
            .put(`/v1/intents/${this.intent.id}/cancellation`)
            .then((response) => {
              this.intent = response.data;
              return response;
            })
            .catch((error) => {
              this.$swal.showValidationMessage(
                `Operation failed: ${error.response.data.message}`
              );
            });
        },
      }).then((result) => {
        this.cancelling = false;
        if (result.value) {
          return this.$swal({
            icon: "success",
            title: "Payment link cancelled",
          });
        }
      });
    },
    captureIntent() {
      this.capturing = true;
      this.$swal({
        icon: "question",
        title: "Capture payment link",
        inputLabel: `Capture amount (max. ${this.intent.amount / 100})`,
        input: "text",
        inputValue: `${this.intent.amount / 100}`,
        text: "Your customer's card will be charged.",
        showCancelButton: true,
        preConfirm: (input) => {
          const body = {};
          body.amount = Math.round(Number(input) * 100); // pounds to integer pence

          return this.axios
            .put(`/v1/intents/${this.intent.id}/authorization/capture`, body)
            .then((response) => {
              this.intent = response.data;
              return response;
            })
            .catch((error) => {
              this.$swal.showValidationMessage(
                `Operation failed: ${error.response.data.message}`
              );
            });
        },
      }).then((result) => {
        this.capturing = false;
        if (result.value) {
          return this.$swal({
            icon: "success",
            title: "Payment captured",
          });
        }
      });
    },
    resetIntent() {
      this.resetting = true;
      this.$swal({
        icon: "info",
        title: "Reset payment link?",
        text: "This can be useful if your customer has encountered issues while attempting payment.",
        showCancelButton: true,
        preConfirm: (input) => {
          return this.axios
            .put(`/v1/intents/${this.intent.id}/reset`)
            .then((response) => {
              this.intent = response.data;
              return response;
            })
            .catch((error) => {
              this.$swal.showValidationMessage(
                `Operation failed: ${error.response.data.message}`
              );
            });
        },
      }).then((result) => {
        this.resetting = false;
        if (result.value) {
          return this.$swal({
            icon: "success",
            title: "Payment link reset",
          });
        }
      });
    },
    deleteIntent() {
      this.deleting = true;
      this.$swal({
        icon: "warning",
        title: "Delete payment link?",
        text: "This action cannot be undone.",
        showCancelButton: true,
        preConfirm: (input) => {
          return this.axios
            .delete(`/v1/intents/${this.intent.id}`)
            .then((response) => {
              // this.intent = response.data;
              return response;
            })
            .catch((error) => {
              this.$swal.showValidationMessage(
                `Operation failed: ${error.response.data.message}`
              );
            });
        },
      }).then((result) => {
        this.deleting = false;
        if (result.value) {
          this.$swal({
            icon: "success",
            title: "Payment link deleted",
          });

          // navigate to payment links list
          this.$router.push(`/payment-links`);

          return null;
        }
      });
    },
    onCopySuccess() {
      this.$notify({
        message: "Copied to clipboard",
        timeout: 1000,
        icon: "fa fa-copy",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: "success",
      });
    },
    onCopyError() {
      this.$notify({
        message: "Could not copy to clipboard",
        timeout: 1000,
        icon: "fa fa-exclamation-triangle",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: "error",
      });
    },
    showPhoneEdit() {
      this.intent.customerPhoneNumber = null;
      this.editingPhone = true;
    },
    showEmailEdit() {
      this.intent.customerEmailAddress = null;
      this.editingEmail = true;
    }
  },
};
</script>
