var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.terminal)?_c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card"},[_c('h2',{staticClass:"card-header"},[_vm._v("Terminal details")]),(_vm.showSubmerchantTerminals)?_c('div',{staticClass:"card-body"},[_c('h5',{staticClass:"card-title"},[_vm._v(_vm._s(_vm.terminal.name))]),_c('h6',{staticClass:"card-subtitle mb-4 text-muted"},[_vm._v(" Terminal ID: "),_c('code',{staticClass:"text-transform-none"},[_vm._v(_vm._s(_vm.terminal.id))])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('table',{staticClass:"table"},[_c('tbody',[_c('tr',[_c('th',[_vm._v("Serial number")]),_c('td',[_c('code',[_vm._v(_vm._s(_vm.terminal.serialNumber))])])]),_c('tr',[_c('th',[_vm._v("Allocated at")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.terminal.currentAllocation.createdAt,"YYYY-MM-DD HH:mm A"))+" ")])]),_c('tr',[_c('th',[_vm._v("Software status")]),_c('td',[(
                            _vm.terminal.reportedConfiguration &&
                            _vm.terminal.reportedConfiguration.isUpToDate
                          )?_c('span',{staticClass:"text-success"},[_c('i',{staticClass:"fa fa-check"}),_vm._v(" Up to date ")]):(
                            _vm.terminal.reportedConfiguration &&
                            !_vm.terminal.reportedConfiguration.isUpToDate
                          )?_c('span',{staticClass:"text-danger"},[_c('i',{staticClass:"fa fa-exclamation-triangle"}),_vm._v(" Out of date ")]):_c('span',{staticClass:"text-warning"},[_c('i',{staticClass:"fa fa-question-circle"}),_vm._v(" Status unknown ")])])]),_c('tr',[_c('th',[_vm._v("Quick Start Guide")]),_c('td',[(
                            _vm.terminal.model === 'bluePad55' ||
                            _vm.terminal.model === 'bluePad55_4g'
                          )?_c('a',{attrs:{"href":"https://cabcard.services/downloads/cabcard-pocket-quick-start-guide.pdf","target":"_blank"}},[_c('i',{staticClass:"fa fa-file-pdf-o"}),_vm._v(" Download PDF ")]):(
                            _vm.terminal.model === 'bluePad5000' ||
                            _vm.terminal.model === 'bluePad5000_v2'
                          )?_c('a',{attrs:{"href":"https://cabcard.services/downloads/cabcard-handheld-quick-start-guide.pdf","target":"_blank"}},[_c('i',{staticClass:"fa fa-file-pdf-o"}),_vm._v(" Download PDF ")]):_vm._e()])])])])]),_c('div',{staticClass:"col-md-6"},[_c('img',{staticClass:"img img-responsive mx-auto d-block mb-5",staticStyle:{"max-height":"250px"},attrs:{"src":("/static/img/terminals/" + _vm.terminalImageFileName)}})])])]):_c('div',{staticClass:"card-body"},[_vm._v("Not permitted to view this data.")]),_c('div',{staticClass:"card-footer"},[_c('button',{staticClass:"btn btn-default",on:{"click":function($event){return _vm.$router.back()}}},[_c('i',{staticClass:"fa fa-arrow-left"}),_vm._v(" Go back ")])])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('terminal-sales-list',{attrs:{"terminal-id":_vm.terminal.id}})],1)])]):_c('div',[(_vm.loading)?_c('div',{staticClass:"spinner-container"},[_c('double-bounce',{attrs:{"size":"60px"}})],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }