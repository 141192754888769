<template>
  <div class="register-page">
    <app-navbar></app-navbar>
    <div class="wrapper wrapper-full-page ">
      <div class="full-page register-page section-image" filter-color="black">
        <div class="content">
          <div class="container">
            <div class="row">
              <div class="col-lg-5 col-md-5 ml-auto">
                <info-section class="mt-5"
                              type="primary"
                              title="Marketing"
                              description="We've created the marketing campaign of the website. It was a very interesting collaboration."
                              icon="nc-icon nc-tv-2">

                </info-section>
                <info-section type="primary"
                              title="Fully Coded in HTML5"
                              description="We've developed the website with HTML5 and CSS3. The client has access to the code using GitHub."
                              icon="nc-icon nc-html5">

                </info-section>

                <info-section type="primary"
                              title="Built Audience"
                              description="There is also a Fully Customizable CMS Admin Dashboard for this product."
                              icon="nc-icon nc-atom">

                </info-section>
              </div>
              <div class="col-lg-4 col-md-6 mr-auto">
                <card type="signup" class="text-center">
                  <template slot="header">
                    <h4 class="card-title">Sign Up</h4>
                  </template>

                  <fg-input v-model="form.firstName"
                            name="firstName"
                            type="text"
                            addon-left-icon="nc-icon nc-single-02"
                            placeholder="First Name (optional)"
                            @keyup.enter="validate"
                            :error="fieldError('firstName')">
                  </fg-input>
                  <fg-input v-model="form.lastName"
                            name="lastName"
                            addon-left-icon="nc-icon nc-circle-10"
                            placeholder="Last Name (optional)"
                            @keyup.enter="validate"
                            :error="fieldError('lastName')">
                  </fg-input>
                  <fg-input v-model="form.emailAddress"
                            name="emailAddress"
                            type="email"
                            data-vv-as="email address"
                            data-vv-delay="1000"
                            v-validate="formValidators.emailAddress"
                            addon-left-icon="nc-icon nc-email-85"
                            placeholder="Email Address"
                            @keyup.enter="validate"
                            :error="fieldError('emailAddress')">
                  </fg-input>
                  <fg-input v-model="form.password"
                            name="password"
                            addon-left-icon="nc-icon nc-key-25"
                            placeholder="Password"
                            data-vv-as="password"
                            data-vv-delay="1000"
                            type="password"
                            v-validate="formValidators.password"
                            @keyup.enter="validate"
                            :error="fieldError('password')">
                  </fg-input>

                  <div class="alert alert-warning" v-if="errorMessage">
                    <span>{{ errorMessage }}</span>
                  </div>

                  <template slot="footer">
                    <p-button v-if="!registering" type="submit" class="btn-block" round @click.prevent="validate">Sign up now &nbsp;<i class="fa fa-arrow-right"></i></p-button>
                    <p-button v-if="registering" type="info" class="btn-block" round disabled><i class="fa fa-spin fa-circle-o-notch"></i>&nbsp; Please wait&hellip;</p-button>
                    <br>
                    <p class="small muted">
                      By creating an account, you agree to our <a href="#todo">Terms of Service</a> and <a href="#todo">Privacy Policy</a>.
                    </p>
                  </template>
                  
                </card>
              </div>
            </div>
          </div>
        </div>
        <app-footer></app-footer>
        <div class="full-page-background" style="background-image: url('/static/img/background/jan-sendereks.jpg') "></div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";

import AppNavbar from "./Layout/AppNavbar";
import AppFooter from "./Layout/AppFooter";
import {
  Card,
  Checkbox,
  Button,
  InfoSection
} from "src/components/UIComponents";

export default {
  components: {
    Card,
    AppNavbar,
    AppFooter,
    InfoSection,
    [Checkbox.name]: Checkbox,
    [Button.name]: Button
  },
  data() {
    return {
      form: {
        firstName: "",
        lastName: "",
        emailAddress: "",
        password: "",
        acceptTerms: true
      },
      formValidators: {
        emailAddress: {
          required: true,
          email: true
        },
        password: {
          required: true,
          min: 8
        }
      },
      validationErrors: {},
      errorMessage: null,
      registering: false
    };
  },
  methods: {
    toggleNavbar() {
      document.body.classList.toggle("nav-open");
    },
    closeMenu() {
      document.body.classList.remove("nav-open");
      document.body.classList.remove("off-canvas-sidebar");
    },
    validate() {
      this.$validator.validateAll().then(isValid => {
        this.$emit("on-submit", this.registerForm, isValid);
        if (isValid) {
          this.registerUser();
        }
      });
    },
    fieldError(fieldName) {
      // clientside
      if (this.errors.first(fieldName)) {
        return this.errors.first(fieldName);
      }

      // serverside
      const fieldError = _.find(this.validationErrors, item => {
        return _.includes(item.field, fieldName);
      });

      if (!fieldError) {
        return false;
      } else if (fieldError.messages) {
        return fieldError.messages;
      } else {
        return true;
      }
    },
    registerUser() {
      this.registering = true;

      this.$auth.register({
        data: {
          emailAddress: this.form.emailAddress,
          password: this.form.password,
          firstName: this.form.firstName,
          lastName: this.form.lastName
        },
        redirect: false,
        success: response => {
          // now log the user in
          this.$auth.login({
            data: {
              grantType: "password",
              emailAddress: this.form.emailAddress,
              password: this.form.password
            },
            success: () => {
              this.registering = false;
            },
            redirect: "/?welcome=true"
          });
        },
        error: error => {
          this.registering = false;
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            if (error.response.data.message) {
              this.errorMessage = error.response.data.message;

              if (error.response.status === 400 && error.response.data.errors) {
                this.validationErrors = error.response.data.errors;
              }
            } else {
              this.errorMessage = "Sorry, an unknown error occurred.";
            }
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest
            this.errorMessage = "Error - no response from server.";
          } else {
            // Something happened in setting up the request that triggered an Error
            this.errorMessage = "Error - request not sent";
          }
        },
        autoLogin: false,
        rememberMe: false
      });
    }
  },
  beforeDestroy() {
    this.closeMenu();
  }
};
</script>
<style>
</style>
