<!-- 
***SET UP***

1. When registering component ensure 'activeFilters' is passed as prop.
2. Add 'remove-filter' event listener to <active-filter-list> and trigger a
removeFilter function which triggers when the user clicks an active filter.
This will then need to fire off another event using the vue-tables-2 Event
bus to remove the relevant filter. The filter is removed from activeFilters
within this component.
3. The server table will need v-on:filter="addToActiveFilters" to be added
as an attribute.
4. Define an addToActiveFilters method taking the filter as an argument.
This needs to check if the filter is already in activeFilters
and that the filter.value is not an empty string.
5. Define a clearFilters method for the @clear-filters event.
6. To remember active filters on refresh or page change, a loadActiveFilters()
method is required which can be called in mounted(). To retrieve table data
call .getRequestParams() on the ref of the ServerTable. 
E.g. const tableData = this.$refs[this.tableName].getRequestParams();
Then add the queries which don't have a value of empty string to activeFilters.
-->


<template>
  <div v-if="activeFilters.length !== 0" class="active-filter-container" :id="containerId">
    <span :class="filterTextClass">Filtering {{ activeFilters.length }} {{ activeFilters.length |
      pluralize('field')
    }}: </span>
    <span id="filter-tag" v-for="filter in activeFilters" @click="removeFilter(filter)" :class="pillClassName">
      {{ filter.name | humanize }}
      <i class="fa fa-times" aria-hidden="true"></i>
    </span>
    <button id="clear-all-btn" @click="clearFilters" :class="buttonClassName">
      Clear All
      <i class="fa fa-eraser ml-2"></i>
    </button>
  </div>
</template>

<script>
import pluralize from 'pluralize';


export default {
  name: 'active-filter-list',
  props: {
    activeFilters: {
      type: Array,
      description: 'Array containing active filters on table',
      required: true
    },
    tableName: {
      type: String,
      description: 'name of vue-tables-2 ServerTable component',
      required: true
    },
    pillClassName: {
      type: String,
      description: 'Changes style for active filter items',
      default: 'badge badge-pill badge-warning'
    },
    buttonClassName: {
      type: String,
      description: 'Changes style for "Clear All" button',
      default: 'badge badge-pill badge-primary'
    },
    containerId: {
      type: String,
      description: '',
      default: 'active-filters'
    },
    filterTextClass: {
      type: String,
      description: 'use filter-text-italic to change text to italic',
      default: 'filter-text-standard'
    }
  },
  emits: ['remove-filter'],
  methods: {
    removeFilter(filter) {
      const index = this.activeFilters.indexOf(filter);
      this.activeFilters.splice(index, 1);
      this.$emit('remove-filter', filter);
    },
    clearFilters() {
      this.$emit('clear-filters');
    }
  }
}

</script>

<style scoped>
.active-filter-container {
  font-size: 15px;
}

button {
  font-size: 15px;
  border: none;
}

button:hover {
  background-color: rgb(52, 209, 236)
}

#filter-tag {
  margin-right: 4px;
  cursor: pointer;
  font-size: 15px;
}

#filter-tag:hover {
  background-color: rgb(252, 173, 2);
}

#clear-all-btn {
  cursor: pointer;
}

.filter-text-italic {
  font-style: italic;
}
</style>