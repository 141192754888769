<template>
  <div class="card">
    <div class="card-header">
      <div class="row">
        <div class="col-sm-8">
          <h4 class="title">Endpoint Notification History</h4>
        </div>

        <div class="col-sm-4 text-right">
          <button
            class="btn btn-default"
            @click="refreshTable"
            title="Refresh data"
            :disabled="refreshing"
          >
            <span v-if="!refreshing"
              ><i class="fa fa-refresh mr-2"></i> Refresh</span
            >
            <span v-else
              ><i class="fa fa-spin fa-refresh mr-2"></i> Loading&hellip;</span
            >
          </button>
        </div>
      </div>
    </div>
    <div class="card-body">
      <h6 class="card-subtitle mb-4 text-muted">
        Endpoint ID:
        <code class="text-transform-none">{{ endpointId }}</code>
      </h6>
      <v-server-table
        :url="`/v1/webhook-endpoints/${endpointId}/notifications`"
        :columns="columns"
        :options="options"
        ref="notificationsTable"
        name="notificationsTable"
        @loading="onTableLoading"
        @loaded="onTableLoaded"
      >
        <template slot="id" slot-scope="props">
          <code>{{ props.row.id }}</code>
        </template>

        <template slot="hasSucceeded" slot-scope="props">
          <span v-if="props.row.hasSucceeded" class="text-success"
            ><i class="fa fa-check"></i
          ></span>
          <span v-else class="text-danger"><i class="fa fa-times"></i></span>
        </template>

        <template slot="attemptCount" slot-scope="props">
          {{ props.row.attemptCount }}
        </template>

        <template slot="createdAt" slot-scope="props">
          <time datetime="props.row.createdAt">{{
            props.row.createdAt | moment("from", "now")
          }}</time>
        </template>

        <template slot="links" slot-scope="props">
          <button
            class="btn btn-default btn-sm"
            @click="props.row.displayAttemptsModal = true"
          >
            View attempts
          </button>

          <modal
            :show.sync="props.row.displayAttemptsModal"
            class="modal-primary"
            :show-close="true"
            header-classes="justify-content-center"
            modal-classes="modal-lg"
            @modal-open="loadAttempts(props.row)"
          >
            <div slot="header" class>
              <i class="fa fa-history mr-2"></i> Notification Attempts
              <span v-if="props.row.attempts.length && props.row.totalAttempts">
                ({{ props.row.attempts.length }} of
                {{ props.row.totalAttempts }})
              </span>
              <span v-if="props.row.attemptsLoading"
                >-
                <i class="fa fa-spin fa-spinner mr-2"></i>loading&hellip;</span
              >
            </div>

            <div class="list-group list-group-flush">
              <div
                v-for="(attempt, index) in props.row.attempts"
                :key="index"
                class="list-group-item table-responsive"
              >
                <h4>Attempt #{{ index + 1 }}</h4>
                <table class="table">
                  <tbody>
                    <tr>
                      <th>ID</th>
                      <td>
                        <code>{{ attempt.id }}</code>
                      </td>
                    </tr>
                    <tr>
                      <th>Date</th>
                      <td>
                        <time :datetime="attempt.createdAt">{{
                          attempt.createdAt | moment("YYYY-MM-DD HH:mm:ss")
                        }}</time>
                      </td>
                    </tr>
                    <tr>
                      <th>Request URL</th>
                      <td>
                        <code>{{ attempt.requestUrl }}</code>
                      </td>
                    </tr>
                    <tr>
                      <th>Request Headers</th>
                      <td>
                        <button
                          class="btn btn-default btn-sm"
                          data-toggle="collapse"
                          :data-target="`#attempt-${attempt.id}-reqheaders`"
                        >
                          Show / Hide
                        </button>
                        <button
                          type="button"
                          class="btn btn-default btn-sm ml-2"
                          v-clipboard:copy="attempt.requestHeaders"
                          v-clipboard:success="onCopySuccess"
                          v-clipboard:error="onCopyError"
                        >
                          <i class="fa fa-copy mr-2"></i>
                          Copy to clipboard
                        </button>
                        <pre
                          class="collapse mt-3 pre-scrollable"
                          :id="`attempt-${attempt.id}-reqheaders`"
                          >{{ attempt.requestHeaders }}</pre
                        >
                      </td>
                    </tr>
                    <tr>
                      <th>Request Body</th>
                      <td>
                        <button
                          class="btn btn-default btn-sm"
                          data-toggle="collapse"
                          :data-target="`#attempt-${attempt.id}-reqbody`"
                        >
                          Show / Hide
                        </button>
                        <button
                          type="button"
                          class="btn btn-default btn-sm ml-2"
                          v-clipboard:copy="attempt.requestBody"
                          v-clipboard:success="onCopySuccess"
                          v-clipboard:error="onCopyError"
                        >
                          <i class="fa fa-copy mr-2"></i>
                          Copy to clipboard
                        </button>
                        <pre
                          class="collapse mt-3 pre-scrollable"
                          :id="`attempt-${attempt.id}-reqbody`"
                          >{{ attempt.requestBody }}</pre
                        >
                      </td>
                    </tr>
                    <tr>
                      <th>Response Status Code</th>
                      <td>
                        <code>{{ attempt.responseStatusCode }}</code>
                      </td>
                    </tr>
                    <tr>
                      <th>Response Headers</th>
                      <td>
                        <button
                          class="btn btn-default btn-sm"
                          data-toggle="collapse"
                          :data-target="`#attempt-${attempt.id}-resheaders`"
                        >
                          Show / Hide
                        </button>
                        <button
                          type="button"
                          class="btn btn-default btn-sm ml-2"
                          v-clipboard:copy="attempt.responseHeaders"
                          v-clipboard:success="onCopySuccess"
                          v-clipboard:error="onCopyError"
                        >
                          <i class="fa fa-copy mr-2"></i>
                          Copy to clipboard
                        </button>
                        <pre
                          class="collapse mt-3 pre-scrollable"
                          :id="`attempt-${attempt.id}-resheaders`"
                          >{{ attempt.responseHeaders }}</pre
                        >
                      </td>
                    </tr>
                    <tr>
                      <th>Response Body</th>
                      <td>
                        <button
                          class="btn btn-default btn-sm"
                          data-toggle="collapse"
                          :data-target="`#attempt-${attempt.id}-resbody`"
                        >
                          Show / Hide
                        </button>
                        <button
                          type="button"
                          class="btn btn-default btn-sm ml-2"
                          v-clipboard:copy="attempt.responseBody"
                          v-clipboard:success="onCopySuccess"
                          v-clipboard:error="onCopyError"
                        >
                          <i class="fa fa-copy mr-2"></i>
                          Copy to clipboard
                        </button>
                        <pre
                          class="collapse mt-3 pre-scrollable"
                          :id="`attempt-${attempt.id}-resbody`"
                          >{{ attempt.responseBody }}</pre
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="text-center mt-3" v-if="props.row.totalAttempts">
              <button
                class="btn btn-default"
                v-if="props.row.totalAttempts > props.row.attempts.length"
                @click="loadAttempts(props.row)"
                :disabled="props.row.attemptsLoading"
              >
                Load more attempts
              </button>
            </div>

            <template slot="footer">
              <div class="left-side">
                <p-button
                  type="default"
                  link
                  @click="props.row.displayAttemptsModal = false"
                >
                  Close &nbsp;
                  <i class="fa fa-times"></i>
                </p-button>
              </div>
              <div class="divider"></div>
            </template>
          </modal>
        </template>
      </v-server-table>
    </div>

    <div class="card-footer">
      <button v-on:click="$router.back()" class="btn btn-default">
        <i class="fa fa-arrow-left"></i>&nbsp; Go back
      </button>
    </div>
  </div>
</template>
<script>
import _ from "lodash";

import { requestAdapter } from "../../../../helpers/tableHelpers";
import { Button, Modal } from "src/components/UIComponents";

const columns = ["id", "hasSucceeded", "attemptCount", "createdAt", "links"];

export default {
  name: "EndpointNotificationsList",
  components: {
    [Button.name]: Button,
    Modal,
  },
  computed: {
    submerchant: function () {
      return this.$store.state.submerchant;
    },
  },
  props: {
    endpointId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      refreshing: false,
      columns,
      options: {
        columnsDropdown: false,
        headings: {
          id: "Notification ID",
          hasSucceeded: "Succeeded",
          attemptCount: "No. Attempts",
          createdAt: "Date",
          links: "",
        },
        sortable: _.initial(columns),
        filterable: ["hasSucceeded", "id"],
        requestAdapter,
        responseAdapter: (resp) => {
          let count = resp.data.length;
          if (resp.headers["total-count"]) {
            count = parseInt(resp.headers["total-count"]);
          }

          // set a "displayAttemptsModal" prop to each row so that we can trigger a modal
          const data = _.map(resp.data, (item) => {
            item.displayAttemptsModal = false;
            item.attemptsLoading = false;
            item.totalAttempts = null;
            item.attempts = [];
            return item;
          });

          return {
            data,
            count,
          };
        },
      },
    };
  },
  methods: {
    refreshTable() {
      this.$refs.notificationsTable.refresh();
    },
    onTableLoading() {
      this.refreshing = true;
    },
    onTableLoaded() {
      this.refreshing = false;
    },
    loadAttempts(notification) {
      if (
        notification.totalAttempts &&
        notification.attempts.length >= notification.totalAttempts
      ) {
        // nothing to load - don't try it
        return true;
      }

      notification.attemptsLoading = true;

      this.axios
        .get(
          `/v1/webhook-endpoints/${this.endpointId}/notifications/${notification.id}/attempts?&offset=${notification.attempts.length}&limit=5&sort=createdAt`
        )
        .then((response) => {
          notification.attemptsLoading = false;

          notification.attempts = notification.attempts.concat(response.data);
          // set notification.totalAttempts from res header total-count
          if (response.headers["total-count"]) {
            notification.totalAttempts = parseInt(
              response.headers["total-count"]
            );
          }
        })
        .catch((err) => {
          notification.attemptsLoading = false;
          console.error("err: ", err);
        });
    },
    onCopySuccess() {
      this.$notify({
        message: "Copied to clipboard",
        timeout: 1000,
        icon: "fa fa-copy",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: "success",
      });
    },
    onCopyError() {
      this.$notify({
        message: "Could not copy to clipboard",
        timeout: 1000,
        icon: "fa fa-exclamation-triangle",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: "error",
      });
    },
  },
};
</script>
