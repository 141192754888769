// import _ from "lodash"; // this does not work

function requestAdapter(input) {
  const output = {};

  const itemsPerPage = input.limit;
  const page = input.page;
  const offset = (page - 1) * itemsPerPage;

  // sort
  if (input.orderBy && input.orderBy !== "") {
    let sort = "";
    if (input.ascending === 0) {
      sort = "-"; // desc
    }
    sort += input.orderBy;
    output.sort = sort;
  }

  // paginate (limit and offset)
  output.limit = input.limit;
  output.offset = offset;


  // filter
  _.each(input.query, (val, key) => {
    if (val && val !== "") {
      output[key] = val;
    }
  });

  if (input.capturedAt) {
    output['capturedAt[$gte]'] = new Date(input.capturedAt.start).toISOString()
    output['capturedAt[$lt]'] = new Date(input.capturedAt.end).toISOString()
  }

  if (input.createdAt) {
    output['createdAt[$gte]'] = new Date(input.createdAt.start).toISOString()
    output['createdAt[$lt]'] = new Date(input.createdAt.end).toISOString()
  }


  return output;
}

module.exports = {
  requestAdapter,
};
