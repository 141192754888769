<template>
  <navbar :show-navbar="showNavbar">
    <div class="navbar-wrapper">
      <div class="navbar-minimize">
        <button
          id="minimizeSidebar"
          class="btn btn-icon btn-round"
          @click="minimizeSidebar"
        >
          <i
            class="nc-icon nc-minimal-right text-center visible-on-sidebar-mini"
          ></i>
          <i
            class="nc-icon nc-minimal-left text-center visible-on-sidebar-regular"
          ></i>
        </button>
      </div>
      <div class="navbar-toggle">
        <navbar-toggle-button
          @click.native="toggleSidebar"
        ></navbar-toggle-button>
      </div>

      <span v-if="submerchant" class="navbar-brand">{{
        submerchant.name
      }}</span>
    </div>

    <template slot="navbar-menu">
      <!-- <form>
        <div class="input-group no-border">
          <input type="text" value class="form-control" placeholder="Search...">
          <div class="input-group-append">
            <div class="input-group-text">
              <i class="nc-icon nc-zoom-split"></i>
            </div>
          </div>
        </div>
      </form>-->
      <ul class="navbar-nav">
        <li class="nav-item">
          <a
            class="nav-link sp-status"
            target="_blank"
            href="https://status.cabcard.services"
            title="System status"
          >
            <el-tooltip
              v-if="!statusPageResult"
              content="System status: not checked"
              placement="bottom"
            >
              <i class="fa fa-circle"></i>
            </el-tooltip>
            <el-tooltip
              v-else-if="statusPageResult === 'ok'"
              content="System status: OK"
              placement="bottom"
            >
              <i class="fa fa-check text-success"></i>
            </el-tooltip>
            <el-tooltip
              v-else-if="statusPageResult === 'minor'"
              content="System status: minor incident"
              placement="bottom"
            >
              <i class="fa fa-exclamation-triangle text-warning"></i>
            </el-tooltip>
            <el-tooltip
              v-else-if="statusPageResult === 'major'"
              content="System status: major incident"
              placement="bottom"
            >
              <i class="fa fa-exclamation-triangle text-danger"></i>
            </el-tooltip>
          </a>
        </li>
        <drop-down
          icon="nc-icon nc-bell-55"
          tag="li"
          position="right"
          direction="none"
          class="nav-item btn-rotate dropdown"
        >
          <li class="dropdown-item no-rotate">
            <i class="fa fa-check"></i>&nbsp; No new notifications
          </li>
        </drop-down>

        <drop-down
          icon="nc-icon nc-settings-gear-65"
          tag="li"
          position="right"
          direction="none"
          class="nav-item btn-rotate dropdown"
        >
          <!-- <router-link to="/account/edit" class="dropdown-item no-rotate">
            <i class="fa fa-building"></i>&nbsp; Account settings
          </router-link>-->
          <router-link to="/select-account" class="dropdown-item no-rotate">
            <i class="fa fa-random"></i>&nbsp; Switch account
          </router-link>
          <!-- <router-link to="/user/edit" class="dropdown-item no-rotate">
            <i class="fa fa-user"></i>&nbsp; Edit profile
          </router-link>-->
          <a class="dropdown-item no-rotate" href="#" @click="logout">
            <i class="fa fa-sign-out"></i>&nbsp; Logout
          </a>
        </drop-down>
      </ul>
    </template>
  </navbar>
</template>
<script>
import { Navbar, NavbarToggleButton } from "src/components/UIComponents";
import { get } from "dot-prop";
import { Tooltip } from "element-ui";

export default {
  components: {
    Navbar,
    NavbarToggleButton,
    [Tooltip.name]: Tooltip,
  },
  props: {
    submerchant: {
      type: Object,
    },
  },
  data() {
    return {
      activeNotifications: false,
      showNavbar: false,
      statusPageResult: null,
    };
  },
  mounted() {
    const interval = 1000 * 60 * 5; // 5 minutes
    this.checkSystemStatus(); // initial check
    setInterval(() => {
      this.checkSystemStatus();
    }, interval);
  },
  methods: {
    checkSystemStatus() {
      this.axios
        .request({
          url:
            "https://statuspal.io/api/v1/status_pages/cabcard-services/status",
          method: "get",
          withCredentials: false,
          headers: {},
        })
        .then((response) => {
          const currentIncidentType = get(
            response,
            "data.status_page.current_incident_type"
          );
          if (!currentIncidentType) {
            this.statusPageResult = "ok";
          } else {
            this.statusPageResult = currentIncidentType;
          }
        })
        .catch((err) => {
          console.error("Error checking system status: ", err);
        });
    },
    logout() {
      this.$store.dispatch("logout");
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    minimizeSidebar() {
      this.$sidebar.toggleMinimize();
    },
    toggleNavbar() {
      this.showNavbar = !this.showNavbar;
    },
  },
};
</script>
<style></style>
