<template>
  <footer class="footer footer-black footer-white d-none d-lg-block">
    <div class="container-fluid">
      <div class="row">
        <nav class="footer-nav">
          <ul>
            <li>
              <a href="https://cabcard.services">CabCard Services</a>
            </li>
            <li>
              <a href="https://cabcard.services/support">Support</a>
            </li>
          </ul>
        </nav>
        <div class="credits ml-auto">
          <div class="copyright pull-right">
            &copy; {{ year }} CabCard Services Limited |
            <span>v2309131457</span>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: "main-footer",
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>
<style></style>
