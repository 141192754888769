<template>
  <div class="login-page">
    <app-navbar></app-navbar>
    <div class="wrapper wrapper-full-page">
      <div class="full-page login-page">
        <!--   you can change the color of the filter page using: data-color="blue | azure | green | orange | red | purple" -->
        <div class="content">
          <div class="container">
            <div class="col-lg-4 col-md-6 ml-auto mr-auto">
              <card type="login">
                <h3 slot="header" class="header text-center">Reset password</h3>

                <div class="alert alert-danger" v-if="!ticketId || !token">
                  <span
                    >Unable to validate password reset ticket - please try
                    again.</span
                  >
                </div>

                <div v-else>
                  <fg-input
                    v-if="!success"
                    v-model="form.password"
                    name="password"
                    v-validate="formValidators.password"
                    data-vv-delay="1000"
                    :error="fieldError('password')"
                    addon-left-icon="nc-icon nc-key-25"
                    @keyup.enter="validate"
                    placeholder="Password"
                    type="password"
                  ></fg-input>

                  <br />

                  <div
                    class="alert alert-warning"
                    v-if="errorMessage && !success"
                  >
                    <span>{{ errorMessage }}</span>
                  </div>
                </div>

                <div class="alert alert-success" v-if="success">
                  <span
                    >Your password has been reset successfully. You may now log
                    in using your new password.</span
                  >
                  <br />
                  <router-link to="/login" class="btn btn-block btn-default"
                    >Log in now</router-link
                  >
                </div>

                <template slot="footer">
                  <p-button
                    v-if="!working && !success"
                    type="submit"
                    round
                    block
                    class="mb-3"
                    @click.prevent="validate"
                  >
                    Reset &nbsp;
                    <i class="fa fa-arrow-right"></i>
                  </p-button>
                  <p-button
                    v-if="working && !success"
                    type="info"
                    round
                    block
                    class="mb-3"
                    disabled
                  >
                    <i class="fa fa-spin fa-circle-o-notch"></i>&nbsp; Please
                    wait&hellip;
                  </p-button>
                </template>
              </card>
            </div>
          </div>
        </div>
        <app-footer></app-footer>
        <div
          class="full-page-background"
          style="background-image: url('/static/img/background/yellow-bg.svg')"
        ></div>
      </div>
    </div>
  </div>
</template>
<script>
import { get } from "dot-prop";

import { Card, Checkbox, Button } from "src/components/UIComponents";
import AppNavbar from "./Layout/AppNavbar";
import AppFooter from "./Layout/AppFooter";
export default {
  components: {
    Card,
    AppNavbar,
    AppFooter,
    [Checkbox.name]: Checkbox,
    [Button.name]: Button,
  },
  methods: {
    toggleNavbar() {
      document.body.classList.toggle("nav-open");
    },
    closeMenu() {
      document.body.classList.remove("nav-open");
      document.body.classList.remove("off-canvas-sidebar");
    },
    validate() {
      this.$validator.validateAll().then((isValid) => {
        this.$emit("on-submit", this.registerForm, isValid);
        if (isValid) {
          this.completeTicket();
        }
      });
    },
    fieldError(fieldName) {
      // clientside
      if (this.errors.first(fieldName)) {
        return this.errors.first(fieldName);
      }
    },
    completeTicket() {
      this.working = true;

      const requestBody = {
        verificationToken: this.token,
        newPassword: this.form.password,
      };

      this.axios
        .put(
          `/v1/password-reset-tickets/${this.ticketId}/completion`,
          requestBody
        )
        .then((response) => {
          this.working = false;
          this.success = true;
        })
        .catch((error) => {
          this.working = false;
          this.errorMessage = "Failed to reset password";
          if (get(error, "response.data.message")) {
            this.errorMessage += `: ${get(error, "response.data.message")}`;
          }

          // validation error looks like this:
          // {
          //     "message": "Validation failed",
          //     "errors": [
          //         {
          //             "field": [
          //                 "newPassword"
          //             ],
          //             "location": "body",
          //             "messages": [
          //                 "\"newPassword\" length must be at least 8 characters long"
          //             ],
          //             "types": [
          //                 "string.min"
          //             ]
          //         }
          //     ]
          // }

          if (get(error, "response.data.errors")) {
            // add each error message to the error message
            error.response.data.errors.forEach((item) => {
              this.errorMessage += item.messages.join("; ");
            });
          }
        });
    },
  },
  data() {
    return {
      form: {
        password: "",
      },
      formValidators: {
        password: {
          required: true,
          min: 8,
        },
      },
      ticketId: null,
      token: null,
      validationErrors: {},
      errorMessage: null,
      working: false,
      success: false,
    };
  },
  mounted() {
    // get ticket ID and token from URL query
    this.token = this.$route.query.token;
    this.ticketId = this.$route.query.ticketId;
  },
  beforeDestroy() {
    this.closeMenu();
  },
};
</script>
<style></style>
