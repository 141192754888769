<template>
  <FinaroSelfEnrollment v-if="showSelfEnrollment" @enrollment-complete="setUp()"></FinaroSelfEnrollment>
  <div v-else class="card">
    <div class="card-header">
      <div class="row">
        <div class="col-md-4">
          <h4 class="title">Payment links</h4>
        </div>
        <div class="col-md-8">
          <div class="row">
            <div class="col-12">
              <!-- Search  -->
              <v-select @search="onSearch" :filterable="false" :options="searchResults"
                @option:selected="chooseSearchResult" label="id"
                placeholder="Search by customer name, email, reference etc.">
                <!-- Option -->
                <template v-slot:option="intent">
                  <code>{{ intent.id }}</code>
                  <time class="ml-1 text-monospace" :datetime="intent.createdAt">{{ intent.createdAt |
                    moment("YYYY-MM-DDHH: mm A") }}</time>
                  <code v-if="intent.reference" class="ml-1">
                        {{ intent.reference }}
                  </code>
                  <span v-if="intent.customerName" class="ml-1">
                    {{ intent.customerName }}
                  </span>
                </template>
                <!-- No results -->
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    No results found for <em>{{ search }}</em>.
                  </template>
                  <em v-else style="opacity: 0.5">Try customer's name, email address or reference.</em>
                </template>
              </v-select>
            </div>
          </div>
          <div class="row">
            <div class="col-12 text-right">
              <div class="btn-group d-flex w-100" role="group">
                <button class="btn btn-default w-100" @click="openBulkActionsModal" title="Bulk actions"
                  :disabled="bulkActionsModalIsOpen">
                  <i class="fa fa-bolt mr-2"></i> Bulk actions
                </button>
                <router-link :to="`/payment-links/create`" class="btn btn-success w-100">
                  Send a payment link &nbsp;
                  <i class="fa fa-chevron-right"></i>
                </router-link>
                <button class="btn btn-default w-100" @click="refreshTable" title="Refresh data" :disabled="refreshing">
                  <span v-if="!refreshing"><i class="fa fa-refresh mr-2"></i> Refresh</span>
                  <span v-else><i class="fa fa-spin fa-refresh mr-2"></i>
                    Loading&hellip;</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body">

      <active-filter-list :activeFilters="activeFilters" :tableName="tableName" @remove-filter="removeFilter"
        @clear-filters="clearFilters"></active-filter-list>

      <v-server-table :url="`/v1/intents?submerchantId=${submerchant.id}`" :columns="columns" :options="options"
        ref="intentsTable" name="intentsTable" @loading="onTableLoading" @loaded="onTableLoaded"
        v-on:filter="addToActiveFilters">
        <template slot="id" slot-scope="props">
          <code>{{ props.row.id }}</code>
        </template>

        <template slot="reference" slot-scope="props">
          <code>{{ props.row.reference }}</code>
        </template>

        <template slot="amount" slot-scope="props">
          <span>{{ props.row.amount | money(props.row.currency) }}</span>
        </template>

        <template slot="status" slot-scope="props">
          {{ props.row.status | humanize }}
        </template>

        <template slot="createdAt" slot-scope="props">
          <time :datetime="props.row.createdAt">{{
            props.row.createdAt | moment("YYYY-MM-DD")
          }}</time>
        </template>

        <div slot="filter__createdAt">
          <date-range-picker class="picker" ref="picker" :opens="opens" v-model="createdAtDateRange"
            @update="updateValues($event, 'createdAt')" style="z-index: 1; position:inherit;">
            <template class="picker-dates" v-slot:input="picker" style="min-width: 350px;">
              {{ picker.startDate ? `${picker.startDate.toISOString().split('T')[0]} -` : "Select date range" }}
              {{ picker.endDate ? picker.endDate.toISOString().split('T')[0] : "" }}
            </template>
          </date-range-picker>
        </div>


        <template slot="succeededAt" slot-scope="props">
          <time v-if="props.row.succeededAt" :datetime="props.row.succeededAt">{{ props.row.succeededAt |
            moment("YYYY-MM-DD") }}</time>
        </template>

        <div slot="filter__succeededAt">
          <date-range-picker class="picker" ref="picker" :opens="opensLeft" v-model="succeededAtDateRange"
            @update="updateValues($event, 'succeededAt')" style="z-index: 1; position:inherit;">
            <template class="picker-dates" v-slot:input="picker" style="min-width: 350px;">
              {{ picker.startDate ? `${picker.startDate.toISOString().split('T')[0]} -` : "Select date range" }}
              {{ picker.endDate ? picker.endDate.toISOString().split('T')[0] : "" }}
            </template>
          </date-range-picker>
        </div>

        <template slot="links" slot-scope="props">
          <router-link :to="`/payment-links/${props.row.id}`" class="btn btn-default btn-sm">
            View &nbsp;
            <i class="fa fa-chevron-right"></i>
          </router-link>
        </template>
      </v-server-table>
    </div>
    <div class="card-footer text-right">
      <router-link :to="`/payment-links/bulk-create`" class="btn btn-default mr-2">
        Bulk create payment links &nbsp;
        <i class="fa fa-list-alt"></i>
      </router-link>
      <router-link :to="`/payment-links/create`" class="btn btn-success">
        Send a payment link &nbsp;
        <i class="fa fa-chevron-right"></i>
      </router-link>
    </div>

    <modal :show.sync="bulkActionsModalIsOpen" class="modal-primary" :show-close="true"
      headerClasses="justify-content-center">
      <div slot="header" class>
        <i class="fa fa-download mr-2"></i> Bulk actions
      </div>

      <select v-model="bulkAction" class="form-control" v-if="!bulkActionRunning && !bulkActionResults.length">
        <option value="resend">Resend to customer</option>
        <option value="cancel">Cancel</option>
        <option value="delete">Delete</option>
      </select>

      <div class="list-group" v-if="bulkActionResults.length">
        <div class="list-group-item" v-for="result in bulkActionResults" :key="result.id">
          <div class="row">
            <div class="col-md-4">
              <code>{{ result.id }}</code>
            </div>
            <div class="col-md-8 text-right">
              <span v-if="result.working"><i class="fa fa-spin fa-spinner"></i></span>
              <span v-if="result.success" class="text-success"><i class="fa fa-check"></i></span>
              <span v-if="result.error" class="text-warning">
                {{ result.error }}
                <i class="fa fa-exclamation-triangle"></i></span>
            </div>
          </div>
        </div>
      </div>

      <div class="alert alert-warning" v-if="bulkActionError">
        Error: {{ bulkActionError }}
      </div>

      <!-- TODO: have a pre-run and a post-run footer -->
      <template slot="footer">
        <div class="left-side">
          <p-button type="default" link @click="bulkActionsModalIsOpen = false">
            Cancel &nbsp;
            <i class="fa fa-times"></i>
          </p-button>
        </div>
        <div class="divider"></div>
        <div class="right-side">
          <p-button v-if="bulkActionRunning || !bulkActionResults.length" type="primary" link
            :disabled="bulkActionRunning" @click="runBulkAction()">
            <span v-if="!bulkActionRunning">
              {{ bulkAction }}
              {{ bulkActionRows.length }}
              payment links
              <i class="fa fa-arrow-right ml-2"></i>
            </span>
            <span v-if="bulkActionRunning">
              Running
              <i class="ml-2 fa fa-spin fa-circle-o-notch"></i>
            </span>
          </p-button>

          <p-button v-else type="primary" link @click="closeBulkActionsModal">
            <span>
              Close
              <i class="ml-2 fa fa-check"></i>
            </span>
          </p-button>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import _ from "lodash";
import swal from "sweetalert2";
import { eachLimit } from "async-es";
import { get } from "dot-prop";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

import { Button, Modal } from "src/components/UIComponents";
import ActiveFilterList from "../../../../UIComponents/ActiveFilterList.vue";
import FinaroSelfEnrollment from "./FinaroSelfEnrollment.vue";

// daterangepicker dependencies
import jquery from 'jquery';
import moment from 'moment';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import { Event } from 'vue-tables-2-premium'

window.$ = jquery;
window.moment = moment;

const columns = [
  "id",
  "amount",
  "reference",
  "createdAt",
  "succeededAt",
  "status",
  "links",
];

export default {
  name: "IntentsList",
  components: {
    [Button.name]: Button,
    Modal,
    vSelect,
    DateRangePicker,
    ActiveFilterList,
    FinaroSelfEnrollment
  },
  computed: {
    submerchant: function () {
      return this.$store.state.submerchant;
    },
    showSelfEnrollment() {
      return (this.submerchant && !this.submerchant.finaroId);
    },
  },
  data() {
    return {
      refreshing: false,
      // search
      searchResults: [],
      // bulk actions
      bulkActionsModalIsOpen: false,
      bulkActionRunning: false,
      bulkActionError: null,
      bulkActionRows: [],
      bulkActionResults: [],
      bulkAction: "cancel",
      // table daterangepicker
      createdAtDateRange: {
        startDate: null,
        endDate: null
      },
      succeededAtDateRange: {
        startDate: null,
        endDate: null
      },
      opens: 'center',
      opensLeft: 'left',
      // vue-tables-2
      columns,
      activeFilters: [],
      tableName: 'intentsTable',
      options: {
        columnsDropdown: false,
        columnsDisplay: {
          id: "min_tabletL",
          amount: "min_mobile",
          reference: "min_tabletL",
          createdAt: "min_desktop",
          succeededAt: "min_desktop",
          status: "min_mobileP",
          links: "min_mobile",
        },
        headings: {
          id: "ID",
          amount: "Amount",
          reference: "Reference",
          createdAt: "Date",
          succeededAt: "Completed",
          status: "Status",
          links: "",
        },
        sortable: _.initial(columns),
        filterable: ["reference", "amount", "status"],
        selectable: {
          mode: "multipe", // or 'single'
          only: function (row) {
            return true; // any condition
          },
          selectAllMode: "page", // or 'all',
          programmatic: false,
        },
        customFilters: ['createdAt', 'succeededAt'],
        listColumns: {
          status: [
            {
              id: "succeeded",
              text: "Succeeded",
            },
            {
              id: "created",
              text: "Created",
            },
            {
              id: "pending",
              text: "Pending",
            },
            {
              id: "error",
              text: "Error",
            },
            {
              id: "payment_declined",
              text: "Declined",
            },
            {
              id: "requires_3ds_fingerprint",
              text: "Requires 3DS fingerprint",
            },
            {
              id: "requires_3ds_challenge",
              text: "Requires 3DS challenge",
            },
            {
              id: "authorized_pending_capture",
              text: "Authorized pending capture",
            },
            {
              id: "cancelled",
              text: "Cancelled",
            },
            {
              id: "expired",
              text: "Expired",
            },
          ],
        },
        requestAdapter: function (input) {
          const output = {};

          const itemsPerPage = input.limit;
          const page = input.page;
          const offset = (page - 1) * itemsPerPage;

          // sort
          if (input.orderBy && input.orderBy !== "") {
            let sort = "";
            if (input.ascending === 0) {
              sort = "-"; // desc
            }
            sort += input.orderBy;
            output.sort = sort;
          }

          // paginate (limit and offset)
          output.limit = input.limit;
          output.offset = offset;

          // filter
          _.each(input.query, (val, key) => {
            if (val && val !== "") {
              output[key] = val;

              // handle amounts - convert to pence (minor units) value
              if (key === "amount") {
                output[key] = parseInt(Math.round(val * 100), 10);
              }
            }
          });

          if (input.createdAt) {
            output['createdAt[$gte]'] = new Date(input.createdAt.start).toISOString()
            output['createdAt[$lt]'] = new Date(input.createdAt.end).toISOString()
          } else if (input.succeededAt) {
            output['succeededAt[$gte]'] = new Date(input.succeededAt.start).toISOString()
            output['succeededAt[$lt]'] = new Date(input.succeededAt.end).toISOString()
          }

          return output;
        },
      },
    };
  },
  methods: {
    refreshTable() {
      this.$refs.intentsTable.refresh();
    },
    onTableLoading() {
      this.refreshing = true;
    },
    onTableLoaded() {
      this.refreshing = false;
    },
    openBulkActionsModal() {
      if (!this.$refs.intentsTable.selectedRows.length) {
        swal({
          icon: "error",
          title: "No rows selected",
          text: `Please select at least one row.`,
        });
      } else {
        this.bulkActionRows = this.$refs.intentsTable.selectedRows;
        this.bulkActionResults = [];
        this.bulkActionRunning = false;
        this.bulkActionsModalIsOpen = true;
      }
    },
    closeBulkActionsModal() {
      // refresh table
      this.refreshTable();
      // reset state
      this.bulkActionRows = [];
      this.bulkActionResults = [];
      this.bulkActionError = null;
      this.bulkActionRunning = false;
      this.bulkActionsModalIsOpen = false;
    },
    runBulkAction() {
      const instance = this;

      instance.bulkActionRunning = true;

      // set up the results array
      instance.bulkActionResults = instance.bulkActionRows.map((item) => {
        return {
          id: item.id,
          working: false,
          success: false,
          error: null,
          row: item,
        };
      });

      // run the action and set the results
      const PARALLEL_LIMIT = 1;
      eachLimit(
        instance.bulkActionResults,
        PARALLEL_LIMIT,
        (item, next) => {
          item.working = true;

          if (instance.bulkAction === "cancel") {
            instance.axios
              .put(`/v1/intents/${item.id}/cancellation`)
              .then((response) => {
                item.working = false;
                item.success = true;
                return next(null);
              })
              .catch((error) => {
                item.working = false;
                // set error
                const msg = get(error, "response.data.message");
                item.error = msg || error.message;
                return next(null);
              });
          } else if (instance.bulkAction === "delete") {
            instance.axios
              .delete(`/v1/intents/${item.id}`)
              .then((response) => {
                item.working = false;
                item.success = true;
                return next(null);
              })
              .catch((error) => {
                item.working = false;
                // set error
                const msg = get(error, "response.data.message");
                item.error = msg || error.message;
                return next(null);
              });
          } else if (instance.bulkAction === "resend") {
            // stop early
            if (item.row.statusIsFinal) {
              item.working = false;
              item.error = `Cannot resend notifications when status is '${item.row.status}'`;
              return next(null);
            } else {
              instance.axios
                .post(`/v1/intents/${item.id}/customer-notifications`, {
                  channel: "sms", // TODO: handle different channel options here
                })
                .then((response) => {
                  item.working = false;
                  item.success = true;
                  return next(null);
                })
                .catch((error) => {
                  item.working = false;
                  // set error
                  const msg = get(error, "response.data.message");
                  item.error = msg || error.message;
                  return next(null);
                });
            }
          }
        },
        (err) => {
          instance.bulkActionRunning = false;

          if (err) {
            instance.bulkActionError = err.message;
          } else {
            instance.$notify({
              message: "Bulk action completed",
              timeout: 3000,
              icon: "fa fa-check",
              horizontalAlign: "right",
              verticalAlign: "top",
              type: "success",
            });
          }
        }
      );
    },
    searchIntents: _.debounce((search, loading, vm) => {
      vm.axios
        .get("/v1/intents", {
          params: {
            q: search,
            limit: 10,
          },
        })
        .then((response) => {
          loading(false);
          vm.searchResults = response.data;
        })
        .catch((error) => {
          loading(false);
          console.log("error: ", error.message);
          // TODO: handle this
        });
    }, 500),
    onSearch(search, loading) {
      if (search !== "") {
        loading(true);
        this.searchIntents(search, loading, this);
      }
    },
    chooseSearchResult(result) {
      // go to selected intent
      this.$router.push(`/payment-links/${result.id}`);
    },
    // daterangepicker
    updateValues(vals, source) {
      Event.$emit(`vue-tables.intentsTable.filter::${source}`, { start: moment(vals.startDate).startOf('day'), end: moment(vals.endDate).endOf('day') });
      let name;
      if (source === 'createdAt') {
        name = 'date'
      } else if (source === 'succeededAt') {
        name = 'completed'
      }
      let filter = { name: name, value: vals, source: source }
      let isDuplicate = this.containsObject(filter, this.activeFilters);
      if (!isDuplicate) {
        this.activeFilters.push(filter);
      }
    },
    clearFilters() {
      this.succeededAtDateRange.startDate = null;
      this.succeededAtDateRange.endDate = null;
      this.createdAtDateRange.startDate = null;
      this.createdAtDateRange.endDate = null;
      this.$refs['intentsTable'].resetQuery()
      this.activeFilters = [];

      Event.$emit('vue-tables.intentsTable.filter::createdAt', null)
      Event.$emit('vue-tables.intentsTable.filter::succeededAt', null)
      this.refreshTable()
    },
    removeFilter(filter) {
      if (filter.source) {
        Event.$emit(`vue-tables.intentsTable.filter::${filter.source}`);
      } else {
        const filterObj = {};
        filterObj[filter.name] = '';
        this.$refs.intentsTable.setFilter(filterObj)
      }
    },
    containsObject(obj, list) {
      for (const i in list) {
        if (obj.name === list[i].name) {
          return true;
        }
      }
      return false;
    },
    addToActiveFilters(filter) {
      let isDuplicate = this.containsObject(filter, this.activeFilters)

      if (filter.value !== '' && !isDuplicate) {
        this.activeFilters.push(filter)
      } else if (filter.value === '') {
        const index = this.activeFilters.indexOf(filter)
        this.activeFilters.splice(index, 1)
      }
    },
    loadActiveFilters() {
      const tableData = this.$refs[this.tableName].getRequestParams();
      const activeQueries = tableData['query']

      if (tableData['createdAt'] !== null || '') {
        activeQueries.date = tableData['createdAt']
      }


      if (tableData['succeededAt'] !== null || '') {
        activeQueries.completed = tableData['succeededAt']
      }

      for (let query in activeQueries) {
        if (activeQueries[query] !== '') {
          const queryObj = { name: query, value: activeQueries[query] };
          this.activeFilters.push(queryObj);
        }
      }
    },
    setUp() {
      if (!this.showSelfEnrollment) {
        this.loadActiveFilters();
      }
    },
  },
  mounted() {
    this.setUp();
  },
};
</script>
