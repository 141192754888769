<template>
  <footer class="footer">
    <div class="container-fluid">
      <nav class="pull-left">
        <ul>
          <li>
            <a href="https://cabcard.services">CabCard Services</a>
          </li>
          <li>
            <a href="https://cabcard.services/support">Support</a>
          </li>
        </ul>
      </nav>
      <div class="copyright pull-right">
        &copy; {{ year }} CabCard Services Limited | <span>v2309131457</span>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>
<style></style>
