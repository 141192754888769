import Money from "js-money";
import pluralizer from "pluralize";
import humanizeString from "string-humanize";

const urlParser = document.createElement("a");

export function domain(url) {
  urlParser.href = url;
  return urlParser.hostname;
}

export function count(arr) {
  return arr.length;
}

export function pluralize(count, label) {
  return pluralizer(label, count);
}

export function money(amount, currencyCode) {
  if (!amount || isNaN(amount)) {
    amount = 0;
  }

  if (!Number.isInteger) {
    // round it
    amount = Math.round(amount);
  }

  if (!currencyCode) {
    currencyCode = "gbp";
  }

  let display = "";
  const money = Money.fromInteger(amount, currencyCode.toUpperCase());
  const currency = money.getCurrency();
  display += Money[currency].symbol;
  display += money.toString();
  return display;
}

export function humanize(str) {
  let result = humanizeString(str);

  // handle some special cases
  if (str === "bluePad5000") {
    result = "BluePad 5000";
  } else if (str === "bluePad50") {
    result = "BluePad 50";
  } else if (str === "bluePad55") {
    result = "BluePad 55";
  } else if (str === "bluePad55_4g") {
    result = "BluePad 55 (4G)";
  } else if (str === "pos") {
    result = "POS";
  }

  return result;
}

// truncate arg can be bool (e.g. true), numeric (e.g. 20 chars max) or 'domain' (display domain only)
export function renderUrls(str, truncate, classes, target) {
  // with input string (should be plaintext), render a URL and return HTML
  // implementation based on https://www.cluemediator.com/find-urls-in-string-and-make-a-link-using-javascript
  if (!str) {
    return str;
  }

  if (typeof str !== "string") {
    return str;
  }

  const urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
  const output = str.replace(urlRegex, function(url) {
    const hyperlink = url;
    if (!hyperlink.match("^https?://")) {
      hyperlink = "//" + hyperlink;
    }

    let anchorText = url;
    if (truncate) {
      if (truncate === "domain") {
        anchorText = domain(anchorText);
      } else {
        let truncateLimit = 20; // 20 chars default
        if (typeof truncate === "number") {
          truncateLimit = truncate;
        }

        anchorText = anchorText.slice(0, truncateLimit);
      }

      if (url.length > anchorText.length) {
        // indicate that the url has been truncated
        anchorText += "&hellip;";
      }
    }

    return `<a href="${hyperlink}" target="${target}" class="${classes}">${anchorText}</a>`;
  });

  return output;
}
