<template>
  <div class="row">
    <div class="col-lg-8 offset-lg-2 col-md-10 offset-md-1 col-sm-12">
      <div class="card">
        <h2 class="card-header">Edit payment link</h2>

        <div class="card-body" v-if="loading">
          <div class="text-center">
            <i class="fa fa-spin fa-spinner fa-5x"></i>
          </div>
        </div>

        <div class="card-body" v-if="error">
          <div class="alert alert-warning" role="alert">
            {{ error }}
          </div>
        </div>

        <div class="card-body" v-if="intent">
          <form
            v-if="submerchantUser && submerchantUser.submerchantRole !== 'viewer'
              ">
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label>Payment amount</label>
                  <currency-input
                    v-model="intent.amount"
                    currency="GBP"
                    locale="gb"
                    :value-as-integer="true"
                    :allow-negative="false"
                    class="form-control"
                    placeholder="e.g. 20.00" />
                </div>
              </div>

              <div class="col-md-8">
                <div class="form-group">
                  <label>Reference / Job Number (optional)</label>
                  <input
                    type="text"
                    v-model="intent.reference"
                    placeholder="e.g. REF001"
                    class="form-control" />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label>Customer name (optional)</label>
                  <input
                    type="text"
                    v-model="intent.customerName"
                    class="form-control"
                    placeholder="e.g. Stuart Jones" />
                </div>
              </div>
            </div>
            <div class="form-group">
              <label>Description (optional)</label>
              <input
                type="text"
                v-model="intent.description"
                class="form-control"
                placeholder="e.g. Taxi Journey to SE11 9FF" />
            </div>

            <div v-if="!working && error" class="alert alert-warning">
              <span>{{ error }}</span>
            </div>

            <button
              class="btn btn-primary btn-block"
              :disabled="working"
              v-on:click.prevent="updateIntent">
              <span v-if="!working">Update payment link
                <i class="fa fa-save ml-2"></i>
              </span>
              <span v-else>Please wait&hellip; <i class="fa fa-spinner fa-spin ml-2"></i></span>
            </button>
            <p class="text-muted">
              Payment link will
              <strong>not be automatically re-sent</strong>
              to your customer.
            </p>
          </form>

          <div v-else>
            <p class="text-warning">
              Sorry, you do not have sufficient permissions to modify payment
              links for this account.
            </p>
          </div>
        </div>

        <div class="card-footer">
          <button v-on:click="$router.back()" class="btn btn-default">
            <i class="fa fa-arrow-left"></i>&nbsp; Go back
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "IntentEditPage",
  components: {},
  props: ["intentId"],
  data() {
    return {
      loading: false,
      working: false,
      error: null,
      intent: null,
    };
  },
  computed: {
    submerchant: function () {
      return this.$store.state.submerchant;
    },
    submerchantUser: function () {
      return this.$store.state.submerchantUser;
    },
  },
  beforeMount() {
    this.loading = true;
    this.axios
      .get(`/v1/intents/${this.intentId}`)
      .then((response) => {
        this.loading = false;
        this.intent = response.data;
      })
      .catch((error) => {
        this.loading = false;
        this.error = "Failed to load intent";
      });
  },
  methods: {
    updateIntent: function () {
      this.error = null; // clear any existing errors
      // some validation
      if (!this.intent.amount) {
        this.error = "Amount is required";
        return;
      }

      // treat as valid
      this.working = true;

      const requestBody = {
        saleAmount: this.intent.amount,
        currency: this.intent.currency,
        description: this.intent.description,
        reference: this.intent.reference,
        customerName: this.intent.customerName
      };

      this.axios
        .patch(`/v1/intents/${this.intent.id}`, requestBody)
        .then((response) => {
          this.working = false;
          this.intent = response.data;

          this.$notify({
            message: "Payment link updated",
            timeout: 3000,
            icon: "fa fa-check",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "success",
          });

          this.$router.push(`/payment-links/${this.intent.id}`);
        })
        .catch((error) => {
          this.working = false;
          console.error("Error: ", error);
          if (error.response) {
            this.error = error.response.data.message;
          } else {
            this.error = error.message;
          }
        });
    },
  },
};
</script>
