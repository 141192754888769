import config from "../config";
import store from "../store";

import DashboardLayout from "../components/Dashboard/Layout/DashboardLayout.vue";
// GeneralViews
import NotFound from "../components/GeneralViews/NotFoundPage.vue";
// Dashboard pages
const Overview = () =>
  import(/* webpackChunkName: "widgets" */ "src/components/Dashboard/Views/Dashboard/Overview.vue");

// Pages
// Users
import UserEdit from "src/components/Dashboard/Views/Pages/Users/UserEdit.vue";
// Accounts
import SelectAccount from "src/components/Dashboard/Views/Pages/AccountSelect.vue";

// Sales
import SalesList from "src/components/Dashboard/Views/Sales/SalesList.vue";
import SalePage from "src/components/Dashboard/Views/Pages/Sales/SalePage.vue";

// Terminals
import TerminalsList from "src/components/Dashboard/Views/Pages/Terminals/TerminalsList.vue";
import TerminalPage from "src/components/Dashboard/Views/Pages/Terminals/TerminalPage.vue";

// Payment Links
import IntentsList from "src/components/Dashboard/Views/Pages/Intents/IntentsList.vue";
import IntentPage from "src/components/Dashboard/Views/Pages/Intents/IntentPage.vue";
import IntentCreatePage from "src/components/Dashboard/Views/Pages/Intents/IntentCreatePage.vue";
import IntentEditPage from "src/components/Dashboard/Views/Pages/Intents/IntentEditPage.vue";
import IntentBulkCreatePage from "src/components/Dashboard/Views/Pages/Intents/IntentBulkCreatePage.vue";

// Anytime Payments
import AnytimePaymentsPage from "src/components/Dashboard/Views/Pages/AnytimePayments/AnytimePaymentsPage.vue";

// Payouts
import PayoutsList from "src/components/Dashboard/Views/Pages/Payouts/PayoutsList.vue";
import PayoutPage from "src/components/Dashboard/Views/Pages/Payouts/PayoutPage.vue";

// Developer Tools
import DevelopersPage from "src/components/Dashboard/Views/Pages/Developers/DevelopersPage.vue";
import EndpointNotifications from "src/components/Dashboard/Views/Pages/Developers/EndpointNotifications.vue";

import Login from "src/components/Dashboard/Views/Pages/Login.vue";
import Register from "src/components/Dashboard/Views/Pages/Register.vue";
import ForgottenPassword from "src/components/Dashboard/Views/Pages/ForgottenPassword.vue";
import ResetPassword from "src/components/Dashboard/Views/Pages/ResetPassword.vue";
import SetPassword from "src/components/Dashboard/Views/Pages/SetPassword.vue";

let loginPage = {
  path: "/login",
  name: "Login",
  component: Login,
  meta: {
    authRequired: false
  }
};

let forgottenPasswordPage = {
  path: "/forgot-password",
  name: "Forgotten Password",
  component: ForgottenPassword,
  meta: {
    authRequired: false
  }
};

let resetPasswordPage = {
  path: "/reset-password",
  name: "Reset Password",
  component: ResetPassword,
  meta: {
    authRequired: false
  }
};

let setPasswordPage = {
  path: "/set-password",
  name: "Set Password",
  component: SetPassword,
  meta: {
    authRequired: false
  }
};

let registerPage = {
  path: "/register",
  name: "Register",
  component: Register,
  meta: {
    authRequired: false
  }
};

let accountSelectPage = {
  path: "/select-account",
  name: "Select account",
  component: SelectAccount,
  meta: {
    authRequired: true
  },
  beforeEnter: clearSelectedAccount
};

let userMenu = {
  path: "/user",
  component: DashboardLayout,
  redirect: "/user/edit",
  meta: {
    authRequired: true
  },
  beforeEnter: checkForSelectedAccount,
  children: [
    {
      path: "edit",
      name: "Edit user",
      component: UserEdit,
      meta: {
        authRequired: true
      }
    }
  ]
};

function checkForSelectedAccount(to, from, next) {
  // check whether user has an submerchant set to localstorage
  const account = store.state.submerchant;
  if (account) {
    const accountUser = store.state.submerchantUser;

    if (accountUser) {
      return next();
    } else {
      // load account user and set it
      window.axios
        .get(`/v1/submerchants/${account.id}/users/current`)
        .then(response => {
          store.commit("SET_SUBMERCHANT_USER", response.data);
          return next();
        })
        .catch(err => {
          console.error("error loading account user: ", err); // TODO: handle this error better?
          return next();
        });
    }
  } else {
    return next("/select-account");
  }
}

function clearSelectedAccount(to, from, next) {
  store.commit("SET_SUBMERCHANT", null);
  return next();
}

const dashboardMenu = {
  path: "/dashboard",
  component: DashboardLayout,
  meta: {
    authRequired: true
  },
  beforeEnter: checkForSelectedAccount,
  children: [
    {
      path: "/",
      name: "Account Dashboard",
      component: Overview,
      meta: {
        authRequired: true
      }
    }
  ]
};

const transactionsMenu = {
  path: "/transactions",
  component: DashboardLayout,
  meta: {
    authRequired: true
  },
  beforeEnter: checkForSelectedAccount,
  children: [
    {
      path: "sales",
      name: "Sales",
      component: SalesList,
      meta: {
        authRequired: true
      }
    },
    {
      path: "sales/:saleId",
      name: "Sale Detail",
      component: SalePage,
      props: true,
      meta: {
        authRequired: true
      }
    }
  ]
};

const paymentLinksMenu = {
  path: "/payment-links",
  component: DashboardLayout,
  meta: {
    authRequired: true
  },
  beforeEnter: checkForSelectedAccount,
  children: [
    {
      path: "/",
      name: "Payment Links",
      component: IntentsList,
      meta: {
        authRequired: true
      }
    },
    {
      path: "create",
      name: "Create Payment Link",
      component: IntentCreatePage,
      meta: {
        authRequired: true
      }
    },
    {
      path: "bulk-create",
      name: "Bulk Create Payment Links",
      component: IntentBulkCreatePage,
      meta: {
        authRequired: true
      }
    },
    {
      path: ":intentId",
      name: "Payment Link Detail",
      component: IntentPage,
      props: true,
      meta: {
        authRequired: true
      }
    },
    {
      path: ":intentId/edit",
      name: "Edit Payment Link",
      component: IntentEditPage,
      props: true,
      meta: {
        authRequired: true
      }
    }
  ]
};

const anytimePaymentsMenu = {
  path: "/scan-to-pay",
  component: DashboardLayout,
  meta: {
    authRequired: true
  },
  beforeEnter: checkForSelectedAccount,
  children: [
    {
      path: "/",
      name: "Anytime Payments",
      component: AnytimePaymentsPage,
      meta: {
        authRequired: true
      }
    },
  ]
};

const terminalsMenu = {
  path: "/terminals",
  component: DashboardLayout,
  meta: {
    authRequired: true
  },
  beforeEnter: checkForSelectedAccount,
  children: [
    {
      path: "/",
      name: "Terminals",
      component: TerminalsList,
      meta: {
        authRequired: true
      }
    },
    {
      path: ":terminalId",
      name: "Terminal Detail",
      component: TerminalPage,
      props: true,
      meta: {
        authRequired: true
      }
    }
  ]
};

const payoutsMenu = {
  path: "/payouts",
  component: DashboardLayout,
  meta: {
    authRequired: true
  },
  beforeEnter: checkForSelectedAccount,
  children: [
    {
      path: "/",
      name: "Payouts",
      component: PayoutsList,
      meta: {
        authRequired: true
      }
    },
    {
      path: ":payoutId",
      name: "Payout Detail",
      component: PayoutPage,
      props: true,
      meta: {
        authRequired: true
      }
    }
  ]
};

const developerMenu = {
  path: "/developer",
  component: DashboardLayout,
  meta: {
    authRequired: true
  },
  beforeEnter: checkForSelectedAccount,
  children: [
    {
      path: "/",
      name: "Developer",
      component: DevelopersPage,
      meta: {
        authRequired: true
      }
    },
    {
      path: "endpoints/:endpointId/notifications",
      name: "Endpoint Notifications",
      component: EndpointNotifications,
      props: true,
      meta: {
        authRequired: true
      }
    }
  ]
};

const routes = [
  loginPage,
  registerPage,
  forgottenPasswordPage,
  resetPasswordPage,
  setPasswordPage,
  accountSelectPage,
  dashboardMenu,
  transactionsMenu,
  terminalsMenu,
  paymentLinksMenu,
  anytimePaymentsMenu,
  payoutsMenu,
  userMenu,
  developerMenu,
  {
    path: "/",
    redirect: "/dashboard"
  },
  {
    path: "/status",
    beforeEnter: (to, from, next) => {
      location.href = "https://status.cabcard.services";
    }
  },
  {
    path: "/support",
    beforeEnter: (to, from, next) => {
      location.href = "https://support.cabcard.services";
    }
  },
  { path: "*", component: NotFound }
];

export default routes;
