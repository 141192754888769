<template>
  <div class="send-receipt-advice-form">
    <p class="lead">
      You can send a {{ transactionType }} receipt by SMS or email to your
      customer.
    </p>

    <p>
      They will receive a link to view and download a copy of the receipt
      information.
    </p>
    <div
      class="alert alert-danger alert-dismissible fade show"
      v-if="error"
      role="alert"
    >
      {{ error }}
      <button
        type="button"
        class="close"
        data-dismiss="alert"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="channel-select" v-if="!channel">
      <button v-on:click="channel = 'sms'" class="btn btn-info">
        <i class="fa fa-comment"></i>&nbsp; Send SMS
      </button>

      <button v-on:click="channel = 'email'" class="btn btn-info">
        <i class="fa fa-envelope"></i>&nbsp; Send Email
      </button>
    </div>

    <form id="send-receipt-form" v-if="channel">
      <div class="form-group" v-if="channel === 'email'">
        <label for="emailAddress" class="control-label">Email address</label>
        <input
          class="form-control"
          type="email"
          v-model="emailAddress"
          required
        />
      </div>

      <div class="form-group" v-if="channel === 'sms'">
        <label for="phoneNumber" class="control-label">Phone number</label>

        <vue-tel-input
          v-model="phoneNumber"
          mode="international"
          :preferredCountries="['gb', 'ie']"
          default-country="GB"
          input-classes="form-control"
        ></vue-tel-input>
      </div>

      <div class="button-group">
        <button
          v-on:click.prevent="channel = null"
          class="btn btn-default"
          :disabled="working"
        >
          <i class="fa fa-times"></i>&nbsp; Cancel
        </button>
        <button
          v-on:click.prevent="send()"
          class="btn btn-success"
          :disabled="working"
        >
          <span v-if="working">
            <i class="fa fa-spin fa-spinner"></i>&nbsp; Please wait&hellip;
          </span>
          <span v-else>
            Send &nbsp;
            <i class="fa fa-paper-plane-o"></i>
          </span>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
// import "vue-tel-input/dist/vue-tel-input.css";

export default {
  name: "sendReceiptAdviceForm",
  props: {
    transactionType: {
      type: String,
      description: "Transaction type (sale|refund)",
    },
    receiptId: {
      type: String,
      description: "ID of transaction receipt",
    },
    transaction: {
      type: Object,
      description: "Transaction (sale or refund) relating to receipt",
    },
  },
  data: function () {
    return {
      channel: null,
      working: false,
      phoneNumber: null,
      emailAddress: null,
      error: null,
    };
  },
  computed: {
    submerchant: function () {
      return this.$store.state.submerchant;
    },
  },
  methods: {
    send: function () {
      this.working = true;
      const requestBody = {
        channel: this.channel,
        brand: "cabcard",
      };

      // TODO: remove this exception for Speedy Park (Optimus submerchant using CC dashboard)
      if (
        (this.transaction &&
          this.transaction.submerchantId === "sub_hmfntkjv58crgu") ||
        this.transaction.submerchantId === "sub_9w62kjvgppola" // cafe two-six
      ) {
        requestBody.brand = "optimus";
      }

      // check for specific other brands (taxi fleets)
      if (this.submerchant.operatorId === "opr_3q12hkjyo7q4ke") {
        requestBody.brand = "cabcard_torbay"; // Torbay
      } else if (this.submerchant.operatorId === "opr_125ikk6vyx0s9") {
        requestBody.brand = "cabcard_avenue"; // Avenue
      } else if (this.submerchant.operatorId === "opr_4abkka3p2j3h") {
        requestBody.brand = "cabcard_wheels"; // Wheels
      } else if (this.submerchant.operatorId === "opr_125ikk6wfuuow") {
        requestBody.brand = "cabcard_toa"; // TOA
      }

      if (this.channel === "sms") {
        requestBody.phoneNumber = this.phoneNumber;
      } else if (this.channel === "email") {
        requestBody.emailAddress = this.emailAddress;
      }

      this.axios
        .post(`/v1/receipts/${this.receiptId}/advice`, requestBody)
        .then((response) => {
          this.working = false;
          // display a notification
          this.$notify({
            message: `Receipt sent via ${this.channel}`,
            timeout: 200000, // TODO: restore timeout 5000
            icon: "fa fa-paper-plane",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "success",
          });

          // clear the form
          this.channel = null;
          this.phoneNumber = null;
          this.emailAddress = null;
          // clear any previous error
          this.error = null;
        })
        .catch((error) => {
          this.working = false;
          this.error = "Failed to send receipt";

          if (error.response.data.message) {
            this.error += `: ${error.response.data.message}`;
          }
        });
    },
  },
};
</script>
