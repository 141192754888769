<template>
  <FinaroSelfEnrollment v-if="(submerchant && !submerchant.finaroId)"></FinaroSelfEnrollment>
  <div v-else class="row">
    <div class="col-12 col-lg-8">
      <div class="card">
        <h2 class="card-header">Send a payment link</h2>

        <div class="card-body text-center" v-if="!submerchant">
          <img
            src="/static/img/illustrations/warning-sign.svg"
            class="p-5 w-50" />
          <p class="lead">Oops - we couldn't load your account.</p>
          <a
            class="btn btn-lg btn-info"
            href="javascript:window.location.reload();">Refresh this page <i class="fa fa-reload ml-2"></i></a>
        </div>

        <div class="card-body" v-else-if="submerchant && submerchant.finaroId">
          <form
            v-if="submerchantUser && submerchantUser.submerchantRole !== 'viewer'
              ">
            <div class="row">
              <div class="col-12">
                <h5 class="mt-2 mb-1 text-muted">
                  <i class="fa fa-fw fa-shopping-basket mr-2"></i> Details
                </h5>
              </div>
            </div>
            <div class="row">
              <div class="col-6 col-sm-4 col-md-4">
                <div class="form-group">
                  <label>
                    Payment amount
                    <span class="text-danger" title="Required">*</span>
                  </label>
                  <currency-input
                    v-model="amount"
                    currency="GBP"
                    locale="gb"
                    :value-as-integer="true"
                    :allow-negative="false"
                    class="form-control"
                    placeholder="e.g. 20.00" />
                </div>
              </div>

              <div class="col-6 col-sm-8 col-md-8">
                <div class="form-group">
                  <label v-if="aquaSubmerchantIds.includes(submerchant.id)">Job Number
                    <span class="text-danger" title="Required">*</span>
                  </label>
                  <label v-else>Reference (optional)</label>
                  <input
                    type="text"
                    v-model="reference"
                    placeholder="e.g. REF001"
                    class="form-control" />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label>Description (optional)</label>
                  <textarea
                    v-model="description"
                    class="form-control"
                    placeholder="e.g. Taxi Journey to SE11 9FF" />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <h5 class="mt-2 mb-1 text-muted">
                  <i class="fa fa-fw fa-user mr-2"></i> Customer
                </h5>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-4">
                <div class="form-group">
                  <label>
                    Customer name
                    <span v-if="!aquaSubmerchantIds.includes(submerchant.id)">(optional)</span>
                    <span v-else class="text-danger" title="Required">*</span>
                  </label>
                  <input
                    type="text"
                    v-model="customerName"
                    class="form-control"
                    placeholder="e.g. Stuart Jones" />
                </div>
              </div>

              <div class="col-12 col-md-4">
                <div class="form-group">
                  <label>Customer country (optional)</label>
                  <select
                    v-model="customerAddressCountryCode"
                    class="form-control">
                    <option
                      v-for="country in countries"
                      :value="country.code"
                      :key="country.code">
                      {{ country.name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-12 col-md-4">
                <div class="form-group">
                  <label>Customer postcode (optional)</label>
                  <input
                    type="text"
                    v-model="customerAddressPostcode"
                    class="form-control"
                    placeholder="e.g. E9 5AV" />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <h5 class="mt-2 mb-1 text-muted">
                  <i class="fa fa-fw fa-mobile-phone mr-2"></i> Notification
                </h5>
              </div>
            </div>

            <!-- Mobile phone number -->
            <div class="row">
              <div class="col-8 col-md-6">
                <div class="form-group">
                  <label>Phone number
                    <span v-if="!sendCustomerSms">(optional)</span>
                    <span v-else class="text-danger" title="Required">*</span>
                  </label>
                  <vue-tel-input
                    name="customerPhoneNumber"
                    v-model="customerPhoneNumber"
                    :default-country="customerAddressCountryCode"
                    :preferredCountries="['gb', 'ie']"
                    mode="international"
                    :disabled="!sendCustomerSms"
                    input-classes="form-control"></vue-tel-input>
                </div>
              </div>

              <div class="col-4 col-md-2">
                <div class="form-group">
                  <label>Send SMS</label>
                  <p-checkbox class="align-middle" v-model="sendCustomerSms">Send?</p-checkbox>
                </div>
              </div>
            </div>

            <!-- Email address (hidden when Finaro ID not available) -->
            <div class="row" v-if="submerchant.finaroId">
              <div class="col-8 col-md-6">
                <div class="form-group">
                  <label>Email address
                    <span v-if="!sendCustomerEmail">(optional)</span>
                    <span v-else class="text-danger" title="Required">*</span>
                  </label>
                  <input
                    class="form-control"
                    v-model="customerEmailAddress"
                    :disabled="!sendCustomerEmail"
                    type="email"
                    placeholder="e.g. user@example.com" />
                </div>
              </div>

              <div class="col-4 col-md-2">
                <div class="form-group">
                  <label>Send email</label>
                  <p-checkbox class="align-middle" v-model="sendCustomerEmail">Send?</p-checkbox>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <h5 class="mt-2 mb-1 text-muted">
                  <i class="fa fa-fw fa-cog mr-2"></i> Settings
                </h5>
              </div>
            </div>

            <div class="row">
              <div class="col-6 col-sm-4 col-md-4">
                <div class="form-group">
                  <label>Expiry (optional)</label>

                  <input
                    type="text"
                    v-model="expiryNaturalLanguage"
                    placeholder="Default: 14 days"
                    class="form-control" />
                  <!-- TODO: allow switching between specific date and free form input -->
                </div>
              </div>

              <div class="col-6 col-sm-4 col-md-4">
                <div class="form-group" v-if="submerchant.finaroId">
                  <label> Capture mode </label>

                  <select v-model="captureMode" class="form-control">
                    <option value="automatic">Automatic (default)</option>
                    <option value="manual">Manual</option>
                  </select>
                </div>
              </div>
            </div>

            <div v-if="!working && error" class="alert alert-danger">
              <span>{{ error }}</span>
            </div>

            <button
              class="btn btn-primary btn-lg btn-block"
              :disabled="working"
              v-on:click.prevent="createIntent">
              <span v-if="!working">Request payment
                <span v-if="sendCustomerSms">by SMS</span>
                <span v-if="sendCustomerSms && sendCustomerEmail"> and </span>
                <span v-if="sendCustomerEmail">by email</span>
                <i class="fa fa-paper-plane ml-2"></i>
              </span>
              <span v-else>Please wait&hellip; <i class="fa fa-spinner fa-spin ml-2"></i></span>
            </button>
          </form>

          <div v-else>
            <p class="text-warning">
              Sorry, you do not have sufficient permissions to create payment
              links for this account.
            </p>
          </div>
        </div>

        <div class="card-footer">
          <button v-on:click="$router.back()" class="btn btn-default">
            <i class="fa fa-arrow-left"></i>&nbsp; Go back
          </button>
        </div>
      </div>
    </div>

    <div class="col-12 col-lg-4">
      <div class="card" v-if="submerchant.finaroId">
        <h3 class="card-header h3 mt-0">Other options</h3>
        <div class="card-body">
          <h5 class="mt-2 mb-1 text-muted">
            <i class="fa fa-fw fa-link mr-2"></i> Scan to pay
          </h5>
          <p>
            Let your customers pay securely any time. You won't need to generate
            a new link for each customer.
          </p>

          <!-- button link to anytime payments page -->
          <div class="text-center">
            <router-link class="btn btn-primary" to="/scan-to-pay">Click for scannable QR code</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueQRCodeComponent from "vue-qrcode-component";
import FinaroSelfEnrollment from "./FinaroSelfEnrollment.vue";

export default {
  name: "IntentCreatePage",
  components: {
    qrCode: VueQRCodeComponent,
    FinaroSelfEnrollment
},
  data() {
    return {
      working: false,
      error: null,
      intent: null,
      customerName: null,
      sendCustomerSms: true,
      customerPhoneNumber: null,
      sendCustomerEmail: true,
      customerEmailAddress: null,
      customerAddressCountryCode: "GB",
      customerAddressPostcode: null,
      amount: null,
      description: null,
      reference: null,
      expiryNaturalLanguage: null,
      captureMode: "automatic",
      countries: [
        { name: "Albania", code: "AL" },
        { name: "Åland Islands", code: "AX" },
        { name: "Algeria", code: "DZ" },
        { name: "American Samoa", code: "AS" },
        { name: "Andorra", code: "AD" },
        { name: "Angola", code: "AO" },
        { name: "Anguilla", code: "AI" },
        { name: "Antarctica", code: "AQ" },
        { name: "Antigua and Barbuda", code: "AG" },
        { name: "Argentina", code: "AR" },
        { name: "Armenia", code: "AM" },
        { name: "Aruba", code: "AW" },
        { name: "Australia", code: "AU" },
        { name: "Austria", code: "AT" },
        { name: "Azerbaijan", code: "AZ" },
        { name: "Bahamas (the)", code: "BS" },
        { name: "Bahrain", code: "BH" },
        { name: "Bangladesh", code: "BD" },
        { name: "Barbados", code: "BB" },
        { name: "Belarus", code: "BY" },
        { name: "Belgium", code: "BE" },
        { name: "Belize", code: "BZ" },
        { name: "Benin", code: "BJ" },
        { name: "Bermuda", code: "BM" },
        { name: "Bhutan", code: "BT" },
        { name: "Bolivia (Plurinational State of)", code: "BO" },
        { name: "Bonaire, Sint Eustatius and Saba", code: "BQ" },
        { name: "Bosnia and Herzegovina", code: "BA" },
        { name: "Botswana", code: "BW" },
        { name: "Bouvet Island", code: "BV" },
        { name: "Brazil", code: "BR" },
        { name: "British Indian Ocean Territory (the)", code: "IO" },
        { name: "Brunei Darussalam", code: "BN" },
        { name: "Bulgaria", code: "BG" },
        { name: "Burkina Faso", code: "BF" },
        { name: "Burundi", code: "BI" },
        { name: "Cabo Verde", code: "CV" },
        { name: "Cambodia", code: "KH" },
        { name: "Cameroon", code: "CM" },
        { name: "Canada", code: "CA" },
        { name: "Cayman Islands (the)", code: "KY" },
        { name: "Central African Republic (the)", code: "CF" },
        { name: "Chad", code: "TD" },
        { name: "Chile", code: "CL" },
        { name: "China", code: "CN" },
        { name: "Christmas Island", code: "CX" },
        { name: "Cocos (Keeling) Islands (the)", code: "CC" },
        { name: "Colombia", code: "CO" },
        { name: "Comoros (the)", code: "KM" },
        { name: "Congo (the Democratic Republic of the)", code: "CD" },
        { name: "Congo (the)", code: "CG" },
        { name: "Cook Islands (the)", code: "CK" },
        { name: "Costa Rica", code: "CR" },
        { name: "Croatia", code: "HR" },
        { name: "Cuba", code: "CU" },
        { name: "Curaçao", code: "CW" },
        { name: "Cyprus", code: "CY" },
        { name: "Czechia", code: "CZ" },
        { name: "Côte d'Ivoire", code: "CI" },
        { name: "Denmark", code: "DK" },
        { name: "Djibouti", code: "DJ" },
        { name: "Dominica", code: "DM" },
        { name: "Dominican Republic (the)", code: "DO" },
        { name: "Ecuador", code: "EC" },
        { name: "Egypt", code: "EG" },
        { name: "El Salvador", code: "SV" },
        { name: "Equatorial Guinea", code: "GQ" },
        { name: "Eritrea", code: "ER" },
        { name: "Estonia", code: "EE" },
        { name: "Eswatini", code: "SZ" },
        { name: "Ethiopia", code: "ET" },
        { name: "Falkland Islands (the) [Malvinas]", code: "FK" },
        { name: "Faroe Islands (the)", code: "FO" },
        { name: "Fiji", code: "FJ" },
        { name: "Finland", code: "FI" },
        { name: "France", code: "FR" },
        { name: "French Guiana", code: "GF" },
        { name: "French Polynesia", code: "PF" },
        { name: "French Southern Territories (the)", code: "TF" },
        { name: "Gabon", code: "GA" },
        { name: "Gambia (the)", code: "GM" },
        { name: "Georgia", code: "GE" },
        { name: "Germany", code: "DE" },
        { name: "Ghana", code: "GH" },
        { name: "Gibraltar", code: "GI" },
        { name: "Greece", code: "GR" },
        { name: "Greenland", code: "GL" },
        { name: "Grenada", code: "GD" },
        { name: "Guadeloupe", code: "GP" },
        { name: "Guam", code: "GU" },
        { name: "Guatemala", code: "GT" },
        { name: "Guernsey", code: "GG" },
        { name: "Guinea", code: "GN" },
        { name: "Guinea-Bissau", code: "GW" },
        { name: "Guyana", code: "GY" },
        { name: "Haiti", code: "HT" },
        { name: "Heard Island and McDonald Islands", code: "HM" },
        { name: "Holy See (the)", code: "VA" },
        { name: "Honduras", code: "HN" },
        { name: "Hong Kong", code: "HK" },
        { name: "Hungary", code: "HU" },
        { name: "Iceland", code: "IS" },
        { name: "India", code: "IN" },
        { name: "Indonesia", code: "ID" },
        { name: "Iran (Islamic Republic of)", code: "IR" },
        { name: "Iraq", code: "IQ" },
        { name: "Ireland", code: "IE" },
        { name: "Isle of Man", code: "IM" },
        { name: "Israel", code: "IL" },
        { name: "Italy", code: "IT" },
        { name: "Jamaica", code: "JM" },
        { name: "Japan", code: "JP" },
        { name: "Jersey", code: "JE" },
        { name: "Jordan", code: "JO" },
        { name: "Kazakhstan", code: "KZ" },
        { name: "Kenya", code: "KE" },
        { name: "Kiribati", code: "KI" },
        { name: "Korea (the Democratic People's Republic of)", code: "KP" },
        { name: "Korea (the Republic of)", code: "KR" },
        { name: "Kuwait", code: "KW" },
        { name: "Kyrgyzstan", code: "KG" },
        { name: "Lao People's Democratic Republic (the)", code: "LA" },
        { name: "Latvia", code: "LV" },
        { name: "Lebanon", code: "LB" },
        { name: "Lesotho", code: "LS" },
        { name: "Liberia", code: "LR" },
        { name: "Libya", code: "LY" },
        { name: "Liechtenstein", code: "LI" },
        { name: "Lithuania", code: "LT" },
        { name: "Luxembourg", code: "LU" },
        { name: "Macao", code: "MO" },
        { name: "Madagascar", code: "MG" },
        { name: "Malawi", code: "MW" },
        { name: "Malaysia", code: "MY" },
        { name: "Maldives", code: "MV" },
        { name: "Mali", code: "ML" },
        { name: "Malta", code: "MT" },
        { name: "Marshall Islands (the)", code: "MH" },
        { name: "Martinique", code: "MQ" },
        { name: "Mauritania", code: "MR" },
        { name: "Mauritius", code: "MU" },
        { name: "Mayotte", code: "YT" },
        { name: "Mexico", code: "MX" },
        { name: "Micronesia (Federated States of)", code: "FM" },
        { name: "Moldova (the Republic of)", code: "MD" },
        { name: "Monaco", code: "MC" },
        { name: "Mongolia", code: "MN" },
        { name: "Montenegro", code: "ME" },
        { name: "Montserrat", code: "MS" },
        { name: "Morocco", code: "MA" },
        { name: "Mozambique", code: "MZ" },
        { name: "Myanmar", code: "MM" },
        { name: "Namibia", code: "NA" },
        { name: "Nauru", code: "NR" },
        { name: "Nepal", code: "NP" },
        { name: "Netherlands (the)", code: "NL" },
        { name: "New Caledonia", code: "NC" },
        { name: "New Zealand", code: "NZ" },
        { name: "Nicaragua", code: "NI" },
        { name: "Niger (the)", code: "NE" },
        { name: "Nigeria", code: "NG" },
        { name: "Niue", code: "NU" },
        { name: "Norfolk Island", code: "NF" },
        { name: "Northern Mariana Islands (the)", code: "MP" },
        { name: "Norway", code: "NO" },
        { name: "Oman", code: "OM" },
        { name: "Pakistan", code: "PK" },
        { name: "Palau", code: "PW" },
        { name: "Palestine, State of", code: "PS" },
        { name: "Panama", code: "PA" },
        { name: "Papua New Guinea", code: "PG" },
        { name: "Paraguay", code: "PY" },
        { name: "Peru", code: "PE" },
        { name: "Philippines (the)", code: "PH" },
        { name: "Pitcairn", code: "PN" },
        { name: "Poland", code: "PL" },
        { name: "Portugal", code: "PT" },
        { name: "Puerto Rico", code: "PR" },
        { name: "Qatar", code: "QA" },
        { name: "Republic of North Macedonia", code: "MK" },
        { name: "Romania", code: "RO" },
        { name: "Russian Federation (the)", code: "RU" },
        { name: "Rwanda", code: "RW" },
        { name: "Réunion", code: "RE" },
        { name: "Saint Barthélemy", code: "BL" },
        { name: "Saint Helena, Ascension and Tristan da Cunha", code: "SH" },
        { name: "Saint Kitts and Nevis", code: "KN" },
        { name: "Saint Lucia", code: "LC" },
        { name: "Saint Martin (French part)", code: "MF" },
        { name: "Saint Pierre and Miquelon", code: "PM" },
        { name: "Saint Vincent and the Grenadines", code: "VC" },
        { name: "Samoa", code: "WS" },
        { name: "San Marino", code: "SM" },
        { name: "Sao Tome and Principe", code: "ST" },
        { name: "Saudi Arabia", code: "SA" },
        { name: "Senegal", code: "SN" },
        { name: "Serbia", code: "RS" },
        { name: "Seychelles", code: "SC" },
        { name: "Sierra Leone", code: "SL" },
        { name: "Singapore", code: "SG" },
        { name: "Sint Maarten (Dutch part)", code: "SX" },
        { name: "Slovakia", code: "SK" },
        { name: "Slovenia", code: "SI" },
        { name: "Solomon Islands", code: "SB" },
        { name: "Somalia", code: "SO" },
        { name: "South Africa", code: "ZA" },
        { name: "South Georgia and the South Sandwich Islands", code: "GS" },
        { name: "South Sudan", code: "SS" },
        { name: "Spain", code: "ES" },
        { name: "Sri Lanka", code: "LK" },
        { name: "Sudan (the)", code: "SD" },
        { name: "Suriname", code: "SR" },
        { name: "Svalbard and Jan Mayen", code: "SJ" },
        { name: "Sweden", code: "SE" },
        { name: "Switzerland", code: "CH" },
        { name: "Syrian Arab Republic", code: "SY" },
        { name: "Taiwan (Province of China)", code: "TW" },
        { name: "Tajikistan", code: "TJ" },
        { name: "Tanzania, United Republic of", code: "TZ" },
        { name: "Thailand", code: "TH" },
        { name: "Timor-Leste", code: "TL" },
        { name: "Togo", code: "TG" },
        { name: "Tokelau", code: "TK" },
        { name: "Tonga", code: "TO" },
        { name: "Trinidad and Tobago", code: "TT" },
        { name: "Tunisia", code: "TN" },
        { name: "Turkey", code: "TR" },
        { name: "Turkmenistan", code: "TM" },
        { name: "Turks and Caicos Islands (the)", code: "TC" },
        { name: "Tuvalu", code: "TV" },
        { name: "Uganda", code: "UG" },
        { name: "Ukraine", code: "UA" },
        { name: "United Arab Emirates (the)", code: "AE" },
        { name: "United Kingdom", code: "GB" },
        { name: "United States Minor Outlying Islands (the)", code: "UM" },
        { name: "United States of America (the)", code: "US" },
        { name: "Uruguay", code: "UY" },
        { name: "Uzbekistan", code: "UZ" },
        { name: "Vanuatu", code: "VU" },
        { name: "Venezuela (Bolivarian Republic of)", code: "VE" },
        { name: "Viet Nam", code: "VN" },
        { name: "Virgin Islands (British)", code: "VG" },
        { name: "Virgin Islands (U.S.)", code: "VI" },
        { name: "Wallis and Futuna", code: "WF" },
        { name: "Western Sahara", code: "EH" },
        { name: "Yemen", code: "YE" },
        { name: "Zambia", code: "ZM" },
        { name: "Zimbabwe", code: "ZW" },
      ],
      aquaSubmerchantIds: [
        // "sub_177f4vkleir6uan", // Aqua Data Services - driver collections
        "sub_5vqszwimld387nf2", // Aqua Data Services - customer payments
        "sub_11d2lls8x0b6w", // Veezu Ltd Passengers -customer payments (successor to sub_5vqszwimld387nf2)
      ],
    };
  },
  computed: {
    submerchant: function () {
      return this.$store.state.submerchant;
    },
    submerchantUser: function () {
      return this.$store.state.submerchantUser;
    },
    anytimeLink: function () {
      const baseUrl = "https://pay.cabcard.services";
      return `${baseUrl}/now?s=${this.submerchant.id}`;
    },
  },
  beforeMount() {
    this.maybeReloadSubmerchant();
    this.loading = true;
    this.axios
      .get("/v1/submerchants", {
        headers: {
          "x-apicache-bypass": "true", // bypass API cache to get accessible submerchants
        },
      })
      .then((response) => {
        this.loading = false;
        this.submerchants = response.data;
      })
      .catch((error) => {
        this.loading = false;
        this.loadingError = "Failed to load submerchants";
      });
  },
  methods: {
    maybeReloadSubmerchant() {
      if (!this.$store.state.submerchant.finaroId) {
        this.axios
          .get(`/v1/submerchants/${this.$store.state.submerchant.id}`, {
            headers: {
              "x-apicache-bypass": "true", // bypass API cache to get latest
            },
          })
          .then((response) => {
            const submerchant = response.data;
            this.$store.commit("SET_SUBMERCHANT", submerchant);
          })
          .catch((error) => {
            console.error("Error loading submerchant", error.message);
          });
      }
    },
    createIntent: function () {
      this.error = null; // clear any existing errors
      // some validation
      if (!this.amount) {
        this.error = "Amount is required";
      }

      if (this.sendCustomerSms && !this.customerPhoneNumber) {
        this.error = "Customer phone number is required to send SMS";
      }

      if (this.sendCustomerEmail && !this.customerEmailAddress) {
        this.error = "Customer email address is required to send email";
      }

      // special case for Aqua Cars
      if (this.aquaSubmerchantIds.includes(this.submerchant.id)) {
        // other fields are required
        if (!this.reference) {
          this.error = "Job number (Reference) is required";
        } else if (this.reference.length < 8) {
          this.error = "Job number (Reference) must be at least 8 characters";
        }

        if (!this.customerName) {
          this.error = "Customer name is required";
        }
      }

      if (this.error) {
        // SweetAlert
        this.$swal({
          title: "Oops...",
          text: this.error,
          icon: "error",
        });
        return;
      }

      // treat as valid
      this.working = true;

      const requestBody = {
        saleAmount: this.amount,
        currency: "gbp",
        submerchantId: this.submerchant.id,
        description: this.description,
        reference: this.reference,
        customerName: this.customerName,
        brandIdentifier: this.submerchant.id,
        captureMode: this.captureMode,
      };

      if (this.customerPhoneNumber && this.sendCustomerSms) {
        requestBody.customerPhoneNumber = this.customerPhoneNumber;
        requestBody.customerPhoneNumber = requestBody.customerPhoneNumber
          .split("-")
          .join(""); // remove dashes
        requestBody.customerPhoneNumber = requestBody.customerPhoneNumber
          .split(" ")
          .join(""); // remove spaces
        requestBody.sendCustomerSms = true;
      }

      if (this.customerEmailAddress) {
        requestBody.customerEmailAddress = this.customerEmailAddress;
      }

      if (this.sendCustomerEmail) {
        requestBody.sendCustomerEmail = true;
      }

      if (this.expiryNaturalLanguage) {
        requestBody.expiryNaturalLanguage = this.expiryNaturalLanguage;
      }

      if (this.customerAddressCountryCode) {
        if (!requestBody.customerAddress) {
          requestBody.customerAddress = {};
        }
        requestBody.customerAddress.countryCode =
          this.customerAddressCountryCode;
      }

      if (this.customerAddressPostcode) {
        if (!requestBody.customerAddress) {
          requestBody.customerAddress = {};
        }
        requestBody.customerAddress.postcode = this.customerAddressPostcode;
      }

      this.axios
        .post("/v1/intents", requestBody)
        .then((response) => {
          this.working = false;
          this.intent = response.data;

          this.$notify({
            message: "Payment link created",
            timeout: 3000,
            icon: "fa fa-check",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "success",
          });

          this.$router.push(`/payment-links/${this.intent.id}`);
        })
        .catch((error) => {
          this.working = false;
          console.error("Error: ", error);
          if (error.response) {
            this.error = error.response.data.message;
          } else {
            this.error = error.message;
          }
        });
    },
    onCopySuccess() {
      this.$notify({
        message: "Copied to clipboard",
        timeout: 1000,
        icon: "fa fa-copy",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: "success",
      });
    },
    onCopyError() {
      this.$notify({
        message: "Could not copy to clipboard",
        timeout: 1000,
        icon: "fa fa-exclamation-triangle",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: "error",
      });
    },
  },
};
</script>
