<template>
  <div class="container">
    <div class="row">
      <div class="col-lg-8 col-md-12">
        <edit-profile-form v-bind:user="user"></edit-profile-form>

        <div class="card mt-5" v-if="submerchant">
          <div class="card-header">
            <h4 class="title">Your Account</h4>
          </div>
          <div class="card-body">
            <table class="table">
              <tbody>
                <tr>
                  <th>Name</th>
                  <td>
                    {{ submerchant.name }}
                    <span v-if="submerchant.legalName !== submerchant.name"
                      >({{ submerchant.legalName }})</span
                    >
                  </td>
                </tr>
                <tr
                  v-if="
                    submerchant.planAttachment &&
                    submerchant.planAttachment.plan
                  "
                >
                  <th>Plan</th>
                  <td>{{ submerchant.planAttachment.plan.name }}</td>
                </tr>
                <tr
                  v-if="
                    submerchant.planAttachment &&
                    submerchant.planAttachment.plan &&
                    submerchant.planAttachment.plan.payoutAgreement
                  "
                >
                  <th>Payout schedule</th>
                  <td>
                    Payment
                    {{
                      submerchant.planAttachment.plan.payoutAgreement
                        .scheduleNaturalLanguage
                    }}
                    <span
                      v-if="
                        submerchant.planAttachment.plan.payoutAgreement
                          .minBalance
                      "
                      >(no minimum balance)</span
                    >
                    <span
                      v-else-if="
                        submerchant.planAttachment.plan.payoutAgreement
                          .minBalance === 0
                      "
                      >(no minimum balance)</span
                    >
                    <span v-else
                      >(minimum balance:
                      {{
                        submerchant.planAttachment.plan.payoutAgreement
                          .minBalance
                          | money(
                            submerchant.planAttachment.plan.payoutAgreement
                              .currency
                          )
                      }})</span
                    >
                  </td>
                </tr>
                <tr
                  v-if="
                    submerchant &&
                    submerchant.id &&
                    ['manager', 'owner'].includes(
                      submerchantUser.submerchantRole
                    )
                  "
                >
                  <th>Additional users</th>
                  <td>
                    <a
                      class="btn btn-sm btn-default"
                      :href="`https://tally.so#tally-open=wQKvAX&tally-layout=modal&tally-hide-title=1&tally-auto-close=3000&submerchantId=${submerchant.id}&userId=${user.id}`"
                      ><i class="fa fa-user-plus mr-2"></i>Add a user to your
                      account</a
                    >
                  </td>
                </tr>
                <tr v-if="['owner'].includes(submerchantUser.submerchantRole)">
                  <th>Bank Account</th>
                  <td>
                    <a
                      class="btn btn-sm btn-default"
                      :href="`https://tally.so#tally-open=n9qpK5&tally-layout=modal&tally-hide-title=1&tally-auto-close=3000&submerchantId=${submerchant.id}&userId=${user.id}`"
                      ><i class="fa fa-bank mr-2"></i>Update bank account</a
                    >
                  </td>
                </tr>
                <tr>
                  <th>Multi-Factor Authentication</th>
                  <td>
                    <a
                      v-if="!user.mfaIsEnabled"
                      class="btn btn-sm btn-primary text-light"
                      @click="enrollMfa"
                    >
                      <i class="fa fa-lock mr-3"></i>Enrol
                    </a>
                    <a
                      v-else
                      class="btn btn-sm btn-danger text-light"
                      @click="disableMfa"
                    >
                      <i class="fa fa-unlock mr-2"></i>Disable
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <modal :show.sync="mfaModalOpen" class="modal-primary">
        <div slot="header">
          <span class="h5">Enroll for Multi-Factor Authentication</span>
        </div>

        <div v-if="mfaEnrollmentError" class="alert alert-warning" role="alert">
          Error enrolling for MFA: {{ mfaEnrollmentError }}
        </div>

        <div class="text-center">
          <p>
            <strong>Step 1:</strong> scan the QR code below with your
            authenticator app such as
            <a :href="authyUrl" target="_blank"> Authy </a> or
            <a :href="googleAuthUrl" target="_blank"> Google Authenticator </a>.
          </p>

          <div class="border border-muted">
            <img class="mx-auto d-block" :src="mfaEnrollmentQRDataUrl" />
            <p class="text-muted">
              Can't see the QR code? Enter:<br />
              <code
                ><mark>{{ mfaEnrollmentSecret }}</mark></code
              >
            </p>
          </div>

          <p class="mt-5">
            <strong>Step 2:</strong> enter the 6 digit code from your
            authenticator app and press confirm before it expires.
          </p>

          <input
            type="text"
            class="form-control"
            placeholder="XXXXXX"
            v-model="mfaEnrollmentToken"
            inputmode="numeric"
            pattern="[0-9]*"
          />
          <button
            class="btn btn-success"
            :disabled="
              mfaEnrollmentWorking ||
              !mfaEnrollmentToken ||
              mfaEnrollmentToken.length < 6
            "
            v-on:click="confirmMfaEnrollment"
          >
            <span v-if="mfaEnrollmentWorking"
              ><i class="fa fa-spinner fa-spin"></i>&nbsp; Please
              wait&hellip;</span
            >
            <span v-else><i class="fa fa-check"></i>&nbsp; Confirm</span>
          </button>
          <p class="text-muted mt-3">
            Need help? <router-link to="/support">Contact support</router-link>.
          </p>
        </div>
      </modal>

      <div class="col-lg-4 col-md-6">
        <div class="card">
          <div class="card-header">
            <h4 class="title">{{ user.displayName }}</h4>
          </div>
          <div class="card-body">
            <table class="table">
              <tbody>
                <tr>
                  <th>Joined</th>
                  <td>
                    <time :datetime="user.createdAt">{{
                      user.createdAt | moment("from", "now")
                    }}</time>
                  </td>
                </tr>
                <tr>
                  <th>Updated</th>
                  <td>
                    <time :datetime="user.updatedAt">{{
                      user.updatedAt | moment("from", "now")
                    }}</time>
                  </td>
                </tr>
                <tr>
                  <th>Credentials updated</th>
                  <td>
                    <time
                      v-if="user.credentialsChangedAt"
                      :datetime="user.credentialsChangedAt"
                      >{{
                        user.credentialsChangedAt | moment("from", "now")
                      }}</time
                    >
                    <span v-else>Never</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import EditProfileForm from "./EditProfileForm.vue";
import { Modal } from "src/components/UIComponents";

export default {
  components: {
    EditProfileForm,
    Modal,
  },
  data() {
    return {
      submerchantLoading: false,
      submerchant: null,
      mfaEnrollmentWorking: false,
      mfaEnrollmentQRDataUrl: null,
      mfaEnrollmentSecret: null,
      mfaEnrollmentRecoveryCode: null,
      mfaEnrollmentToken: null,
      mfaEnrollmentError: null,
      mfaModalOpen: false,
      authyUrl: "https://authy.com/download/",
      googleAuthUrl: "https://support.google.com/accounts/answer/1066447",
      refreshKey: 0,
    };
  },
  methods: {
    enrollMfa() {
      this.refreshKey++;
      this.mfaEnrollmentWorking = true;
      const requestBody = { type: "totp" };
      this.axios
        .put(`/v1/users/${this.user.id}/mfa-enrollment`, requestBody)
        .then((response) => {
          this.mfaEnrollmentQRDataUrl = response.data.qrDataUrl;
          this.mfaEnrollmentSecret = response.data.secret;
          this.mfaEnrollmentRecoveryCode = response.data.recoveryCode;
        })
        .catch((error) => {
          this.mfaEnrollmentError = error.response.data.message;
        });

      this.mfaEnrollmentWorking = false;
      this.mfaModalOpen = true;
    },
    confirmMfaEnrollment() {
      const tokenIsNumeric = /^\d+$/.test(this.mfaEnrollmentToken);
      if (!this.mfaEnrollmentToken || !tokenIsNumeric) {
        this.mfaEnrollmentError =
          "Invalid code provided. Please double check your authenticator app.";
      } else {
        this.mfaEnrollmentWorking = true;
        const requestBody = { type: "totp", token: this.mfaEnrollmentToken };

        this.axios
          .put(
            `v1/users/${this.user.id}/mfa-enrollment/confirmation`,
            requestBody
          )
          .then((response) => {
            this.mfaEnrollmentWorking = false;

            if (response.status !== 200) {
              this.mfaEnrollmentError = response.statusText;
              this.mfaEnrollmentWorking = false;
              return;
            }

            this.mfaModalOpen = false;

            const user = this.user;
            user.mfaIsEnabled = true;
            this.$store.commit("SET_USER", user);

            this.$swal({
              title: "Single Use Recovery Code",
              icon: "info",
              input: "text",
              backdrop: "rgba(0,0,0,0.4)",
              html: `
                <span style="font-size: 18px;" class="badge rounded-pill bg-secondary text-light mb-2">${this.mfaEnrollmentRecoveryCode}</span><br> 
                <p>MFA has been successfully enabled. Note the above recovery code somewhere and keep it safe. You can use it to sign in if you lose access to your device.</p>
              `,
              allowOutsideClick: false,
              // allowEscapeKey: false,
              inputLabel: 'Type "Confirm" below to dismiss.',
              inputPlaceholder: "Confirm",
              inputValidator: (value) => {
                return new Promise((resolve) => {
                  if (value.toLowerCase().trim() === "confirm") {
                    resolve();
                  } else {
                    resolve(
                      'You need to type "Confirm" in the text box to acknowledge you have copied your recovery code before proceeding.'
                    );
                  }
                });
              },
            });
          })
          .catch((error) => {
            this.mfaEnrollmentError = error.response.data.message;
            this.mfaEnrollmentWorking = false;
          });
      }
    },
    disableMfa() {
      this.$swal({
        title: `Disable MFA for ${this.user.displayName}?`,
        input: "text",
        icon: "question",
        backdrop: "rgba(0,0,0,0.4)",
        confirmButtonText: "Disable",
        confirmButtonColor: "#dc3545",
        inputLabel:
          "Enter your authenticator code (or recovery code) to confirm.",
        inputPlaceholder: "XXXXXX",
        inputValidator: (value) => {
          return new Promise((resolve) => {
            if (value.length === 6 || value.length === 24) {
              this.axios
                .delete(`v1/users/${this.user.id}/mfa-enrollment`, {
                  data: { mfaToken: value },
                })
                .then((response) => {
                  this.$swal({
                    icon: "success",
                    title: "MFA Successfully disabled",
                    toast: true,
                    timeout: 3000,
                  });
                  const user = this.user;
                  user.mfaIsEnabled = false;
                  this.$store.commit("SET_USER", user);
                  this.refreshKey++;
                })
                .catch((error) => {
                  this.$swal({
                    icon: "error",
                    title: "Something went wrong. Please try again.",
                    toast: true,
                    timeout: 3000,
                  });
                });
              resolve();
            } else {
              resolve(
                "Invalid code provided. Please double check your authenticator app."
              );
            }
          });
        },
      });
    },
  },
  computed: {
    user: function () {
      this.refreshKey;
      return this.$store.state.user;
    },
    submerchantUser: function () {
      this.refreshKey;
      return this.$store.state.submerchantUser;
    },
  },
  beforeMount() {
    this.submerchantLoading = true;

    const submerchant = this.$store.state.submerchant;
    this.axios
      .get(`/v1/submerchants/${submerchant.id}?expand=planAttachment`)
      .then((response) => {
        this.submerchantLoading = false;
        this.submerchant = response.data;
      })
      .catch((error) => {
        this.submerchantLoading = false;
        this.submerchantLoadingError = "Failed to load submerchant";
      });
  },
};
</script>
<style></style>
